import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json"; // Fixed Product Market Maker ABI
import { getErrorMessage } from '../../../errorHandler';

async function WithdrawFees(fpmmAddress, recipientAddress, provider, signer) {
    let withdrawResult = [true, ''];

    try {
        console.log('Initiating fee withdrawal to:', recipientAddress);
        const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider).connect(signer);

        const tx = await fpmmContract.withdrawFees(recipientAddress);

        console.log('Fee withdrawal transaction sent:', tx.hash);
        await tx.wait();
        withdrawResult = [true, tx.hash];

    } catch (error) {
        console.error('Error withdrawing fees:', error);
        const errorMsg = getErrorMessage(error);
        withdrawResult = [false, errorMsg];
    }

    return withdrawResult;
}

export { WithdrawFees };
