import { ethers } from "ethers";
import PaginatedQuestions from "../../../../config/abi/prediction/PaginateQuestionsABI.json";

async function GetQuestionByQId( contractAddress, questionString, provider) {
    let result = null;

    try {
        // Initialize the contract instance
        
        const paginatedQuestionsContract = new ethers.Contract(contractAddress, PaginatedQuestions.abi, provider);

        // Call the contract's function to get the question by string
        const question = await paginatedQuestionsContract.getQuestionByString(questionString);
        console.log('Retrieved question by string:', question);

        // Store the result
        result = question;

    } catch (error) {
        console.error('Error retrieving question by string:', error);
    }

    return result;
}

export { GetQuestionByQId };
