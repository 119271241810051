import { ethers } from "ethers";
import LotteryABI from "../../config/abi/NFTLotteryABI.json"; // Adjust the path as needed

// Function to fetch paginated winners using getPaginatedWinners
async function PaginateWinners(lotteryAddress, page, resultsPerPage, provider) {
    let winnersData = {
        winnerAddresses: [],
        prizeAmounts: []
    };

    try {
        // Initialize the Lottery Contract with the provided address, ABI, provider, and signer
        let LotteryContract = new ethers.Contract(lotteryAddress, LotteryABI.abi, provider)
        const [winnerAddresses, prizeAmounts] = await LotteryContract.getPaginatedWinners(page, resultsPerPage);

        // Store the fetched data in the winnersData object
        winnersData = {
            winnerAddresses: winnerAddresses,
            prizeAmounts: prizeAmounts
        };
        console.log("Paginated Inside Winners Data:", winnersData);
    } catch (error) {
        // Handle any errors (e.g., interaction or network issues)
        console.error("Error fetching paginated winners:", error);
    }

    // Log the paginated winners data for debugging purposes

    // Return the winners data
    return winnersData;
}

export { PaginateWinners };
