import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import NftABI from "../../config/abi/NftABI.json";

async function nftInfo( nftAddress, tokenID, provider, signer) {


  let nftInfo = []
  var level = 0

  try {
    let nftContract = new ethers.Contract( nftAddress, NftABI.abi,  provider).connect(signer)
    var info = await nftContract.nftInfo(  tokenID )
    var tokenURI = await nftContract.tokenURI( tokenID)


    if (info[2] == 4){
      //level = parseInt(info[1]) + 108000
      level = 120000
    }
    if (info[2] == 3){
      level = 75000
    }
    if (info[2] == 2){
      level = 15000
    }
    if (info[2] == 1){
      level = 10000
    }

    nftInfo.push(
      //points_, currentLevel_, NFTType_, maxLevel_
      info[0], level ,info[2], info[3],tokenURI
    )

  }
  catch (e) {
    //console.log('error:',e)

  }
  //console.log('nftINFO:',nftInfo)
  return nftInfo
}
export {nftInfo};
