import slotAddresses from './contracts/slotmachine'
import coinFlipAddresses from './contracts/coinflip'

const chainId = process.env.REACT_APP_CHAIN_ID;

export const getSlotMachineEggpAddress = () => {
  return slotAddresses.machineEggp[chainId];
}

export const getSlotMachineCakeAddress = () => {
  return slotAddresses.machineCake[chainId];
}

export const getSlotTokenEprtAddress = () => {
  return slotAddresses.tokenEprt[chainId];
}

export const getSlotTokenEggpAddress = () => {
  return slotAddresses.tokenEggp[chainId];
}

export const getSlotTokenCakeAddress = () => {
  return slotAddresses.tokenCake[chainId];
}

export const getCoinFlipMachineEggpAddress = () => {
  return coinFlipAddresses.machineEggp[chainId];
}

export const getCoinFlipMachineCakeAddress = () => {
  return coinFlipAddresses.machineCake[chainId];
}

export const getCoinFlipMachineBUSDAddress = () => {
  return coinFlipAddresses.machineBUSD[chainId];
}

export const getCoinFlipTokenEprtAddress = () => {
  return coinFlipAddresses.tokenEprt[chainId];
}

export const getCoinFlipTokenEggpAddress = () => {
  return coinFlipAddresses.tokenEggp[chainId];
}

export const getCoinFlipTokenCakeAddress = () => {
  return coinFlipAddresses.tokenCake[chainId];
}

export const getCoinFlipTokenBUSDAddress = () => {
  return coinFlipAddresses.tokenBUSD[chainId];
}