import React, { useState, useEffect } from 'react';
import { NFT_URL_SRC } from '../../config/configurations';
import { ProgressBar } from '../../components/ProgressBar';
import { PlusMinusInput } from '../../components/inputs/PlusMinusInput';
import { BoostNft } from '../../modules/nfts/userBoostNft';
import { nftInfo } from '../../modules/nfts/nftInfo';
import { NftERC20Fees } from '../../modules/nfts/nftERC20Fees';
import contractAddrs from '../../config/contracts/contracts.json';
import { useGlobalState } from '../../components/context/GlobalStateProvider';
import { ApproveBtn } from '../../components/ApproveBtn';
import { AttributePanel } from './AttributePanel'
import noNft from '../../assets/images/icons/noNfts.jpg';
import { useError } from '../../components/context/ErrorProvider';
import './profile.css';

const MainPanel = ({ ownedNfts, selected, setSelected, user }) => {
    const [input, setInput] = useState(150000);
    const [userLevel, setUserLevel] = useState(0);
    const { walletInfo, isConnected } = useGlobalState();
    const [currentMetaTags, setCurrentMetaTags] = useState();
    const [currentAttributes, setCurrentAttributes] = useState()
    const [boostMsg, setBoostMsg] = useState();
    const [maxLevel, setMaxLevel] = useState();
    const [minLevel, setMinLevel] = useState();
    const [curTokenId, setCurTokenId ] = useState();
    const [nCurLevel, setNCurLevel] = useState();
    const [nNextLevel, setNNextLevel] = useState();
    const [progValue, setProgValue] = useState(0);
    const [nERC20Fees, setNERC20Fees] = useState(0);
    const [isApproved, setIsApproved] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const showPopUp = useError();

    var i = 0
    useEffect(() =>{

      const interval = setInterval(() => {
        i  = i + 1
        //console.log('cur selected',curTokenId)
        if (curTokenId ){
          console.log(i,'inter looped');
        }
      },6000)

      return () => clearInterval(interval);

    },[])
    useEffect(() => {
        if (isFirstLoad) { return }

        GetNftInfos()

    }, [selected]);

    useEffect(() => {
        if (!isFirstLoad || !ownedNfts) { return }
        if (Object.keys(ownedNfts).length < 1) { return }
        GetNftInfos()
        setIsFirstLoad(false)
    }, [ownedNfts])

    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    async function GetNftInfos() {
        if (!ownedNfts) { return }
        var nftId = Object.keys(ownedNfts)[selected] //for now takes first in list by default
        var chosenNFT = ownedNfts?.[nftId];
        timeout(2000)
        var nInfos = await nftInfo(contractAddrs.NFTs[369], nftId, walletInfo.provider, walletInfo.signer);
        timeout(2500)
        console.log('inter pause request:',nInfos)
        var erc20Fees = await NftERC20Fees(contractAddrs.NFTs[369], walletInfo.provider, walletInfo.signer);
        console.log('pause request:',erc20Fees)

        //console.log("debug", nInfos)
        if (!erc20Fees || !nInfos) { return } //TODO error handling here
        var levelUP = (parseInt(erc20Fees[1]) / 10 ** 9) * 1000;
        setNERC20Fees(levelUP);
        //console.log('erc20Fees', levelUP);
        //console.log("DEBUG:",ownedNfts,ownedNfts[selected])

        //must be changed to from metaData

        var mMinLevel = 0
        var mMaxLevel = 0
        mMinLevel = chosenNFT?.metaData.attributes[0].value
        mMaxLevel = chosenNFT?.metaData.attributes[1].value
        setCurrentMetaTags(() => {
            const { attributes, ...rest } = chosenNFT?.metaData || {};
            mMinLevel = chosenNFT?.metaData.attributes[0].value
            mMaxLevel = chosenNFT?.metaData.attributes[1].value
            timeout(1000);
            setMinLevel(mMinLevel);
            setMaxLevel(mMaxLevel);
            return rest;
        });
        setCurrentAttributes((chosenNFT?.metaData?.attributes || []).filter(
            attribute => attribute.trait_type !== "Min-level" && attribute.trait_type !== "Max-level"
        ));
        setCurTokenId(chosenNFT)
        setMinLevel(mMinLevel);
        setMaxLevel(mMaxLevel);


        //var currentLevel = parseInt(nInfos[1]) / 1000;
        var currentLevel = mMinLevel + ( (parseInt(nInfos[0]) / parseInt(erc20Fees[1]) ) /1000 );
        //currentLevel = 10.23;

        var currentRounded = Math.floor(currentLevel);
        setNCurLevel(currentLevel);

        var nextLevel = currentRounded + 1;
        setNNextLevel(nextLevel);

        var progress = parseInt((1 - (nextLevel - currentLevel)) * 100);
        if (currentLevel === maxLevel) {
            progress = 0;
        }
        setProgValue(progress);

    }

    async function DoBoost() {
        console.log('boost test button nft:', ownedNfts, selected);
        var fShipAmount = input * 10 ** 9;
        var chosenNFT =  Object.keys(ownedNfts)[selected];

        setBoostMsg('Boosting', chosenNFT);
        var result = await BoostNft(contractAddrs.NFTs[369], fShipAmount, chosenNFT, walletInfo.provider, walletInfo.signer);
        if(result[0]){
            showPopUp("Transaction Success: "+result[1], "success")
          } else {
            showPopUp("Error: "+ result[1], "error")
          }
    }

    if (!ownedNfts || Object.keys(ownedNfts).length < 1) { //0 NFT return state
        return (
            <div className="panel friendship-panel attribute-container">
                <div className="grid-container">
                    <h4 className="grid-item0">Your Friendship Awaits!</h4>
                    <div style={{ height: '300px' }} className="grid-item1">
                        <img
                            src={noNft}
                            alt="NFT Placeholder"
                            className="nft-image"
                            style={{ maxWidth: '300px' }}
                        />
                    </div>

                    <div style={{ height: '300px' }} className="grid-item2">
                        <div className="attribute-panel">
                            <h4 style={{ fontSize: '20px' }}>Coming soon...</h4>
                            <ul>
                                <li>Earn rewards and bonuses</li>
                                <li>Boost Levels to increase Earnings</li>
                                <li>Trade and sell on marketplace</li>
                                <li>Referral Earning..</li>
                            </ul>
                        </div>
                    </div>

                    <div className="level-info-container attribute-panel">
                        <div className="ref-link level-info-text">
                            <p>Share Your Referral Link: </p>
                            <a onClick={isConnected && walletInfo?.signer?.address ? () => { navigator.clipboard.writeText(walletInfo?.signer?.address) } : null}>
                                <p className="ref-text">SommiFantoken.com/dapp/nfts?ref={'\n'}
                                    {isConnected && walletInfo?.signer?.address ? walletInfo?.signer?.address : "Wallet not connected"}</p>
                            </a>
                        </div>

                        <p>Power Up your NFT!!</p>
                        <div className="level-info-text">
                            <p>Min-level</p>
                            <p>Max-level</p>
                        </div>
                        <div className="level-info-text large">
                            <p>1</p>
                            <p>100</p>
                        </div>
                        <div className="level-info-text">
                            <p>Current-level</p>
                            <p>Next-level</p>
                        </div>
                        <div className="level-info-text large">
                            <p>0</p>
                            <p>1</p>
                        </div>
                        <ProgressBar progress={0} />
                        <PlusMinusInput name="quantity" value={input} setValue={setInput} increment={1000} />
                        <div className="btn-panel">
                            <a href="/dapp/nfts" className="button">Purchase Your NFT</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return ( //NFT return state
        <div className="panel friendship-panel attribute-container">
            <div className="grid-container">
                <h4 className="grid-item0">Name: {currentMetaTags && currentMetaTags?.name && currentMetaTags?.name}</h4>
                <div style={{ height: '300px' }} className="grid-item1">
                    <img
                        src={currentMetaTags?.image}
                        alt="Selected NFT"
                        className="nft-image"
                        style={{ maxWidth: '300px' }}
                    />
                </div>


                <div style={{ height: '300px' }} className="grid-item2">
                    <AttributePanel currentAttributes={currentAttributes} />
                </div>

                <div className="level-info-container attribute-panel">

                    <div className="ref-link level-info-text">
                        <p>My Referral Link: </p><a onClick={isConnected && walletInfo?.signer?.address ? () => { navigator.clipboard.writeText(walletInfo?.signer?.address) } : null}><p className="ref-text">SommiFantoken.com/dapp/nfts?ref={'\n'}
                            {isConnected && walletInfo?.signer?.address ? walletInfo?.signer?.address : "Wallet not connected"}</p></a></div>

                    <p>{nERC20Fees} ($FRIENDSHIP) per Level</p>
                    <div className="level-info-text">
                        <p>Min-level</p>
                        <p>Max-level</p>
                    </div>
                    <div className="level-info-text large">
                        <p>{minLevel}</p>
                        <p>{maxLevel}</p>
                    </div>
                    <div className="level-info-text">
                        <p>current-level</p>
                        <p>next-level</p>
                    </div>
                    <div className="level-info-text large">
                        <p>{nCurLevel}</p>
                        <p>{nNextLevel}</p>
                    </div>
                    <ProgressBar progress={progValue} />
                    <PlusMinusInput name="quantity" value={input} setValue={setInput} increment={1000} />
                    <div className="btn-panel">
                        <ApproveBtn user={user} quantity={input} chain={"PULSECHAIN"} contractType={"NFTs"} tokenType={"Friendship"} isApproved={isApproved} setIsApproved={setIsApproved}>
                            <button className='button' onClick={DoBoost}>Level Up</button>
                        </ApproveBtn><button className='refresh button' onClick={GetNftInfos}>⟳</button>
                        <a href='https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/?outputCurrency=0x6A46d56F9Dd963cEAc7f60aD148d49219CC3b79f' target="_blank">
                        <button className='button' value="Buy $FRIENDSHIP">🛒</button></a >
                    </div>
                </div>
            </div>
        </div>
    );
};

export { MainPanel };
