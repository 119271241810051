import { ethers } from "ethers";
import PaginatedQuestions from "../../../../config/abi/prediction/PaginateQuestionsABI.json";
import {PageHelper}  from "./pageHelper";

async function GetQsByTimestamp(timestamp, greaterThan, offset, limit, contractAddress, provider) {
    let result = [null,null]

    try {
        // Initialize the contract instance
        const paginatedQuestionsContract = new ethers.Contract(contractAddress, PaginatedQuestions.abi, provider);
        // Call the contract's function to get questions by timestamp
        const [page1, page2] = await paginatedQuestionsContract.getQuestionsByTimestamp(timestamp, greaterThan, offset, limit);
        // Store the results
        result = PageHelper(page1,page2)

        console.log('Retrieved questions by timestamp:', result);

    } catch (error) {
        console.error('Error retrieving questions by timestamp:', error);
    }

    return result;
}

export { GetQsByTimestamp };
