import React, { useEffect, useState } from 'react';
import { useGlobalState } from './context/GlobalStateProvider';

const WrongChain = ({ expectedChainIdList, showPopUp, wrongChainError, onChainChange, children }) => {
  const { setChainData, chainData } = useGlobalState();
  const [show, setShow] = useState(false);
  const [currentChainId, setCurrentChainId] = useState(null);

  useEffect(() => {
    const checkChain = async (chainIdHex) => {
      const chainId = chainIdHex ? parseInt(chainIdHex, 16) : parseInt(await window.ethereum.request({ method: 'eth_chainId' }), 16);

      if (!expectedChainIdList.includes(chainId)) {
        setShow(false);
        showPopUp(`${wrongChainError}: Switch chains to continue!`, "error");
      } else {
        setShow(true);
      }
    };

    // Check chain on component mount
    checkChain();

    // Set up an event listener for chain changes
    const handleChainChanged = (chainIdHex) => {
        if (onChainChange) {
            onChainChange();
          }
      setCurrentChainId(chainIdHex);
      checkChain(chainIdHex);
    };

    if (window.ethereum) {
      window.ethereum.on("chainChanged", handleChainChanged);
    } else {
      showPopUp("Ethereum provider is not available.", "error");
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, []);

  return show ? children : <div className='panel'><b>{wrongChainError}<br/>Switch chain to continue </b></div>;
};

export { WrongChain };
