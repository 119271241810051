import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json"; // Fixed Product Market Maker ABI
import ERC20ABI from "../../../../config/abi/ERC20ABI.json"; // Assuming ERC20 ABI is available
import { getErrorMessage } from '../../../errorHandler';


async function AddFunding(  fpmmAddress, collateralTokenAddress,  addedFunds,  distributionHint, provider,signer, allowance) {

              // Distribution hint for funding allocation

    let fundingResult = [true, ''];

    try {
        console.log('Adding funding with amount:',collateralTokenAddress, addedFunds, distributionHint);
        const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider).connect(signer);

        if (Number(allowance) < Number(addedFunds)  ){
           const collateralTokenContract = new ethers.Contract(collateralTokenAddress, ERC20ABI.abi, provider).connect(signer);
           const approveTx = await collateralTokenContract.approve(fpmmAddress, addedFunds);
           console.log('Approval transaction sent:', approveTx.hash);
           const receipt = await provider.waitForTransaction(approveTx.hash)
        }

        const tx = await fpmmContract.addFunding( addedFunds, distributionHint, collateralTokenAddress);

        console.log('Funding transaction sent:', tx.hash);
        fundingResult = [true, tx.hash];

    } catch (error) {
        console.error('Error adding funding:', error);
        const errorMsg = getErrorMessage(error);
        fundingResult = [false, errorMsg];
    }

    return fundingResult;
}

export { AddFunding };
