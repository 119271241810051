import React from 'react';
import { FriendshipPanel } from '../../components/FriendshipPanel';

const FriendshipPanelController = ({ user }) => {
    return (
        <>
            {user?.data && Object.entries(user.data).map(([chain, data]) => {
                return (
                    <FriendshipPanel
                        key={chain} // Add a unique key for each element
                        chain={chain}
                        balance={data?.balance} // Provide default values to avoid warnings
                        sellRatio={data?.sellRatio}
                        userShare={data?.userShare}
                        userStatus={data?.userStatus}
                        userTracker={data?.userTracker}
                        nativeTokens={user?.nativeTokens?.[chain]}
                        friendshipTokens={user?.friendshipTokens?.[chain]}
                    />
                )
            })}
        </>
    );
};

export { FriendshipPanelController };