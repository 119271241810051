import React, { useEffect, useState }  from "react";
import { ethers } from "ethers";
import { MyBigInt } from "../MyBigInt";
import {ChainSelect} from './ChainSelect';
import { useGlobalState } from '../../components/context/GlobalStateProvider';

const ConnectWithSelect = () =>{
  const {walletInfo, setWalletInfo, chainData, isConnected, setIsConnected} = useGlobalState();

  useEffect(()=>{
    if(window?.ethereum?.isConnected()){
      onConnectMetaMask()
    }
  }, [chainData?.chainId])

  useEffect(()=>{
    if(window?.ethereum?.isConnected()){
      //console.log("connected Eth object exists", window?.ethereum?.isConnected())
      onConnectMetaMask()
    } else { //console.log("connected Eth object doesntExist!")

    }
  }, [])

  useEffect(()=>{
    if(chainData?.chainId != window?.ethereum?.chainId)
    {
      onConnectMetaMask()
    }
  },[chainData?.chainId])

  const onConnectMetaMask = async () => {
    try {
      const provider = window?.ethereum ? await new ethers.BrowserProvider(window.ethereum) : ethers.getDefaultProvider();
      const signer = provider ? await provider.getSigner() : null;
      
      if (signer) {
        const chainId = chainData?.chainId || window.ethereum?.networkVersion;
        if (window.ethereum.networkVersion !== chainId) {
          try {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: `${chainId}` }],
            });
          } catch (error) {
            console.error('Failed to switch chain:', error);
          }
        }
        setWalletInfo({ signer, provider });
        setIsConnected(true);
      }
    } catch (e) {
     // console.log(e);
    }
  };  

const disConnect = () =>{
  setWalletInfo()
  setIsConnected(false)
}

  return (
    <>
      {!isConnected ? 
        <button className="wallet-button" onClick={onConnectMetaMask}>Connect Wallet</button>
        :
        <button className="wallet-button" onClick={disConnect}>{walletInfo ? walletInfo.signer.address.slice(0, 5) : "connecting"}...{walletInfo ? walletInfo.signer.address.slice(-2) : null}</button>
        }</>
  );
};

export {ConnectWithSelect}