function cleanNumbers(number, decimals) {
    const factor = Math.pow(10, decimals);
    var value = Math.floor(number * factor) / factor;

    return value.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 5
      })
  }

  const numToPercent = (num)=>{
    // Calculate the magnitude (order of magnitude) of the number
    const magnitude = Math.floor(Math.log10(num));

    // Normalize the number to a range where standard rounding can be applied
    const normalizedNum = num / Math.pow(10, magnitude - 1);

    // Round the normalized number
    const roundedNormalizedNum = Math.round(normalizedNum);

    // Scale the number back up
    const roundedNum = roundedNormalizedNum * Math.pow(10, magnitude - 1);

    // Convert to an integer with two significant digits
    const significantDigitsNum = roundedNum / Math.pow(10, magnitude - 1);
return Math.round(significantDigitsNum);
}

  export {cleanNumbers, numToPercent};