import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";




// Function to get Collection ID
async function CollectionId(conditionalAddress, parentCollectionId, conditionId, indexSet, provider) {

    var collectionId = 'N/A'
    try {
        console.log('Fetching Collection ID for:', parentCollectionId, conditionId, indexSet);

        // Instantiate the contract
        let conditionalTokenContract = new ethers.Contract(conditionalAddress, ConditionalTokenABI.abi, provider);
        collectionId = await conditionalTokenContract.getCollectionId(parentCollectionId, conditionId, indexSet);

        console.log('Collection ID:', collectionId);

        return collectionId;
    } catch (error) {
        console.error('Error fetching Collection ID:', error);
        //throw error;
    }
}

export { CollectionId };
