import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json";


async function GetPositionIds(fpmmAddress, provider) {
  // Initialize the variable to hold position IDs
  let positionIds = [];

  try {
    // Initialize the contract instance
    const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider);
    positionIds = await fpmmContract.getPositionIds();
    //console.log('Position IDs:', positionIds);
  } catch (e) {
    console.error('Error fetching position IDs:', e);
  }

  // Return the position IDs (empty if an error occurred)
  return positionIds;
}

export { GetPositionIds };
