import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import NftABI from "../../config/abi/NftABI.json";
import { getErrorMessage } from "../errorHandler";

async function BoostNft( nftAddress, amount,  tokenID, provider, signer) {

  var boost = [true, '']
  //console.log('BoostNft',  nftAddress, amount,  tokenID, provider, signer )
  try {
    let nftContract = new ethers.Contract( nftAddress, NftABI.abi,  provider).connect(signer)
    boost = await nftContract.userBoostNft( amount, tokenID)

    const receipt = await boost.wait();
    boost = [true, receipt.blockHash]

  }
  catch (e) {
    var errorMsg = getErrorMessage(e)
    console.log('boost error:',errorMsg)

    boost = [false, 'Boosting Error: '+errorMsg]
  }

  return boost
}
export { BoostNft };
