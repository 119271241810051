import React, { useState, useEffect } from 'react';
import { TokenBalance } from '../../modules/tokenBalance';
import { Allowance } from '../../modules/allowance'
import { CClaim } from '../../modules/actions/cClaim';
import blueBlack from '../../assets/images/background/blue-black.jpg'
import tokensAddrs from '../../config/contracts/tokens.json'
import contractAddrs from '../../config/contracts/contracts.json'
import { VDepositInfo, VTimes } from '../../modules/vesting';
import moment from 'moment';
import { useGlobalState } from '../../components/context/GlobalStateProvider';

const Claims = () => {
  const { storedTokenInfo, chainData, setChainData, walletInfo, isConnected } = useGlobalState();
  const [vResponse, setVResponseData] = useState()
  const [buttonManager, setButtonManager] = useState({ approve: false, deposit: false, withdraw: false, msg: null })
  const [cBalances, setCBalances] = useState()
  const [isApproved, setIsApproved] = useState(false)


  useEffect(() => { //WALLET INFO HERE!
    if (isConnected) {
      const fetchData = async () => {
        try {
          var chainId = "0x" + (369).toString(16);
          var claimsAddress = contractAddrs.claims[369]
          console.log('claimsAddress:', claimsAddress)
          var tokenAddress = tokensAddrs.Friendship[369]
          setChainData({ claimsAddress: claimsAddress, tokenAddress: tokenAddress, chainId: chainId })
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      fetchData();
    }
  }, [isConnected])


  function rnd(num) {
    var rnum = Math.round(num * 100) / 100
    return rnum
  }

  useEffect(() => {
    if (isConnected) {
      const tmpFunc = async () => {
        try {
          console.log("stored:", chainData, walletInfo)
          let allowance = await Allowance(chainData.tokenAddress, chainData.claimsAddress, walletInfo.provider, walletInfo.signer)
          console.log("stored:", allowance)
        } catch (e) { console.log("vesting", e) }
      }
      tmpFunc()

      if (!walletInfo.signer) {
        setButtonManager({ approve: false, deposit: false, withdraw: false, msg: 'Please Connect Wallet!' })
      } else {
        if (!isApproved) {
          setButtonManager({ approve: true, deposit: true, withdraw: true, msg: 'Please Approve Wallet!' })
        } else { setButtonManager({ approve: true, deposit: true, withdraw: true, msg: 'Wallet Already Approved!' }) }

        //if (input > max) set buttonManager.deposit false
      }
    } else { console.log("nada") }
  }, [isConnected]);

  useEffect(() => {
    if (!isConnected) {
      if (!chainData) {
        console.log("collectedData noDat", chainData, isConnected)

        return
      }
    }
    const getData = async () => {
      //console.log("collectedData")
      try { //vestingAddress, tokenAddress, provider, signer
        let ubalance = await TokenBalance(chainData.tokenAddress, walletInfo.signer.address, walletInfo.provider, storedTokenInfo)
        let vbalance = await TokenBalance(chainData.tokenAddress, chainData.claimsAddress, walletInfo.provider, storedTokenInfo)
        setCBalances({ 'vBalance': vbalance, 'uBalance': ubalance })
        let vestingTimeData = await VTimes(chainData.claimsAddress, walletInfo.provider, walletInfo.signer)
        let userDepositInfo = await VDepositInfo(chainData.claimsAddress, chainData.tokenAddress, walletInfo.provider, walletInfo.signer)

        //console.log("collectedData1:", vestingTimeData, userDepositInfo, allowanceInfo, vBalance, uBalance )
        setVResponseData({
          'vestingTimeData': vestingTimeData,
          'userDepositInfo': userDepositInfo,
          'vBalance': vbalance,
          'uBalance': ubalance
        })

        //console.log('set balances:', vBalance, uBalance, vestingTimeData )

      } catch (error) {
        console.log('collectedData2:', error)
      }
      //console.log("collectedData2")
    };

    // Set the refresh interval to, for example, 5000 milliseconds (5 seconds)
    const refreshInterval = setInterval(getData, 2500);

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(refreshInterval);
  })

  function balanceData() {
    const balDict = {}
    try {
      balDict['userBal'] = rnd(cBalances['uBalance'])
      balDict['vestingBal'] = rnd(cBalances['vBalance'])
    } catch {
      balDict['userBal'] = 0
      balDict['vestingBal'] = 0
    }
    return balDict
  }

  var errorString = 'You Must Connect Wallet Before Using This Feature!'
  var bln = balanceData()

  // get Vesting Times data
  function getVTimes() {
    const vTimesDict = {}
    var t = new Date();
    try {
      var strt = parseInt(Number(vResponse['vestingTimeData'][0][0]))
      vTimesDict['start'] = moment.unix(strt).format("YYYY-MM-DD");

      var end = parseInt(Number(vResponse['vestingTimeData'][0][1]))
      vTimesDict['end'] = moment.unix(end).format("YYYY-MM-DD"); //

      var lck = Number(vResponse['vestingTimeData'][0][2])
      vTimesDict['lock'] = lck / (24 * 3600);//Number(vResponse['vestingTimeData'][0][2])

      vTimesDict['duration'] = rnd((end - strt) / 86400)
    }
    catch (e) {
      console.log('moment:', e);
      vTimesDict['start'] = 0
      vTimesDict['end'] = 0
      vTimesDict['lock'] = 0
      vTimesDict['duration'] = 0

    }
    return vTimesDict

  }

  var vTimes = getVTimes()

  function getVUserData(lockhrs) {
    const vUdict = {}
    try {
      var claimAmount = vResponse['userDepositInfo'][1]
      vUdict['clAmount'] = rnd(parseInt(Number(claimAmount)) / (10 ** 9))

      var lastclaimTime = parseInt(vResponse['userDepositInfo'][2])
      vUdict['lClaimTime'] = moment.unix(lastclaimTime).format("YYYY-MM-DD HH:mm");

      var nextClaimTime = parseInt(Number(vResponse['userDepositInfo'][2])) + (lockhrs * 3600 * 24)
      //console.log('nextClaimTime:',vResponse['userDepositInfo'][2], nextClaimTime, lockhrs)
      if (vResponse['userDepositInfo'][2] == 0) {
        nextClaimTime = Number(vResponse['vestingTimeData'][0][0]) + (lockhrs * 3600 * 24)
        vUdict['lClaimTime'] = 'NA'
      }
      vUdict['nClaimTime'] = moment.unix(nextClaimTime).format("YYYY-MM-DD HH:mm");

      var nextClaim = vResponse['userDepositInfo'][3]
      vUdict['nxclAmount'] = rnd(parseInt(Number(nextClaim)) / (10 ** 9))

      var cycles = vResponse['userDepositInfo'][4]
      vUdict['cycle'] = parseInt(Number(cycles))

      vUdict['waitTime'] = (nextClaimTime - moment.unix(Date.now()))

      var depositAmount = rnd(parseInt(Number(vResponse['userDepositInfo'][0])) / (10 ** 9))
      vUdict['dpAmount'] = depositAmount
    }
    catch (e) {
      console.log('vdict', e)
      vUdict['clAmount'] = 0
      vUdict['lClaimTime'] = 0
      vUdict['dpAmount'] = 0
      vUdict['waitTime'] = 0
      vUdict['nClaimTime'] = 0
      vUdict['nxclAmount'] = 0
    }
    return vUdict
  }
  var vUData = getVUserData(vTimes['lock'], vTimes['start'])
  const withdraw = (e) => {
    e.preventDefault();
    if (!walletInfo) {
      return
    }
    //get value from withdraw field
    try { //vestingAddress, tokenAddress, provider, signer
      var claim = CClaim(chainData.claimsAddress, chainData.tokenAddress, walletInfo.provider, walletInfo.signer)
      console.log('claim log:', claim)

    } catch (e) { console.log("claim function failed:", e) }

  }

  return (
    <>
      <section className="newsletter section">
        <div className='demoDapp-container'>
          <div className='centered-div customForm nftPage'>
            <h3 className="vhead"> Claiming Your Free Tokens on Pulsechain</h3>
            <p>During migration we keep your FRIENDSHIP safe by storing it in a smart contract.
              You will receive an Airdrop of tokens 1:1 as we launch on Pulsechain.
              Every 2 days you will be able to claim  your Free tokens into your Pulsechain wallet.
              This is gradually release is to incentivise holding & price stability on both chains.

              If you earned our referal Airdrop, your Pulsechain token balance will also reflect that.</p>

            <b>$FRIENDSHIP</b><br />
            <div className="pls">Total Locked in Contract: {bln['vestingBal']} <br />
              In My Wallet: {bln['userBal']} </div><br />
            Duration: {vTimes['duration']}  Days || Full Unlock: {vTimes['end']} <br />
            Withdraw Every:  {vTimes['lock']}  Days<br />
            <b>My Total Claimable:{vUData['dpAmount']} </b>
            <button className='button form_button' onClick={withdraw}>Claim</button>
            Next Token Unlock :  {vUData['nClaimTime']} <br />
            <b>Previously Claimed: {vUData['clAmount']} </b> ({vUData['lClaimTime']}) <br />
            <b>Next Claim Estimate: {vUData['nxclAmount']}</b><br />
          </div>
        </div>
        <img src={blueBlack} />
      </section>
    </>
  )
}

export { Claims };
