import React from 'react'
import './MetamaskButton.css'
//import metamaskLogo from '../assets/svgs/metamask-fox.svg'


function MetamaskButton({buttonText}) {
    return (
        <>
            <button id="metamaskButton">
                <img src={''} width="20px" /> {buttonText} 
            </button>
        </>
    )
}

export default MetamaskButton
