import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
//import { FormatNumber } from "./../FormatNumber";
import tmpImg from "../../assets/images/NFT-Promo/Sommi-NFTs-poster-1.png";
import { cachedList } from "../../config/markets/marketsConfig";
import { useGlobalState } from "../../components/context/GlobalStateProvider";
import { PlusMinusInput } from "../inputs/PlusMinusInput.jsx";
import { Single } from "./predictionStates/Single";
import { Multi } from "./predictionStates/Multi";
import { Liquidity } from "./predictionStates/Liquidity";
import { Redeem } from "./predictionStates/Redeem";
import { Positions } from "./predictionStates/Positions";
import { Admin } from "./predictionStates/Admin";
import { Tabs } from "../Tabs";
import "../../pages/prediction/BetCard.css";
import { useLocation } from 'react-router-dom';

const Controller = ({children, ...props}) => {
  //although options and betPercent not called in this file THEY ARE NEEDED (passed as props)!!!
  const { qId, question, questionDetails, loading, questionList, totalBet, state, source_name, resolution_date_time,
    setState, collateralToken, questionId, activeController,  questionSelect, collateralInfo,
    vInputChange, tvlBalance, source_img, options, vEventAddress} = props;

  const {walletInfo} = useGlobalState()
  const [showPercent, setShowPercent] = useState(0);
  const defaultViewData = {input: 0, userMultiPartSelection: "", userBinaryPartSelection: "", distributionInfo: []}
  const [viewData, setViewData] = useState(defaultViewData)
  const [distributionInfo, setDistributionInfo] = useState()

  const location = useLocation();
  const [tabs, setTabs] = useState({public: [ "buy", "sell", "liquidity", "redeem" ], private: ["admin"]});
  const previousActive = useRef(activeController);
  const adminList = ['0x995fa5eadCDf96268C7a99357d87fe89dCF7EDd2','0xb8bF8D8cE14F092A21dA559ae2a4eD84EFF64b09']

  const baseQuestionInfo = useMemo(() => {
    return questionId
      ? questionList?.find((q) => q.questionId === questionId)
      : null;
  }, [questionId]);

  useEffect(() => {
    if (previousActive.current === qId && activeController !== qId) {
      //handleClearSelection();

    }
    //vInputChange( [  [questionDetails] , "active"]);
    previousActive.current = activeController;
  }, [activeController]);

//
  const updateInput = (value) => updateViewData("input", value);


  const updateViewData = (key, value) => {

    if (state?.predictionState=== "buy"){ vInputChange( [value, "buyTokensIn"]) ; }
    if (state?.predictionState=== "sell"){ vInputChange( [value, "sellTokensIn"]) }
    if (state?.predictionState=== "liquidity"){ vInputChange( [value, "liquidityIn"]) }
    if (state?.predictionState=== "liquidity"){ vInputChange( [value, "liquidityOut"]) }
    setViewData(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  //check this isn't updateing with the view data .. input field

  //()

  const InputHandle = ({input, setinput}) => {
    return (
      <div
        className={
          activeController !== qId
            ? "betcard-input-controller"
            : "betcard-input-controller-active"
        }
      >
        <PlusMinusInput
          name="quantity"
          value={viewData?.input}
          setValue={updateInput}
          step={1000}
        />
      </div>
    );
  };






  const BetCardStateHelper = ({children, ...props}) => {
    switch (state?.predictionState) {
      case "buy":
      case "sell":
        switch (state?.viewType) {
          case "single":
            return (
              <Single
                {...props}
                questionSelect={questionSelect}
                showPercent={showPercent}
                setShowPercent={setShowPercent}
                view={state?.predictionState}
                viewData={viewData}
                updateViewData={updateViewData}
                InputHandle={InputHandle}
                children={children}
              />
            );
          case "multi":
            return (
              <Multi
                {...props}
                questionSelect={questionSelect}
                showPercent={showPercent}
                setShowPercent={setShowPercent}
                view={state?.predictionState}
                viewData={viewData}
                updateViewData={updateViewData}
                InputHandle={InputHandle}
                children={children}
              />
            );
          default:
            console.log("error", state);
            return null; // Return a default component or null
        }
      case "redeem":
        return <Redeem {...props} viewData={viewData} updateViewData={updateViewData} InputHandle={InputHandle}/>;
      case "positions":
          return <Positions {...props} viewData={viewData} updateViewData={updateViewData} InputHandle={InputHandle} />;
      case "admin":
              return <Admin {...props} viewData={viewData} updateViewData={updateViewData} InputHandle={InputHandle} />;
      case "liquidity":
        return <Liquidity {...props} viewData={viewData} setViewData={setViewData} updateViewData={updateViewData}
        distributionInfo={distributionInfo} setDistributionInfo={setDistributionInfo} InputHandle={InputHandle}/>;
      default:
        console.log("outer error");
        return null; // Handle other cases or return null if no match

    }
  };


  const handleAreaClick = useCallback(( e, questionId ) => {
    let options = props.options
    //console.log('active:', props.options)
     vInputChange([ [options, questionId, vEventAddress ], "activeCard"]);
     //console.log('active bitch', questionId )

   }, [vInputChange])


  if (questionId && loading) {
    return <p>Loading details...</p>;
  }
  // if (questionId && !baseQuestionInfo) { //TODO THIS LOGIC IS NEEDED WHEN FINISHED! it blocks non existing questions
  //   return (
  //     <div className="panel">
  //       <p>Question not found in question list</p>
  //     </div>
  //   );
  // }
  const genericSetView = (info) => {
    setState({ ...state, predictionState: info });
  };
  //console.log('source_img',source_img)
  return (
    <div id={location.pathname === '/dapp/prediction'  && "bet-card-prediction"} className={`bet-card ${questionId ? "bet-card-more-details" : ""}`}
    onMouseOver={(e) => handleAreaClick(e, [qId,question])}  >
      <div className="bet-header">
        <img src={ source_img || tmpImg} alt="c" className="bet-image" />
        <div className={`bet-title ${questionId ? "bet-title-more-details" : ""}`} >
          <a className="bet-title-anchor" href={`/dapp/prediction?id=${qId}`}>
            <span>{baseQuestionInfo ? baseQuestionInfo?.title : question}</span>
          </a>
        </div>
      </div>
      {location.pathname === '/dapp/prediction' &&
      <Tabs
        view={state?.predictionState}
        tabs={tabs}
        conditional={ adminList.includes(walletInfo?.signer?.address) }
        setView={genericSetView} />
       }
      <BetCardStateHelper {...props} children={children} />
      {(questionDetails || state?.predictionState !== "liquidity") && (
        <div className="bet-footer">
        <div className="footer-data">
          {/* {totalBet&& <b> //TVL DATA FROM WEB3??
            TVL: ${totalBet}{" "}
              </b>} <span><b>TVL:</b> <i>${tvlBalance} {collateralInfo?.name}</i></span> */ }

              <span><b>Source:</b> <i>{source_name}</i></span>
              <span><b>Resolution Date:</b> <i>{resolution_date_time}</i></span>
          </div>
        </div>
      )}
    </div>
  );
};

export { Controller };
