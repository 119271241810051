import React, { useState, useEffect } from 'react';
import './input.css';

const PlusMinusInput = ({value, setValue, step=1, max=50000000}) => {
  const [focused, setFocused] = useState(false);
  const [localValue, setLocalValue] = useState(0);

  useEffect(()=>{
    setLocalValue(value)
  },[])

  const handleIncrement = () => {
    if(value+step >= max){return}
    setValue(value + step);
  };

  const handleDecrement = () => {
    if (value >= step) {
      setValue(value - step);
    }
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 0) { // Allow 0 as a valid value
      setLocalValue(newValue);
    }
  };

  const handleBlur = (e) =>{
    setValue(localValue);
    setFocused(false);
  }

  return (
    <div
      className={`CustomNumberInput ${focused ? 'focused' : ''}`}
      onFocus={() => setFocused(true)}
      onBlur={handleBlur}
    >
      <input
        key="editor1"
        value={localValue}
        onChange={handleChange}
        min="1"
        className="input"
      />
      <button className="btn increment" onClick={handleIncrement}>
        <span className="arrow">&uarr;</span>
      </button>
      <button className="btn decrement" onClick={handleDecrement}>
        <span className="arrow">&darr;</span>
      </button>
    </div>
  );
};

export {PlusMinusInput};
