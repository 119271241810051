import { ethers } from "ethers";
import LotteryABI from "../../config/abi/NFTLotteryABI.json"; // Adjust the path as needed

// Function to fetch paginated extra data using getPaginatedWinnersExtra
async function PaginateWinnersExtra(lotteryAddress, page, resultsPerPage, provider) {

    let winnersExtraData = {
        prizeAddresses: [],
        drawTimes: [],
        tokenIDs: []
    };

    try {
        // Initialize the Lottery Contract with the provided address, ABI, provider, and signer
        let LotteryContract = new ethers.Contract(lotteryAddress, LotteryABI.abi, provider);
        const [prizeAddresses, tokenIDs, drawTimes] = await LotteryContract.getPaginatedWinnersExtra(page, resultsPerPage);
        //const some =  await LotteryContract.getaginatedWinnersExtra(page, resultsPerPage);

        winnersExtraData = {
            prizeAddresses: prizeAddresses,
            drawTimes: drawTimes,
            tokenIDs: tokenIDs
        };
          console.log("Paginated Extra insider Winners Data:", winnersExtraData);
    } catch (error) {
        // Handle any errors (e.g., interaction or network issues)
        console.error("Error fetching paginated extra winners:", error);
    }

    // Log the paginated extra winners data for debugging purposes
    //
    console.log("Paginated Extra Winners Data:", winnersExtraData);

    // Return the winners extra data
    return winnersExtraData;
}

export { PaginateWinnersExtra };
