import React, { useState, useEffect, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import yellowBlack from '../../assets/images/background/green-black.jpg';
import { useNavigate  } from 'react-router-dom';
import './memeGallery.scss'

const caches = {
    defaultCache: {},
    compCache: {},
}

function importAll(r, cache) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}

importAll(require.context("../../../public/memes/defaultCache", false, /\.(png|jpe?g|svg)$/), caches.defaultCache);
importAll(require.context("../../../public/memes/compCache", false, /\.(png|jpe?g|svg)$/), caches.compCache);



const Spinner = () => (
    // Customize the spinner component based on your design
    <div className="spinner">
        Loading...
    </div>
);
const pageViewSelect = [
    'defaultCache',
    'compCache'
]

const MemeGallery = () => {
    const [images, setImages] = useState([]);
    const [scaledImages, setScaledImages] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [loading, setLoading] = useState(true); // New state to track loading status
    const [viewPage, setViewPage] = useState('defaultCache')
    let navigate = useNavigate ();

    useEffect(() => {
        // Update the viewPage state when the URL changes
        const pathSections = window.location.pathname.split('/').filter(Boolean);
        const lastSection = pathSections[pathSections.length - 1];
        if (!lastSection || !pageViewSelect.includes(lastSection)) {
            //setViewPage('defaultCache')
            navigate(`defaultCache`)
        } else{
            setViewPage(lastSection)
            navigate(lastSection)
        }
      }, []);

    useEffect(() => {
        fetchImages();
        navigate(viewPage)
    }, [viewPage]);


    const fetchImages = async () => {
        const newImages = Object.entries(caches[viewPage]).map((p, ind) => {
            var tmpWord = p[0].split("/")[1];
            var tmpWord2 = tmpWord.split(".")[0];
            var finWord = tmpWord2.charAt(0).toUpperCase() + tmpWord2.slice(1);
            return {
                src: process.env.PUBLIC_URL + "/memes/" + viewPage + "/" + tmpWord,
                id: ind,
                title: finWord,
                alt: p[0], // You can set a meaningful alt text
            }
        });
        setImages(newImages);

        const promises = newImages.map((image) => {
            return new Promise((resolve, reject) => {
                const imgElement = new Image();
                imgElement.onload = () => {
                    const naturalWidth = imgElement.naturalWidth / 2;
                    const naturalHeight = imgElement.naturalHeight / 2;
                    const scaledImage = {
                        ...image,
                        width: naturalWidth,
                        height: naturalHeight,
                    };
                    resolve(scaledImage);
                };
                imgElement.onerror = (error) => {
                    console.error(`Error loading image: ${image.src}`, error);
                    reject(error);
                };
                imgElement.src = image.src;
            });
        });

        Promise.all(promises)
            .then((newScaledImages) => {
                setScaledImages(newScaledImages);
                setLoading(false); // Set loading to false once images are loaded
            })
            .catch((error) => {
                console.error("Error loading images:", error);
                setLoading(false); // Handle loading state in case of an error
            });
    };

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };


    const CustomHeader = ({ currentIndex, onClickImageWrapper }) => (
        <div onClick={() => onClickImageWrapper()} style={{ display: 'flex', position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', top: '5em', zIndex: 1 }}>
            <h2 style={{ color: 'yellow' }}>{images[currentIndex].title}</h2>
        </div>
    );

    const customRenderImage = ({ index, onClick, photo, margin, direction, top, left }) => {
        return (
            <img
                className='meme-gallery-img'
                key={index}
                onClick={(e) => onClick(e, { photo, index })}
                src={photo.src}
                alt={`Photo ${index + 1}`}
                height={scaledImages[index].height}
                width={scaledImages[index].width}
            />
        );
    };


    return (
        <section className="media section"  style={{ padding: 0 }}>
        <div className="bg-rpt">
            <div id='meme-container' className="container-md">
                <div className="media-inner section-inner" style={{paddingBottom: 0}}>
                    <div className="media-header text-center">
                        <div className="container feature-inner" style={{ padding: 0 }}>
                            <h2 className="meme-head"> Sommi Fan Art Gallery </h2>
                            <p className="memePs" ><a href="https://x.com/yourfriendSOMMI"  target="_blank">@yourfriendSOMMI</a> is a super influential crypto influencer! Here at Sommi Fan Token we pay homage to his 18 hour work days with $FRIENDSHP & MEMES.

                            <br/ > <br/ >Check out our yourfriendSOMMMI fan art galleries below.

                            One day Sommi will be a household name and were going to be part of the journey. $FRIENDSHP holders can access 100s mores meme in our <a href="https://t.me/SommiFanBot"  target="_blank">telegram bot.</a> </p>
                        </div>
                        <div className="btn_container container">
                        <button className='button ' id='meme-button' onClick={(()=>{setViewPage('defaultCache')})}>SOMMI AI</button>
                        <button className='button' id='meme-button' onClick={(()=>{setViewPage('compCache')})}>FRIENDSHIP</button>
                    </div>
                        {loading && <Spinner className="spinner"/>} {/* Display the spinner while loading */}
                        {!loading && (
                            <Gallery className='gallery-photoAlbum' loading='lazy' renderImage={customRenderImage} margin={3} direction={"row"} photos={scaledImages} onClick={openLightbox} />
                        )}
                        <ModalGateway >
                            {viewerIsOpen && (
                                <Modal onClose={closeLightbox}>
                                    <Carousel
                                        currentIndex={currentImage}
                                        views={images.map((x) => ({
                                            ...x,
                                            srcset: x.srcSet,
                                            caption: x.title,
                                        }))}
                                        components={{/*
                                            Header: ({ currentIndex }) => (
                                                <CustomHeader
                                                    currentIndex={currentIndex}
                                                    onClickImageWrapper={closeLightbox}
                                                />)
                                            */}}
                                    />
                                </Modal>
                            )}
                        </ModalGateway>
                    </div>
                </div>
            </div>
            <div className="background-divider"><img src={yellowBlack} /></div>
          </div>
        </section>

    );
};

export { MemeGallery };
