import React from 'react';
import sommiFanBanner from '../../assets/images/sommi fan banner.jpg'
import sommiFanToken from '../../assets/images/sommi-fan-token-friendship-token-distribution-yourfriendsommi.png'

const Tokenomics = (props) =>{
    return(
      <section className="features section">
        <div className="container-sm">
        <div className="media-inner section-inner">
          <div className="media-header text-center">
            <div className="text-box">
              <div className="feature-icon">
                <img src={sommiFanBanner}/>
              </div>
              <h2 className="section-title-2">What is Sommi Fan Token?</h2>
              <p className="section-paragraph">
                Sommi Fan token is the No.1 Fan Token for crytpo influencer{" "}
                <a href="https://twitter.com/yourfriendSOMMI">
                  @yourfriendSOMMI
                </a>
                .
                <br />
                Who is sommi and why do we love him? Check out yourfriendSOMMI's
                latest videos below to find out!
              </p>
            </div>
          </div>
          <br />
          <div className="media-canvas">
            <iframe
              width="100%"
              height={450}
              src="https://www.youtube.com/embed/videoseries?list=UUeXcbXPVKdc1Gs4E7z-j7sA"
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen=""
            />
          </div>
          <br />
          <div className="text-box" id="token">
            <h2 className="section-title-2">What does Sommi Fan Token Do?</h2>
            <p className="section-paragraph">
              ⚠️ Sommi Fan Token It is 100% NOT Affiliated with or Endorsed by
              Sommi⚠️ If you've listened to his videos or posts on X.com, you'd
              know that he loves other projects far too much to create his own.
              <br />
              <br />
              This project was made because we love what he does and we know you
              do too, it has ZERO utility. Any Sommi Fan Tokens you hold should
              be considered for <b>Fun and Speculation ONLY!</b>
              <br />
              <br />
              <img src={sommiFanToken} />
              Sommi fan token will operate as a DAO, so if have skill and you'd
              like to get involed in the project please{" "}
              <a href="https://t.me/SommiFanToken" target="”_blank”">
                let us know
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
    )
}

export {Tokenomics};