const userNftCollection = async (walletId, PLS_NFT_HOLDERS) => {
    // Helper function to fetch data with pagination
    const fetchData = async (url, walletId, params = {}) => {
        // Include holder_address_hash in the params
        params.holder_address_hash = walletId;
        const query = new URLSearchParams(params).toString();
        const response = await fetch(`${url}?${query}`);

        if (!response.ok) { return new Error("Network Error: Bad Response!"); }
        try {
            return await response?.json(); // Deserialize data     //noNFT user needs response.data.json WTF       
        } catch (e) {
            return new Error("debug Error Deserialising Json", response);
        }
    };
    
    let dictNFT = {};
    let nextPageParams = {};
    let hasNextPage = true;
  
    while (hasNextPage) {
        let data;
        try {
            data = await fetchData(PLS_NFT_HOLDERS, walletId, nextPageParams);
        } catch (error) {
            return error.message;
        }
        if (!data){return []} //NoNftsFound
        if (!data.items || !data.items[0]) { 
            return []; //NoNftsFound
        }

        data.items.forEach((nft) => {
                dictNFT[nft.id] = { url: nft.image_url, metaData: nft.metadata };
        });
        
        // Check if there are more pages to fetch
        if (data.next_page_params?.unique_token) {
            nextPageParams = { unique_token: data.next_page_params.unique_token };
        } else {
            hasNextPage = false;
        }
    }
    return dictNFT;
  };
  
  export { userNftCollection };
  