import React from 'react';

const Positions = (props) =>{

    return(
      <div>
        <h1>Positions</h1>
        My Shares:
        this part is basically a list of IDs from the NFTs that hold the position, ideally i'll find the following info from the NFTs
        - price we bought.
        - current price to sellShares
        - % change in position


        if someone has multiple positions in the same bet we need to handle in an array.
        (later we might want to just list all their positions on the profile page too but litterly a link to here. like the list of NFTs)


<div><button>Redeem</button></div>
        </div>


    )
}


export {Positions};
