import React, {useEffect, useState} from 'react';
import {Introduction, About, Tokenomics, FAQ} from './'
import blueBlack from '../../assets/images/background/blue-black.jpg';
import greenBlue from '../../assets/images/background/green-blue.jpg';
import redYellow from '../../assets/images/background/red-yellow.jpg';
import yellowGreen from '../../assets/images/background/yellow-green.jpg';


const Home = () => {
    return(
        <div>
            <div id="introduction" className="nav-link"/>
            <Introduction />
            <div className="background-divider"><img src={redYellow} /></div>

            <div id="tokenomics" className="nav-link"/>
            <Tokenomics/>

            <div className="background-divider"><img src={yellowGreen} /></div>

            <div id="about" className="nav-link"/>
            <About />
            <div className="background-divider"><img src={greenBlue} /></div>

            <div id="faq" className="nav-link"/>
            <FAQ/>
            <div className="background-divider"><img src={blueBlack} /></div>
        </div>
    )
}

export {Home};