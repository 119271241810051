import { ethers } from "ethers";
import PaginatedQuestions from "../../../../config/abi/prediction/PaginateQuestionsABI.json";

async function GetUrlString(urlId, contractAddress, provider) {
    let result = null;

    try {
        // Initialize the contract instance
        const paginatedQuestionsContract = new ethers.Contract(contractAddress, PaginatedQuestions.abi, provider);

        // Call the contract's function to get the URL string

        result = await paginatedQuestionsContract.getUrlString(urlId);

        console.log('Retrieved qbaseURL string:', result);

    } catch (error) {
        console.error('Error retrieving URL string:', error);
    }

    return result;
}

export { GetUrlString };
