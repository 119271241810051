import { GetQsByPage} from '../../modules/prediction/questionDB/getQuestions/getQsByPage';
import { GetQsByTradingState} from '../../modules/prediction/questionDB/getQuestions/getQsByTradingState';
import { GetQsByResolutionDate} from '../../modules/prediction/questionDB/getQuestions/getQsByResolutionDate';
import { GetQsBySource} from '../../modules/prediction/questionDB/getQuestions/getQsBySource';
import { GetQsByCategory} from '../../modules/prediction/questionDB/getQuestions/getQsByCategory';
import { GetQsByTimestamp} from '../../modules/prediction/questionDB/getQuestions/getQsByTimestamp';
//number of records
//contract address

async function GetQsByfilter(provider, pgContractAddress, filter="noFilter", tradingState=200 ){ //Make into seperate component
    var arbPaginate = '0x265B1951aad02dde28440c9F14A84E6CEE73cd11'
    var offset = 1
    //per page
    console.log("pgContractAddress:",pgContractAddress)
    var limit = 33 // number of records

    if ( pgContractAddress !== arbPaginate ){
      limit = 80
      // get limit
    }
    //if the PFMM is blank the contracts will error out on liquidity setting. we need to check for the zero address when the is site live

    let [gq1, gq2] = [null,null]
    var timestamp = 0
    var resolution = 10
    var greaterThan = 1

    switch (filter) {
      case 'noFilter':
        [gq1, gq2] = await GetQsByPage(offset, limit, pgContractAddress, provider)
        break

      case 'source':
        [gq1, gq2] = await GetQsBySource(tradingState, offset, limit, pgContractAddress, provider)
        break

      case 'category':
        [gq1, gq2] = await GetQsByCategory(tradingState, offset, limit, pgContractAddress, provider)
        break

      case 'trade':
        [gq1, gq2] = await GetQsByTradingState(tradingState, offset, limit, pgContractAddress, provider)
        break

      case 'resolution':
        [gq1, gq2] = await GetQsByResolutionDate(resolution, greaterThan, offset, limit, pgContractAddress, provider)
        break

      case 'timestamp':
        [gq1, gq2] = await GetQsByTimestamp(timestamp, greaterThan, offset, limit, pgContractAddress, provider)
        break
      default: return null
    }

    const completeQuestions = gq1?.map((question, index) => ({
     ...question,
     ...gq2[index]
    }));
    console.log("check completeQuestions",completeQuestions)
    return completeQuestions
  }

  export {GetQsByfilter};
