import React, {useState, useEffect, useRef} from 'react'
import { useSearchParams, useNavigate  } from 'react-router-dom';
import blueBlack from '../../assets/images/background/blue-black.jpg'
import { FPBalanceOf } from '../../modules/prediction/FPMarketMaker/views/balanceOf';
import { FPCollateralInfo } from '../../modules/prediction/FPMarketMaker/views/collateralInfo';
import { FPConditionIds } from '../../modules/prediction/FPMarketMaker/views/conditionIds';
import { FPCalcBuyAmount } from '../../modules/prediction/FPMarketMaker/views/calcBuyAmount';
import { FPCalcSellAmount } from '../../modules/prediction/FPMarketMaker/views/calcSellAmount';
import { RemoveFunding } from '../../modules/prediction/FPMarketMaker/actions/removeFunding';
import { WithdrawFees } from '../../modules/prediction/FPMarketMaker/actions/withdrawFees';

import { CollectionId } from '../../modules/prediction/Conditional/views/collectionId';
import { ConditionId } from '../../modules/prediction/Conditional/views/conditionId';
import { PositionId } from '../../modules/prediction/Conditional/views/positionId';
import { SlotCount } from '../../modules/prediction/Conditional/views/slotCount';
import { BalanceOf } from '../../modules/prediction/Conditional/views/balanceOf';
import { BalanceOfBatch } from '../../modules/prediction/Conditional/views/balanceOfBatch';
import { IsApproved } from '../../modules/prediction/Conditional/views/isApproved';


import { FeesSplit } from '../../modules/prediction/FPMarketMaker/views/feesSplit';
import { GetWFees } from '../../modules/prediction/FPMarketMaker/views/wFees';
import { GetPositionIds } from '../../modules/prediction/FPMarketMaker/views/getPositionIds';
import { TotalSupply } from '../../modules/prediction/FPMarketMaker/views/totalSupply';
import {TokenBalance} from '../../modules/tokens/views/tokenBalance';
import { Allowance } from '../../modules/allowance'


import { CreateFPMM } from '../../modules/prediction/createFPMM';
import tokensAddrs from '../../config/contracts/tokens.json'
import { ReportPayouts } from '../../modules/prediction/Conditional/actions/report';
import { RedeemPosition } from '../../modules/prediction/Conditional/actions/redeem';
import { Prepare } from '../../modules/prediction/Conditional/actions/prepare';
import { AddFunding } from '../../modules/prediction/FPMarketMaker/actions/addFunding';
import { Buy } from '../../modules/prediction/FPMarketMaker/actions/buyShares';
import { Sell } from '../../modules/prediction/FPMarketMaker/actions/sellShares';

import { GetUrlString } from '../../modules/prediction/questionDB/views/getUrl';
import { GetQuestionByQId } from '../../modules/prediction/questionDB/getQuestions/getQByQId';
import { UpdateQuestion } from '../../modules/prediction/questionDB/actions/updateQuestion';

import { CollateralWhitelist } from '../../modules/prediction/Whitelist/views/getCollateral';
import { getPredictionAddresses } from '../../modules/getPredictionAddresses';

import { useError } from '../../components/context/ErrorProvider';
import { useGlobalState } from '../../components/context/GlobalStateProvider';
import predictionAddrs from '../../config/contracts/prediction.json'
import { Controller } from '../../components/prediction/Controller';
import { questionList } from "../../config/markets/marketsConfig";
import { FAKEWEB3CALL } from '../../components/prediction/FAKEWEB3CALL';
import { getQuestionById } from '../../components/prediction/getQuestionById';
import source_list from '../../config/source_list.json'
import { YouTubeEmbed } from '../../components/YoutubeEmbed';

const PredictionPage = () => {
  const { chainData , setChainData, walletInfo, isConnected } = useGlobalState();
  const [ tokenAddy , setTokenAddy ] = useState()
  const [ tvlBalance, setTVLBalance ] = useState()
  const [ conditionIds, setConditionIds ] = useState(['NA'])
  const [ selectedConditionId, setSelectedConditionId] = useState()
  const [ collectionId, setCollectionId ] = useState()
  const [ collateralAddy, setCollateralAddy] = useState()
  const [ calcBuy, setCalcBuy] = useState()
  const [ calcSell, setCalcSell] = useState()
  const [ reportPayout, setReportPayout] = useState()
  const [ positionId, setPositionId ] = useState()
  const [ isApproved, setIsApproved ] = useState()
  const [ outSlotCount, setOutSlotCount ] = useState()
  const [ qSlotCount, setQSlotCount ] = useState()
  const [ funding, setFunding] = useState()
  const [ oracleAddy, setOracleAddy ] = useState()
  const [ condId, setCondId ] = useState()
  const [ dHint, setDHint] = useState()
  const [ fpFees, setFPFees] = useState()
  const [ tokenAmount, setTokenAmount] = useState()
  const [ minShares, setMinShares] = useState()
  const [ indexNum, setIndexNum ] = useState(0)
  const [ collectId, setCollectId ] = useState()
  const showPopUp = useError();
  const [ indexSets, setIndexSet ] = useState()
  const [ newFPMM, setNewFPMM ] = useState()
  const [ qBaseUrl, setQBaseUrl ] = useState()
  const [ batchPositionInfo, setBatchPositionInfo ] = useState()
  const [searchParams] = useSearchParams();
  const [ decimals, setDecimals ] = useState(9)
  const [viewState, setVewState] = useState({predictionState: null, viewType: null})

  const [ selectedIndex, setSelectedIndex] = useState(0)
  const [ priceInfo, setPriceInfo] = useState()
  const [ userCTBalance, setUserCTBalance] = useState()
  const endString=".json"
  const sQuestionId = searchParams.get('id')
  const mQuestionId = sQuestionId+'00000000000000000000000000000000'

  const [qPageData, setQPageData] = useState( { pId:null, catId:null,  sourceId:null, tradingStateId:null, eventAddress:null, resolutionDate:null } )
  const navigate = useNavigate();
  const [ loading, setLoading] = useState(true)
  const [ controllerData, setControllerData] = useState()
  const [ localBetFee, setLocalBetFee] = useState(0)
  const [ userFPMMAllowance,setUserFPMMAllowance] = useState()
  const [ userWTokenBalance ,setUserWTokenBalance] = useState()
  const [ fpmmApproved, setFPMMApproved] = useState()
  const [ feesSplit, setFeesSplit ]= useState()

  const [ collateralInfo, setCollateralInfo] = useState()
  const [ selectedCollateral , setSelectedCollateral] = useState()
  const intervalRef = useRef(5000);
  const timeoutRef = useRef(null);

  const defaultViewData = {input: 0, userMultiPartSelection: "", userBinaryPartSelection: "", distributionInfo: []}
  const [viewData, setViewData] = useState(defaultViewData)
  const [ inValue, setInValue ] = useState()
  const [condIndex, setCondIndex] = useState(0)
  const [questionIds,setQuestionIds] = useState()
  const [outcomesCount, setOutcomesCount] = useState()

  const defaultToken = '0x900a033403556DB01b830DdDE80EbF69403f49dC'
  let nineList = ['0x900a033403556DB01b830DdDE80EbF69403f49dC','0x6A46d56F9Dd963cEAc7f60aD148d49219CC3b79f','0xc674B7Bed1D086584c8776C1B83013009cC294e7']
  useEffect(() => {


    const IntervalSetter = () => {
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        switch (viewState.predictionState) {

          case 'None':
            console.log("case-buy", viewState.predictionState);
            intervalRef.current = 15000000;
            break;

          case'admin':
            getConditionalInfo()
            intervalRef.current = 1500000;
            break;
          case 'buy':
          case 'sell':
            getPositionInfo()
            userInfo()
            getCollateralInfo()
            intervalRef.current = 150000;
            break

          case "redeem":
            getPositionInfo()
            getCollateralInfo()
            intervalRef.current = 150000;
            break

          case "liquidity":
            GetQuestionPageData()
            getFeesInfo()
            intervalRef.current = 150000;
            break

          default:
            console.log("not buy", viewState.predictionState);

            getCollateralInfo() //only needs tobe called once or until set in state
            GetQuestionPageData() //only need to be checked when tab change from admin to another tab.. then basically at the load of the page
            userInfo() //needs to be checked basically after every action
            getFeesInfo() // only needs to be called once or until set in state
            intervalRef.current = 1500000;
            //getPositionInfo() // frequent calls
            break;
        }

        timeoutRef.current = setTimeout(IntervalSetter, intervalRef.current);
      }, 800); //800 is delay before call to prevent spam 0xfE58826F32bC1e954a3373EA298234adE3bd20fC,

    };

    IntervalSetter();
    return () => clearTimeout(timeoutRef.current);
  }, [viewState?.predictionState]);

  function countSlots(count,vType){
    let sCount = 2
    if (vType==="multi"){
      console.log('qSlotCount says its multi')
      sCount = count*2
    }
    return sCount
  }

  useEffect(() => {
    const fetchQuestion = async () => {

      console.log('chainId1:',window?.ethereum?.chainId, 'chainId2:', chainData?.id  )

      if (!mQuestionId) {
        // Redirect if the 'id' parameter is missing
        showPopUp("Error No Question ID Parameter: Redirecting To Markets", "error")
        //navigate('/dapp/markets');
       }
       else {
        // Fetch question by id

        var baseUrl = await GetUrlString( 2,  predictionAddrs.PaginateQuestions[chainData?.id] , walletInfo?.provider )

        if (chainData?.id !== 369){
          baseUrl = "https://arweave.net/myFkl2qOjO-5_NJibqIdeoN5JkTBefBWr9l2Dhy1SwA/"
        }
        console.log('mQuestionId baseUrl',baseUrl)
        const question = await getQuestionById(baseUrl, sQuestionId, endString);
        console.log(question)

        if (!question && viewState?.predictionState !== "liquidity") {
          // Redirect if question doesn't exist
          showPopUp("Error Invalid Question ID Parameter: Redirecting To Markets", "error")
          //navigate('/dapp/markets');
        }

        let thisState = {predictionState: null, viewType: null}
        if (question) {
           question?.outcomes?.length > 2  ? /* ||
           (//logic to justify 2 cases of multi
            question?.outcomes?.length === 2 &&
            !question.outcomes.some(outcome => outcome?.title === 'Yes') &&
            !question.outcomes.some(outcome => outcome?.title === 'No')
          )*/
         (thisState ={predictionState: 'buy', viewType:'multi'}) : (thisState ={predictionState: 'buy', viewType:'single'});

        }
        const info = {...question, data: {}, thisState }


        console.log( '(qslotcount):', countSlots(question?.outcomes?.length, thisState.viewType )  )
        setQSlotCount( countSlots(question?.outcomes?.length, thisState.viewType ) )
        setVewState(thisState)
        setControllerData(info)
        setLoading(false)
        setOutcomesCount(question?.outcomes?.length)

      }
    };

    fetchQuestion();
  }, [mQuestionId, navigate]);


  useEffect(() => { //WALLET INFO HERE!
    if (isConnected) {
      var tokenAddress = tokensAddrs.Friendship[chainData?.id]
      tokenAddress = defaultToken
      setTokenAddy(tokenAddress)
      getConditionalInfo()
      GetQuestionPageData()

    }
  }, [isConnected])

let vInputChange = ( test ) => {
  //console.log('test send:',test[0], test[1])
  let viewInput = test[1]
  switch(viewInput) {
    case "source_id":
      console.log("so:",test[0])
      setQPageData((prevData) => ({
        ...prevData, // Spread the previous state
        sourceId: test[0] // Update the specified key with the new value
      }));
      break;

    case "buyTokensIn":
      console.log("buyTokensIn:",test[0])
      setInValue(test[0]);
      setTokenAmount( test[0]*10**9 )
      getBuyInfo(  test[0]*10**9, selectedIndex )
      break;

    case "sellTokensIn":
      console.log("sellTokensIn:",test[0])
      setInValue(test[0]);
      setTokenAmount( test[0]*10**9 )
      getSellInfo(  test[0]*10**9, selectedIndex )
      break;

    case "liquidityIn":
      console.log("liquidityIn:",test[0])
      setInValue(test[0]);
      setFunding( test[0]*10**9 )
      userInfo()
      //getSellInfo(  test[0]*10**9, selectedIndex )

      break;

    case "selectedHint":
      console.log("selectedHint:",test[0])
      userInfo()
      setDHint(test[0][1])
      break;

    case "selectedOutcomeIndex":
      console.log("selectedOutcomeIndex:",test[0])
      setDHint(test[0])
      break;

    case "conditionSelect":
      console.log("conditionSelect",test[0])
      setCondIndex(test[0])
      break

    case "oSelector":
      let optionIndex = getQOptionIndex( test[0][1],  test[0][0] )
      console.log("optionIndex tester:",optionIndex)
      setSelectedIndex(optionIndex)
      break;

    case "category_id":
      console.log("c:",test[0])
      setQPageData((prevData) => ({
        ...prevData, // Spread the previous state
        categoryId: test[0] // Update the specified key with the new value
      }));
      break;

    case "state_id":
      console.log("st:",test[0] )
        setQPageData((prevData) => ({
          ...prevData, // Spread the previous state
          tradingStateId: test[0] // Update the specified key with the new value
        }));
      break;


    case "vFPMMContract":
      console.log("vfpmm:",test[0])
      setNewFPMM(test[0])
      break;


    case "vPayout":
      console.log("vp:",test[0])
      const parsedArray = test[0].split(",").map((num) => parseFloat(num.trim())).filter((num) => !isNaN(num)); // Filter out invalid numbers
      console.log("vp2:",parsedArray)
      setReportPayout(parsedArray)
      break;

    case "selectCollateral":
      console.log("sel colateral:",test[0])
      setSelectedCollateral(test[0])
      break

    case "selectedOutcomeIndex":
      console.log("sel OutcomeIndex:",test[0])
      //setSelectedCollateral(test[0])
      break


    case "vFees":
    setPriceInfo((prevData) => ({
      ...prevData, // Spread the previous state
      fee: test[0]* (10**16),
      feePercent:test[0] // Update the specified key with the new value
    }));
      setLocalBetFee( test[0] )

      console.log("vf:",test[0])
      break;
  default:
    //console.log("No case match")
  }
}

//INPUT HANDLING
const outcomeSelect = (select, row, activeController, setActiveController, viewData, updateViewData, qId) => {
  const {userBinaryPartSelection, userMultiPartSelection, input} = viewData;
  if(activeController && userBinaryPartSelection === select){ //select = yes/no (0/1),  row=rowIndex     - userMultiPartSelection=(PreviousRender Row index)
    if((!row && row !==0) || userMultiPartSelection === row){
      setActiveController(null) //Condition if someone unselects the option (clicks on already accepted)

    }
  } else {
    setActiveController(qId); //activates which controller is currently vbeing viewed (can see input)
  }
  if (row || row === 0) {
    updateViewData("userMultiPartSelection", row); //if userMultiPartSelection is defined set it to option index
  }
  updateViewData("userBinaryPartSelection", select); //set yes or no
  let optionIndex = getQOptionIndex( userMultiPartSelection,  userBinaryPartSelection )


  //setCalcBuy(input*row)
};


const handleSubmit = ( viewData, qId, qTitle, view, ) => {

  switch(view) {
    case "buy":
    case "sell":

    // take out for view data and put into local variables#
    //userSelection -> userBinaryPartSelect, multQuestionoption is userMultiPartSecltion
      const {userBinaryPartSelection, userMultiPartSelection, input } = viewData //Move into switchstatement
      let optionIndex = getQOptionIndex( userMultiPartSelection,  userBinaryPartSelection )
      let decimals = 9
      let tAmount = Number(input *10**decimals).toString()
      console.log('viewData buy', userBinaryPartSelection, userMultiPartSelection, input)
      if (userBinaryPartSelection !== "") {
            if (view=="buy" ){

                BuyShares( optionIndex, tAmount )
                setCalcBuy(tAmount)
            }
            else{
              tAmount = input.toString()
              console.log("button view:",view, tAmount)
              SellShares( optionIndex, tAmount )

            }
      }

      if (userBinaryPartSelection === "") {
        showPopUp("You must select an option to enter market", "error");
        return;
      }
      if (viewData && !viewData?.input > 0) {
        showPopUp("You must select an amount to enter market", "error");
        return;
      }
      break;

    case "addLiquidity":
      console.log("add Liquidty funding", viewData.input)
      let funds = Number(viewData.input) * Number(10**9)

      if (collateralAddy !=='0x6A46d56F9Dd963cEAc7f60aD148d49219CC3b79f' &&
      collateralAddy !=='0x900a033403556DB01b830DdDE80EbF69403f49dC'){
          funds = Number(viewData.input) *Number(10**9)
      }

      //setFunding( funds )
      setDHint(viewData.distributionInfo)
      console.log('funding:', funding, funds)
      AddFunds( funds, dHint)
      break;

    case "claimLiquidity":
      console.log("Remove / Claim Liquidity", viewData, qId)
      FeesClaimer()
      break;

    case "createFPMM":
      console.log("create fpmm", viewData, qId)
      FPMMCreator()
      break;

    case "prepareCondition":
      console.log("Prepare Condition", viewData, qId)
      Preparer()
      break;

    case "getCondition":
      console.log("Get Condition", viewData, qId)
      Conditioner()
      break;

    case "report":
      console.log("Report Results", viewData, qId)
      Reporter()
      break;

    case "updateDetails":
      console.log("Update Details", viewData, qId)
      QstnUpdater()
      break;

    case "redeem":
      console.log("Redeem", viewData, qId)
      Conditioner()
      Redeemer()
      break;

    case "removeLiquidity":
      console.log("debugExecuted Remove Liquidity", viewData?.input, qId)
      Remover( viewData?.input )
      break;
    default:
      // code block
  }
};


  function resultsHandle( myResult ){
    if(myResult[0]){
      showPopUp("Transaction Success: "+ myResult[1], "success")
    } else {
      showPopUp("Error: "+ myResult[1], "error")
    }

  }

  async function getFeesInfo(){
    console.log('qPageData',qPageData)
    var feeSplit1 = await FeesSplit( qPageData.eventAddress,1, walletInfo.provider)
    console.log('feesplit',feeSplit1.dev, feeSplit1.donate, feeSplit1.user, feeSplit1.community, feeSplit1.outreach) //.dev, feesplit.donate, feesplit.user, feesplit.community, feesplit.outreach)

    var feeSplit2 = await FeesSplit( qPageData.eventAddress,2, walletInfo.provider)
    console.log('feesplit2',feeSplit2.dev, feeSplit2.donate, feeSplit2.user, feeSplit2.community, feeSplit2.outreach )
    var fees = await GetWFees( qPageData.eventAddress, walletInfo.provider)
    let fpFee = fees *100/(10**18)
    console.log('feesplit fpFee',fpFee)
    setFPFees(fpFee)
    setFeesSplit(feeSplit1)

  }

  function tokenNameByAddress(tokenlist, address) {
    const token = tokenlist.find(token => token.value.toLowerCase() === address.toLowerCase());
    return token ? token.name : null; // Return the name if found, otherwise null
  }

  async function getCollateralInfo(){
    let collateralToken = await FPCollateralInfo( qPageData.eventAddress,  walletInfo.provider)
    let collateralWhitelist = await CollateralWhitelist( predictionAddrs.WhiteList[chainData?.id] , walletInfo.provider);
    let tDecimals = 9
    if ( !nineList.includes(collateralToken.token) ){
      tDecimals = 18
    }
    const tokenlist = getPredictionAddresses(chainData?.id)
    console.log('collateral tokenlist:',collateralToken?.token,tokenlist)
    let tokenName = 'NA'
    try{
      tokenName = tokenNameByAddress(tokenlist, collateralToken.token)
    }catch{

    }

    console.log( "collateral info",tokenName, collateralToken, collateralWhitelist, tokenName,decimals)
    setCollateralInfo({
      whiteList:collateralWhitelist,
      token: collateralToken?.token,
      name:tokenName,
      isSet:collateralToken?.isSet,
      decimals: tDecimals
    })

    console.log('collateral info name:',collateralInfo?.name)

    setDecimals(tDecimals)

  }

  async function getSellInfo( ltAmount ) {
     var cSell = await FPCalcSellAmount( qPageData.eventAddress, ltAmount, selectedIndex,  walletInfo.provider)
     setCalcSell( Number(cSell) )
     console.log('cSell:', cSell,  ltAmount, selectedIndex )
  }

  async function getBuyInfo( lTokAmount, here ) {
    var cBuy = await FPCalcBuyAmount( qPageData.eventAddress, lTokAmount, selectedIndex, walletInfo.provider)
    setCalcBuy( Number(cBuy) )
    console.log('cBuy:', cBuy , lTokAmount, selectedIndex, batchPositionInfo.outcomeIndex[selectedIndex] )
    return cBuy
  }

  async function userInfo(){
    let token = collateralInfo?.token
    var userAllowance = await Allowance( collateralInfo?.token, qPageData.eventAddress, walletInfo.provider, walletInfo.signer.address )
    var userApproved = await IsApproved(predictionAddrs.ConditionalToken[chainData?.id], walletInfo.signer.address, qPageData.eventAddress, walletInfo.provider)
    var userTokenBalance = await TokenBalance( collateralInfo?.token, walletInfo.signer.address,  walletInfo.provider)
    console.log('userAllowance: fpmm ap tkn',userAllowance, userApproved, userTokenBalance, token)
    console.log('userFPMMAllowance vs input:',userFPMMAllowance, tokenAmount )
    setUserFPMMAllowance (userAllowance)
    setFPMMApproved( userApproved )
    setUserWTokenBalance( userTokenBalance )
  }

  function createPowerOfTwoArray(x, value) {
      // Calculate the length of the array, which is 2^x
      let length = Math.pow(2, x);
      // Initialize the array with zeros for the "yes" condition
      let array = new Array(length).fill(0);
      // List to store the indices set to the value (yes condition)
      let yesIndices = [];
      // Loop through and find the powers of 2 up to the length of the array
      let powerOfTwo = 1;
      while (powerOfTwo < length) {
          yesIndices.push(powerOfTwo); // Add the power of 2 index to the list
          powerOfTwo *= 2; // Move to the next power of 2
      }
      // Fill the array at the chosen indices with the specified value (yes condition)
      for (let i = 0; i < yesIndices.length; i++) {
          array[yesIndices[i]] = value; // Set the value at the specified index
      }
      // Generate binary representations for the "yes" condition (indices set to value)
      let yesBinary = yesIndices.map(index => index.toString(2).padStart(x, '0'));
      // Now, generate the "no condition" indices where exactly one bit is 0 and others are 1
      let noIndices = [];
      let noBinary = [];
      // Loop through and generate the binary numbers where only one bit is 0
      for (let i = 0; i < x; i++) {
          let binaryStr = '1'.repeat(x); // Start with all ones
          binaryStr = binaryStr.slice(0, i) + '0' + binaryStr.slice(i + 1); // Replace the i-th bit with 0
          noIndices.push(parseInt(binaryStr, 2)); // Convert binary string to integer for "no" index
          noBinary.push(binaryStr); // Store the binary representation
      }
      // Create a new array for the "no condition" with the value set at the "no" indices
      let noArray = new Array(length).fill(0); // Initialize with zeros
      for (let i = 0; i < noIndices.length; i++) {
          noArray[noIndices[i]] = value; // Set value at the "no" condition indices
      }
      // Create the final array that includes both the "yes" and "no" conditions
      let finalArray = new Array(length).fill(0);
      // Apply the "yes" condition (set values at powers of 2 indices)
      for (let i = 0; i < yesIndices.length; i++) {
          finalArray[yesIndices[i]] = value;
      }
      // Apply the "no" condition (set values where exactly one 0 bit exists)
      for (let i = 0; i < noIndices.length; i++) {
          finalArray[noIndices[i]] = value;
      }

      // Print all the required information
      console.log("Yes condition (Indices set to value):", yesIndices);
      console.log("Yes condition (Binary representations):", yesBinary);
      console.log("No condition (Indices with one 0):", noIndices);
      console.log("No condition (Binary representations with one 0):", noBinary);
      console.log("Resulting Array (Yes condition applied):", array);
      console.log("Resulting Array (No condition applied):", noArray);
      console.log("Final Array (Yes and No conditions applied):", finalArray);

      return yesIndices;
  }

  function processPositions(positionIds, indexes, balances) {
  // Step 1: Calculate powerLength and complength
  const x = indexes.length;
  const powerLength = Math.pow(2, x) - 1;
  const complength = powerLength;

  // Initialize lists to store the results
  let list1 = [];
  let list2 = [];
  let list3 = [];
  let list = [];

  // Step 2: Iterate over the indexes and perform operations
  indexes.forEach(val => {
    // Create the binary dictionary with 'yes' and 'no' as per your specification
    const binaryDict = {
       "yes":val,
       "yPostition":positionIds[val],
       "no":complength - val,
       "nPostition":positionIds[complength - val],
       "balance":balances[val]
    };

    list.push(binaryDict);

    list1.push(val)
    list1.push(complength - val)

    // Add to list2 (binary array)
    list2.push(positionIds[val]);
    list2.push(positionIds[complength - val]);

    list3.push(balances[val]);
    list3.push(balances[complength - val]);


  });

  return [list, list1, list2, list3];
}

  function processPositions2(positionIds, indexes, balances) {
    // Step 1: Calculate powerLength and complength
    const x = indexes.length;
    const powerLength = Math.pow(2, x) - 1;
    const complength = powerLength;
    // Initialize lists to store the results
    let list1 = [];
    let list2 = [];
    // Step 2: Iterate over the indexes and perform operations
    indexes.forEach(val => {
      // Create the binary dictionary with 'yes' and 'no' as per your specification
      const binaryDict = {
         "yes":val,
         "yPostition":positionIds[val],
         "no":complength - val,
         "nPostition":positionIds[complength - val],
         "balance":balances[val]
      };
      // Add to list1 (binary dictionary)
      list1.push(binaryDict);
      // Create the binary array
      const binaryArray = [val, complength - val ];
      // Add to list2 (binary array)
      list2.push(binaryArray);
    });

    return [list1, list2];
  }

  function processPercent(inputArray) {
      // Extract numbers at even indices (0, 2, 4, 6, ...)
      const evenIndexValues = inputArray.filter((_, index) => index % 2 === 0);
      // Calculate the sum of the even-index numbers
      const evenIndexSum = evenIndexValues.reduce((sum, num) => sum + num, 0);
      // Create the percentage array based on the even-index values
      const percentageArray = evenIndexValues.map(num => {
          return evenIndexSum === 0 ? 0 : ((num / evenIndexSum) * 100).toFixed(1);
      });

      return {
          evenIndexSum,
          percentageArray
      };
  }

  async function getPositionInfo(){
    var position1  = await GetPositionIds( qPageData.eventAddress, walletInfo.provider)
    let positions = position1.concat(position1)
    let positionStringsExtra = Object.values(positions).map(value => value.toString());
    let positionStrings = Object.values(position1).map(value => value.toString());

    var marketTVL = await TokenBalance( collateralInfo?.token, qPageData.eventAddress,  walletInfo.provider)
    var totalSupply = await TotalSupply( qPageData.eventAddress,  walletInfo.provider)
    let feeRate = 0.05

    console.log('real balance:', marketTVL , collateralInfo?.token ,'positionIds',positionStrings)
    console.log('real totalSupply',totalSupply)
    setTVLBalance( marketTVL )

    let repeatUserWallets = Array(positionStrings.length).fill(walletInfo.signer.address)
    let repeatFPMMWallets = Array(positionStrings.length).fill(qPageData.eventAddress)
    let repeatWallets = repeatUserWallets.concat(repeatFPMMWallets)
    let outcomers = Array(positionStrings.length).fill('id or something');
    let bal = Array(positionStrings.length).fill(100);

    //postitList
    var cBalanceOfBatch = await BalanceOfBatch( predictionAddrs.ConditionalToken[chainData?.id], repeatWallets,  positionStringsExtra, walletInfo.provider)
    console.log('cBalanceOfBatch:',cBalanceOfBatch)
    var userCBalanceOfBatch = cBalanceOfBatch.slice(0,cBalanceOfBatch.length/2)
    var fpmmCBalanceOfBatch = cBalanceOfBatch.slice(cBalanceOfBatch.length/2)
    console.log('real userCBalanceOfBatch:',userCBalanceOfBatch)
    console.log('real fpmmCBalanceOfBatch:',fpmmCBalanceOfBatch)

    console.log('(outcomesCommes)',controllerData?.outcomes?.map((outcome) => outcome.title),outcomesCount)

    let sum = 0;
    try{
      for (let i = 0; i < cBalanceOfBatch.length; i++) {
        sum += Number(cBalanceOfBatch[i]);
      }
    }catch{

    }
    let  value = 10
    let yesIndices = createPowerOfTwoArray(outcomesCount, value)

    const [ulist0, ulist1, ulist2, ulist3] = processPositions(positionStrings.slice(0), yesIndices,  userCBalanceOfBatch )
    const [list0, list1, list2, list3] = processPositions(positionStrings.slice(0), yesIndices, fpmmCBalanceOfBatch)

    console.log("List 2 (binary dict):", list0);
    console.log("List 1 (Flat indixces):", list1);
    console.log("List 2 (Flat Positions):", list2);
    console.log("List 3 (Flat balance):", list3);

    let betPercents = processPercent(list3)
    console.log("real Even Index Sum:", betPercents?.evenIndexSum);
    console.log("real Percentage Array:", betPercents?.percentageArray);

    setBatchPositionInfo( {
      positionIds: list2,
      userCondToknBalance:ulist3,
      fpmmCondToknBalance:list3,
      outcomes:outcomers.slice(0,outcomesCount*2),
      outcomeIndex:list1,
      colToknBalance:bal.slice(0,outcomesCount*2),
      betPercents:betPercents?.percentageArray}
    )

    console.log('BatchPositionInfo:',batchPositionInfo)
    console.log('Batch sum: ',sum, 'balance:',userCTBalance)

    //console.log('estimated token buys:',amountWithoutFee, 'estimated token sells:', collateralWithFee, 'from:',1000, 'realbuy:',cBuy, 'realsell',cSell)
  }

  async function getConditionalInfo(){
    //conditionalAddress, ownerAddress, id,
    var id = 0
    var ownerAddress = '0x995fa5eadCDf96268C7a99357d87fe89dCF7EDd2' //walletInfo.signer.address
    var oracleAddress = predictionAddrs.Oracle[chainData?.id]
    //var questId = '0x4b22fe478b95fdaa835ddddf631ab29f12900b62061e0c5fd8564ddb7b684333'
    var parentCollectionId = '0x0000000000000000000000000000000000000000000000000000000000000000'
    var indexSet = 0
    var outcomeSlotCount = 2
    var operatorAddress = qPageData.eventAddress
    var amount = 1000
    var index = 0

    //setIndexNum(index)
    let balance = 0
    console.log('FPmm',qPageData.eventAddress)
    var userCTBal = await FPBalanceOf( qPageData.eventAddress, walletInfo.signer.address,  walletInfo.provider)
    //var condition = await FPConditionIds( qPageData.eventAddress, conditionIds, walletInfo.provider)


    var cSell = await FPCalcSellAmount( qPageData.eventAddress, tokenAmount, indexNum,  walletInfo.provider)
    var cBuy = await FPCalcBuyAmount( qPageData.eventAddress, tokenAmount, indexNum, walletInfo.provider)
    var fee = await GetWFees( qPageData.eventAddress, walletInfo.provider)
    let fpFee = fee *100/(10**18)

    //var collection = await CollectionId( predictionAddrs.ConditionalToken[chainData?.id], parentCollectionId, condition, indexSet, walletInfo.provider)
    //var position = await PositionId( predictionAddrs.ConditionalToken[chainData?.id], '0xc674B7Bed1D086584c8776C1B83013009cC294e7', collection, walletInfo.provider)
    var slots = await SlotCount( predictionAddrs.ConditionalToken[chainData?.id],  selectedConditionId, walletInfo.provider)
    console.log('conditionid balance:',selectedConditionId, balance )
    //var approved = await IsApproved( predictionAddrs.ConditionalToken[chainData?.id],  ownerAddress, operatorAddress, walletInfo.provider)

    setUserCTBalance( userCTBal )
    //setCollectionId( collection )

    let fpPriceinfo = { cBuy:cSell, cSell:cBuy, feePercent:fpFees, fee:fee }
    setPriceInfo( fpPriceinfo )
    setOutSlotCount( slots )
    console.log('out Slot Count',outSlotCount,'q slot Count',qSlotCount)
    //console.log('condition',condition)

  }


    async function GetQuestionPageData() {

      console.log('get question by string:', predictionAddrs.PaginateQuestions[chainData?.id], sQuestionId, )
      var qpd = await GetQuestionByQId( predictionAddrs.PaginateQuestions[chainData?.id], sQuestionId, walletInfo.provider )
      let data =  { pId:Number(qpd?.[0]), categoryId:Number(qpd?.[6]),  sourceId:Number(qpd?.[5]), tradingStateId:Number(qpd?.[4]), eventAddress:qpd?.[7], resolutionDate:Number(qpd?.[2]) }
      setQPageData ( data )

      console.log('qPageData string start:', qPageData )

    }


  const handlePayoutChange = (e) => {
      const value = e.target.value;
      // Split the string by commas and trim whitespace from each item
      const arrayFromInput = value.split(',').map(item => item.trim());
      setReportPayout(arrayFromInput);
    };


    //case for tabs: tradingStateId = 1 for no liquidity - remove buy / sell (or add no liquidity errorErrorMessage)
      //case for tabs: tradingStateId = 0 for no liquidity - has not been registered, remove tabs for everyone except admin, Maybe voting tab infuture
        //case for tabs: tradingStateId = 1 for no liquidity


//admin only function

  async function Reporter(){
    console.log('reporter:',reportPayout)
    var result = await ReportPayouts( predictionAddrs.ConditionalToken[chainData?.id], mQuestionId, reportPayout, walletInfo.provider,  walletInfo.signer )
     resultsHandle(result)
  }

  async function Remover( inValue ){
    console.log('remover:')
    let token = '0x900a033403556DB01b830DdDE80EbF69403f49dC'
    let amountsToRemove = inValue
    console.log("inValue liquidity",inValue)
    var result = await RemoveFunding( qPageData.eventAddress, amountsToRemove, walletInfo.provider,  walletInfo.signer )
     resultsHandle(result)
  }

  async function FeesClaimer(){
    console.log('fees claimer:')
    var result = await WithdrawFees( qPageData.eventAddress, walletInfo.signer.address , walletInfo.provider,  walletInfo.signer )
     resultsHandle(result)

  }

  async function Redeemer(){
    var parentCollectionId = '0x0000000000000000000000000000000000000000000000000000000000000000'
    var indexSets = [4]

    console.log('Redeemer', predictionAddrs.ConditionalToken[chainData?.id], collateralInfo?.token, parentCollectionId, selectedConditionId, indexSets)
    var result = await RedeemPosition( predictionAddrs.ConditionalToken[chainData?.id], collateralInfo?.token, parentCollectionId, selectedConditionId, indexSets, walletInfo.provider, walletInfo.signer )
    resultsHandle(result)
  }

  async function Preparer( ){
    console.log('Preparer:', predictionAddrs.ConditionalToken[chainData?.id], predictionAddrs.Oracle[chainData?.id],
      questionIds[condIndex], qSlotCount, 2)
    var result = await Prepare( predictionAddrs.ConditionalToken[chainData?.id], predictionAddrs.Oracle[chainData?.id],
       questionIds[condIndex], 2, walletInfo.provider, walletInfo.signer )
    resultsHandle(result)
  }


  async function Conditioner(){
    console.log('Conditioner', predictionAddrs.ConditionalToken[chainData?.id], predictionAddrs.Oracle[chainData?.id], mQuestionId, qSlotCount)

    const qIdArray = []
    const conditionArray = []
    let n = qSlotCount/2
    console.log('qSlotCount,',qSlotCount)

    if (qSlotCount > 2) {
      for (let i = 1; i <= n; i++) {
        const suffix = i.toString(16).padStart(2, '0') + '0'.repeat(30); // Creates '001000...' etc.
        let cQuestionId = sQuestionId+suffix
        qIdArray.push(cQuestionId);
        //console.log('cQuestionId',cQuestionId)
        var condition = await ConditionId( predictionAddrs.ConditionalToken[chainData?.id], predictionAddrs.Oracle[chainData?.id], cQuestionId, 2, walletInfo.provider)
        conditionArray.push(condition)
      }
    } else {
      var condition = await ConditionId( predictionAddrs.ConditionalToken[chainData?.id], predictionAddrs.Oracle[chainData?.id], mQuestionId, 2, walletInfo.provider)
      conditionArray.push(condition)
      qIdArray.push(mQuestionId);
    }

    console.log('Conditioner cond:',conditionArray)
    console.log('Conditioner q:',qIdArray)
    setConditionIds( conditionArray )
    setQuestionIds( qIdArray)
  }


  async function QstnUpdater(){
    console.log('QstnUpdater:', qPageData.pId, qPageData.resolutionDate, qPageData.tradingStateId, qPageData.sourceId,
    qPageData.categoryId, newFPMM )
    var result = await UpdateQuestion(
      predictionAddrs.PaginateQuestions[chainData?.id],
      qPageData.pId, qPageData.resolutionDate, qPageData.tradingStateId, qPageData.sourceId,
      qPageData.categoryId, newFPMM ,   walletInfo.provider, walletInfo.signer)
      console.log('updated',result)
  }

  async function FPMMCreator(){
    let token = '0x900a033403556DB01b830DdDE80EbF69403f49dC'
    console.log('CreateFPMM:', predictionAddrs.FPMarketMakerFactory[chainData?.id], predictionAddrs.ConditionalToken[chainData?.id],
    token, conditionIds , priceInfo.fee, Number(priceInfo.fee))
    var result = await CreateFPMM(  predictionAddrs.FPMarketMakerFactory[chainData?.id], predictionAddrs.ConditionalToken[chainData?.id],
      token, conditionIds, priceInfo.fee, walletInfo.provider, walletInfo.signer );
      setNewFPMM(result)
    resultsHandle(result)
  }

  const handleHintChange = (e) => {
      const value = e.target.value;
      // Split the string by commas and trim whitespace from each item
      const arrayFromInput = value.split(',').map(item => Number( item.trim() ));
      setDHint(arrayFromInput);
    };

  async function AddFunds( funds, dHint ){

    let fundToken = defaultToken

    console.log('collateralInfo isSet:',collateralInfo.isSet,collateralInfo.token, selectedCollateral )
    if (!collateralInfo?.isSet){
      fundToken = selectedCollateral
        console.log('token selected collateralInfo',fundToken, selectedCollateral)
    }


    console.log('AddFunds: ', qPageData.eventAddress, fundToken,  funds,  dHint, walletInfo.provider)
    var result = await AddFunding(  qPageData.eventAddress, fundToken,  funds,  dHint, walletInfo.provider,  walletInfo.signer,
       userFPMMAllowance)
    resultsHandle(result)

  }

  async function BuyShares( optionIndex, colTokenAmount ){
    console.log('buyshares outcome index',batchPositionInfo.outcomeIndex)
    console.log('BuyShares', optionIndex, colTokenAmount, batchPositionInfo.outcomeIndex[optionIndex])
    //let token = '0x900a033403556DB01b830DdDE80EbF69403f49dC'
    var minBShares = 0// calcBuy
    var result = await Buy( qPageData.eventAddress, collateralInfo.token, colTokenAmount, batchPositionInfo.outcomeIndex[optionIndex],
      minBShares, walletInfo.provider,  walletInfo.signer, userFPMMAllowance)
    resultsHandle(result)
  }

  async function SellShares( optionIndex, maxBShares ){
    console.log('SellShares:',optionIndex, maxBShares)

    var returnTokAmount = 1000000000 //calcSell
    var result = await Sell(  qPageData.eventAddress, predictionAddrs.ConditionalToken[chainData?.id],  returnTokAmount ,
      indexNum, maxBShares, walletInfo.provider,  walletInfo.signer, isApproved)
    //                  Sell( fpmmAddress, conditionalTokenAddress,  returnAmount, outcomeIndex, maxSharesToSell ,provider ,signer )
    resultsHandle(result)

  }

  async function ChangeIndex(){
    if (indexNum===0){
      setIndexNum(1)

    }
    else{
      setIndexNum(0)
    }
    console.log('indexNum',indexNum)
    getConditionalInfo()
  }


  const handleClearSelection = () => {
    setViewData(defaultViewData)
  };


function getQOptionIndex( row , col  ){
  if ( row == null ){
    row = 0
  }
  var qOptnIndex = col*row -1
  qOptnIndex = (row*2) + (col)
  return qOptnIndex

}

//question info // remove duplicate questionID
//source info
//bet info

  return (
    <section className="newsletter section">
      <div className="demoDapp-container">


        {mQuestionId &&
        <Controller  //activeController function not needed as grid but is expected to recieve so ()=>null function
          qId={mQuestionId}
          loading={loading}
          options={controllerData?.outcomes?.map((outcome) => outcome.title)}
          betPercent={controllerData?.betPercent}
          question={controllerData?.title}
          totalBet={controllerData?.totalBet}
          source_name={controllerData?.source_id && source_list.find(item => item.id === Number(controllerData.source_id))?.name }
          source_img={controllerData?.source_id && source_list.find(item => item.id === Number(controllerData.source_id))?.img }
          source_view={controllerData?.source_view}
          source_url={ controllerData?.url}
          resolution={controllerData?.resolution}
          resolution_date_time={controllerData?.resolution_date_time}
          questionList={questionList}
          questionId={mQuestionId}
          outcomeSelect={outcomeSelect}
          handleSubmit={handleSubmit}
          state={viewState}
          setState={setVewState}
          activeController={mQuestionId}
          setActiveController={()=>null}
          collateralToken={collateralAddy}
          conditionIds={conditionIds}
          indexSets={indexSets}
          priceInfo={priceInfo}
          vBatchPositionInfo={batchPositionInfo}
          vBetFee={localBetFee}
          vInputChange = {vInputChange}
          addresses={
            { oracle:predictionAddrs.Oracle[chainData?.id],
              fpmm:qPageData.eventAddress,
              conditional:predictionAddrs.ConditionalToken[chainData?.id],
              collateral:defaultToken}
          }
          vQPagedata={qPageData}
          vSlotCount={qSlotCount}
          vCalcBuyShares={calcBuy}
          vFPMM={newFPMM}
          viewData={viewData}
          setViewData={setViewData}
          handleClearSelection={handleClearSelection}
          vInValue={inValue}
          vCalcSellShares={calcSell}
          tvlBalance={tvlBalance}
          userFPMMAllowance={userFPMMAllowance}
          fpmmApproved={fpmmApproved}
          userWTokenBalance={userWTokenBalance*10**2}
          userCTBalance={userCTBalance}
          fpFees={fpFees}
          feesSplit={feesSplit}
          chainId={chainData?.id}
          vSelectedLP={selectedCollateral}
          collateralInfo={collateralInfo}
          vReportPayout={reportPayout}
          vCondIndex={condIndex}
          outcomesCount={outcomesCount}
          ><YouTubeEmbed url={controllerData?.url} /></Controller>

          }
        <img src={blueBlack} />
      </div>
    </section>
  );
};

export { PredictionPage };
