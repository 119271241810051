import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import NftABI from "../../config/abi/NftABI.json";

async function NftERC20Fees( nftAddress, provider, signer) {

  let feesInfo = []

  try {
    let nftContract = new ethers.Contract( nftAddress, NftABI.abi,  provider).connect(signer)
    var erc20Fee = await nftContract.erc20Fee( )
    var saleActive = await nftContract.ercSaleIsActive( )
    var levelFee = await nftContract.LevelUpFee( )
    //console.log('erc20Fee',erc20Fee, saleActive)

    feesInfo.push(
      erc20Fee, levelFee, saleActive
    )

  }
  catch (e) {
    //console.log('error:',e)

  }
  console.log('feesINFO:',feesInfo)
  return feesInfo
}
export { NftERC20Fees };
