import { ethers } from "ethers";
import LotteryABI from "../../../config/abi/NFTLotteryABI.json"; // Adjust the path to the correct ABI
import { getErrorMessage } from '../../errorHandler';

// Function to trigger the drawLottery transaction
async function DrawLottery(lotteryAddress, provider, signer) {

   let draw = [true,'']
    try {
        // Initialize the Lottery Contract with the provided address, ABI, provider, and signer
        let LotteryContract = new ethers.Contract(lotteryAddress, LotteryABI.abi, provider).connect(signer);
        let tx = await LotteryContract.drawLottery();

        // Wait for the transaction to be mined
        let receipt = await tx.wait();
        draw = [true, receipt.blockHash]
        // Log the receipt for debugging purposes
        console.log("drawLottery transaction receipt:", receipt);


    } catch (e) {
        // Log any errors that occur during the transaction
        console.error("Error executing drawLottery:", e);

        var errorMsg = getErrorMessage(e)
        draw = [false, errorMsg]
    }
    return draw;
}

export { DrawLottery };
