import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import ClaimsABI from "../../config/abi/vestingTwo.json"



async function CClaim( claimsAddress, tokenAddress, provider, signer ) {

  var claim ='' ;
  //console.log('claims:', claimsAddress, tokenAddress, provider, signer)
  try {
    let vestingContract = new ethers.Contract( claimsAddress,  ClaimsABI.abi, provider).connect(signer)
    await vestingContract.claim(tokenAddress)
  }
  catch (e) {
    //console.log('error claim:',e)
    claim = 'Wait for next claim time!'
  }
  return claim
}
export {CClaim};
