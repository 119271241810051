import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import NftABI from "../../config/abi/NftABI.json";

async function NftContractInfo( nftAddress, provider, signer) {

  let contractInfo = []

  try {
    //console.log('nftContract',nftAddress)
    let nftContract = new ethers.Contract( nftAddress, NftABI.abi,  provider).connect(signer)
    //console.log('nftContract',nftContract)
    var maxSupply = await nftContract.maxSupply()

    var totalSupply = await nftContract.totalSupply()
    var numAvailable = maxSupply - totalSupply
    //var rareness = await nftContract.rareness()
    //var name = await nftContract.name()
    //var symbol = await nftContract.symbol()
    var rareness = ''
    var name = 'Sommi Wif Hat'
    var symbol = 'SOMMI'


    contractInfo.push(
      maxSupply, totalSupply, numAvailable, rareness, name,  symbol
    )

  }
  catch (e) {
    //console.log('error:',e)

  }
  console.log('nft contract INFO:',contractInfo)
  return contractInfo
}
export {NftContractInfo};
