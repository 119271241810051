import React, { createContext, useContext, useState, useEffect } from 'react';
import { TokenInfo } from '../../modules/tokenInfo';
import contractAddrs from '../../config/contracts/contracts.json'
import tokensAddrs from '../../config/contracts/tokens.json'
import RPCs from '../../config/RPCs.json'

const GlobalStateContext = createContext();

export const useGlobalState = () => useContext(GlobalStateContext);

export const GlobalStateProvider = ({ children }) => {
  const [storedTokenInfo, setStoredTokenInfo] = useState();
  const [chainData, setChainData] = useState();
  const [walletInfo, setWalletInfo] = useState({ signer: null, provider: null });
  const [loading, setLoading] = useState(false);
  const [mounting, setMounting] = useState(true);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => { //WALLET INFO HERE!
    if (isConnected) {
      const fetchData = async () => {
        try {
          //console.log("Calling, tokeInfo, walletProvider=",walletInfo.provider)
          var tokenInfo = await TokenInfo('0x6A46d56F9Dd963cEAc7f60aD148d49219CC3b79f', walletInfo.provider);
          //console.log("response, tokeInfo=",tokenInfo)
          setStoredTokenInfo(tokenInfo[0]);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
      fetchData();
    }
  }, [isConnected])

  useEffect(() => {
    const delay = 1000; // 1000 milliseconds = 1 second

    const timeoutId = setTimeout(() => {
      // Your code to be executed after 1 second
      setMounting(false)
      // You can also update state here if needed
      // setWalletInfo({ signer: updatedSigner, provider: updatedProvider });
    }, delay);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const updateChainData = async () => {
      try {
        let chainId = null;

        if (window?.ethereum?.chainId) {
          chainId = window.ethereum.chainId;
        } else {
          // If chainId is not available in window.ethereum, you can set a default chain ID here
          chainId = "0x" + (369).toString(16);
          console.log('chainId it not available thats why');
        }

        // Convert chainId from hexadecimal to decimal
        const chainIdDecimal = parseInt(chainId, 16);

        // Update the addresses and RPC based on the detected chain ID
        const vestingAddress = contractAddrs.vesting[chainIdDecimal];
        const tokenAddress = tokensAddrs.Friendship[chainIdDecimal];
        const rpc = RPCs[chainIdDecimal]?.rpc;

        if (vestingAddress && tokenAddress && rpc) {
          setChainData({
            vestingAddress: vestingAddress,
            rpc: rpc,
            tokenAddress: tokenAddress,
            chainId: chainId,
            id:parseInt(chainId) //doesnt work as this only executes on init
          });
        } else {
          setChainData({...chainData, chainId: chainId, id:parseInt(chainId) }) //id doesnt work as this only executes on init
        }
      } catch (e) {
        //console.log("VestingUseffectError", e);
      }
    };
    updateChainData();
  }, [mounting]);

 /* useEffect(()=>{ //state -> wallet chain discrepency fix
      console.log("debug:",chainData?.chainId, window?.ethereum?.chainId)
      if(chainData?.chainId !== window?.ethereum?.chainId){
        setChainData({...chainData, chainId: window?.ethereum?.chainId})
      }
  },[chainData?.chainId, window?.ethereum?.chainId])
*/
  const globalState = {
    storedTokenInfo,
    setStoredTokenInfo,
    chainData,
    setChainData,
    walletInfo,
    setWalletInfo,
    loading,
    setLoading,
    mounting,
    setMounting,
    isConnected,
    setIsConnected,
  };

  return (
    <GlobalStateContext.Provider value={globalState}>
      {children}
    </GlobalStateContext.Provider>
  );
};
