import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json";


async function FPBalanceOf( fpmmAddress,ownerAddress,  provider) {

  var balance = '0'


  try {

    console.log('FPMMAddress', ownerAddress )
    let fpmmContract = new ethers.Contract( fpmmAddress, FPMarketMakerABI.abi,  provider)
    balance = await fpmmContract.balanceOf( ownerAddress)

  } catch (e) {
    balance = '1000'
      //console.log('balance allowance error:',e)
  }

  balance = parseInt( Number(balance)   )
  //balance = balance / (10**decimals)
  console.log('FPMM balance:',balance)
  return balance

  }

export {FPBalanceOf};
