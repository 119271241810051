import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import VestingABI from "../../config/abi/vestingOne.json"



async function VClaim( vestingAddress, tokenAddress, provider, signer ) {

  var claim ='' ;
  //console.log('claims:', vestingAddress, tokenAddress, provider, signer)
  try {
    let vestingContract = new ethers.Contract( vestingAddress,  VestingABI.abi, provider).connect(signer)
    await vestingContract.claim(tokenAddress)
  }
  catch (e) {
    //console.log('error claim:',e)
    claim = 'Wait for next claim time!'
  }
  return claim
}
export {VClaim};
