import React from "react";
import sourceList from "../../../config/source_list.json"
import categoriesList from "../../../config/cat_list.json"

const Admin = ({ handleSubmit, demoVar, conditionIds, collateralToken, addresses , qId, setFPFees,question, qTitle, view, vBetFee, vInputChange,
    vQPagedata, vSlotCount, vFPMM, vReportPayout }) => {
  console.log(vQPagedata); // extracted from props

  return (
    <div className="prediction-container">
      <div className="prediction-header">
        <h3>Admin</h3>
      </div>
      <div className="smaller prediction-add-section">
        <h4 className="prediction-info-item-flex">Prepare Condition</h4>
        ConditionID:<br/>
        <select name="conditionSelect" className="betcard-select" defaultValue={vQPagedata.sourceId}
          onChange={(e) => {vInputChange( [e.target.value, e.target.name] ); }}
        >
          {conditionIds.map((_, index) => (
              <option key={index} value={index}>
                {conditionIds[index]}
              </option>
          ))}
        </select>
        <br/><br/>
        QuestionId:<br/>{qId}<br/><br/>
        OutcomeSlotCount: set In 2s  (will become {vSlotCount} in total)<br/><br/>
        Oracle:<br/>{addresses.oracle}<br/><br/>
        ConditionalToken:<br/>{addresses.conditional}<br/>

        <div className="prediction-submit-section">
        <button  onClick={() => handleSubmit(  qId, question, '', "getCondition" ) } className="button bet_btn_sub bet_btn">Get Condition</button>
          <button  onClick={() => handleSubmit(  qId, question, '', "prepareCondition" ) } className="button bet_btn_sub bet_btn">Prepare Condition</button>
        </div>
      </div>

      <div className="smaller prediction-add-section">
        <h4 className="prediction-info-item-flex">Create Market Maker</h4>
        Conditional Token: <br/>{addresses.conditional}<br/><br/>
        Collateral Default (overidden on add liquidity): {addresses.collateral}<br/><br/>
        Condition ids: [{conditionIds.join(', ')}]
        <br/><br/>
        Fee(%): {vBetFee}
        <input name="vFees" onBlur={(e) => vInputChange( [e.target.value, e.target.name] ) } ></input>

        <div className="prediction-submit-section">
          <button onClick={() => handleSubmit(  qId, question, '', "createFPMM" ) } className="button bet_btn_sub bet_btn">Create FPMM</button>
        </div>
      </div>
      <div className="smaller prediction-add-section">
        <h4 className="prediction-info-item-flex">Update Records</h4>
        <p>Question ID: web3 - pagination</p>

        <label htmlFor="source_id" className="betcard-label">
          Initial Source ID (from contract)
        </label>
        <select name="source_id" className="betcard-select" defaultValue={vQPagedata.sourceId}
          onChange={(e) => {vInputChange( [e.target.value, e.target.name] ); }}
        >
          {sourceList.map((source) => (
              <option key={source.id} value={source.id}>
                {source.name}
              </option>
          ))}
        </select>

        <label htmlFor="category_id" className="betcard-label">
          Initial Category ID (from contract)
        </label>
        <select name="category_id" className="betcard-select" defaultValue={vQPagedata.categoryId}
          onChange={(e) => {vInputChange( [e.target.value, e.target.name] ); }}        >
          {categoriesList.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
          ))}
        </select>

        <label htmlFor="state_id" className="betcard-label">
          Initial State ID (from contract)
        </label>
        <select name="state_id" className="betcard-select" defaultValue={vQPagedata.tradingStateId}
          onChange={(e) => {vInputChange( [e.target.value, e.target.name] ); }}
        >
          <option value="0">Before Live (No FPMM Contract or admin)</option>
          <option value="100">Live - Before Liquidity (set by admin)</option>
          <option value="200">Live before Resolution (set by admin)</option>
          <option value="130">Live Post Resolution Date (set by oracle)</option>
        </select>
        FPMM: {vFPMM}
        <input name="vFPMMContract" onBlur={(e) => vInputChange( [e.target.value, e.target.name] ) } ></input>
        <div className="prediction-submit-section">
          <button onClick={() => handleSubmit(  qId, question, '', "updateDetails" ) } className="button bet_btn_sub bet_btn">Update Details</button>
        </div>
      </div>

      <div className="smaller prediction-add-section">
        <h4 className="prediction-info-item-flex">Report Payout</h4>
        <p>Only the oracle address can report the results.</p>
        <p>Must be Oracle: {addresses.oracle}</p>
        Slotscount:{vSlotCount}<br/><br/>
        Payouts (a,b): [{vReportPayout?.join(", ")}]<br/><br/>
        <input name="vPayout" onBlur={(e) => vInputChange( [e.target.value, e.target.name] ) } ></input>
        <div className="prediction-submit-section">
          <button onClick={() => handleSubmit(  qId, question, '', "report" ) } className="button bet_btn_sub bet_btn">Report Results</button>
        </div>
      </div>
    </div>
  );
};

export { Admin };
