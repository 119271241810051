export const getQuestionById = async (baseString, questionId, endString) => {
  try {
    // Construct the URL using baseString, questionId, and endString
    // Trim any potential whitespace
    const trimmedBaseString = baseString.trim();
    const trimmedQuestionId = questionId.trim();
    const trimmedEndString = endString.trim();

    // Concatenate to form the URL
    console.log()
    const url = `${trimmedBaseString}${trimmedQuestionId}${trimmedEndString}`;
    // Perform the fetch request
    const response = await fetch(url);

    // Check if the response is OK (status code 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Parse the response as JSON
    const questionData = await response.json();

    return questionData;
  } catch (error) {
    console.error("Error fetching question by ID:", error);

    return null; // Or handle the error based on your needs
  }
};
