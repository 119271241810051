import React from 'react';
import {Card} from '../../components/Card';
import sommi1 from '../../assets/images/about/1.png'
import sommi2 from '../../assets/images/about/2.png'; 
import sommi3 from '../../assets/images/about/3.png'; 
import sommi4 from '../../assets/images/about/4.png'; 
import sommi5 from '../../assets/images/about/5.png'; 
import sommi6 from '../../assets/images/about/6.png'; 

export const whySommiCardData = [
    {Header: "Sommi is Contrarian", Body: "Sommi thinks only irrational people can survive the crypto market, not normal people. We have to hodl our nuts like squirrels.", img: sommi1},
    {Header: "Sommi Entertains Us", Body: "Sommi helps us the audience to be aware of ourselves, in a fun, entertaining way. He wants us to think about the likelihood of our expectations.", img: sommi2},
    {Header: "Sommi Knows Too Much", Body: "He’s also a big fan of Sergey Nazarov Chainlink of $LINK token.. because ‘The bug eaters have chosen it’. Its because of this wisdom we have chosen him! ", img: sommi3},
    {Header: "Sommi's Prophecies", Body: "He helps us to understand there is an ongoing battle between smart money and emotional money in the market. He helps us to 'price in the future' instead of today.", img: sommi4},
    {Header: "Sommi's Friendship", Body: "Sommi considers himself as just a messenger. He said he makes videos so that his friends and audience can win together with him. Sommi thinks we can win, so do we!", img: sommi5},
    {Header: "Sommi Loves Real DEFI", Body: "Sommi is a huge fan of the Richard Heart ecosystem, $HEX, $PLSX and Pusleschain stating it is ’real defi’ and ’freedom of speech’! Sommi is the true champion of the people. #insommiwetrust", img: sommi6},
]

const About = () =>{
    return(
        <section class="media section">
        <h2 className="section-title mt-0 about_title">Why do we Love yourfriendSOMMI?</h2>
        <div className="card_container container">
            {whySommiCardData.map((item, index) => (
                <Card
                key={index}
                header={item.Header}
                body={item.Body}
                imgSrc={item.img}
                />
            ))}
        </div>
        </section>
    )
}

export {About};