import React, { useEffect, useState, useRef } from "react";

const HorizontalBanner = ({ data, customStyling = {}, small=false }) => {
  const bannerRef = useRef(null);

  return (
    <div
      ref={bannerRef}
      className={`horizontal-banner ${small && "small"}`}
    >
      {data &&
        Object.entries(data).map(([key, value]) => {
        return (
          <div className="banner-item" key={key}>
            <div className="banner-key">
              <u>{key}</u>
            </div>
            <div
              className={`banner-value ${customStyling[key] || ""}`}
            >
              {value ?? "N/A"}
            </div>
          </div>
        )})}
    </div>
  );
};

export { HorizontalBanner };
