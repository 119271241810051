import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import VestingABI from "../../config/abi/vestingOne.json"

async function VDepositInfo( vestingAddress, tokenAddress, provider, signer) {


  let userInfo = []

  try {
    let vestingContract = new ethers.Contract( vestingAddress, VestingABI.abi,  provider).connect(signer)
    var depoistInfo = await vestingContract.deposits( signer, tokenAddress)
    var nexClaimInfo = await vestingContract.nextClaim( tokenAddress, signer)
    //console.log( 'udp:',  depoistInfo[0],depoistInfo[1],nexClaimInfo[0],nexClaimInfo[1])
    userInfo.push(
      depoistInfo[0],depoistInfo[1],depoistInfo[2], nexClaimInfo[0],nexClaimInfo[1]
    )

  }
  catch (e) {
    //console.log('error:',e)

  }
  console.log('udp2:',userInfo)
  return userInfo
}
export {VDepositInfo};
