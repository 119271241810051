import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import TokenABI from "../../../config/abi/standardTokenABI"
//import {TokenInfo} from  "../modules/tokenInfo"
//import {FInfo} from  "../modules/friendshipInfo"

async function TokenBalance( tokenAddress, userAddress, provider) {

  var balance = 0
  var decimals = 9
  console.log(tokenAddress, userAddress,'token balance')
  try {

    //console.log('decimals:', decimals )

    let tokenContract = new ethers.Contract(tokenAddress, TokenABI.abi,  provider)

    balance = await tokenContract.balanceOf( userAddress )
    //decimals = await tokenContract.decimals()

  } catch (e) {

      console.log('balance allowance error:',e)
  }
  //console.log('balance 1:',parseInt(Number(balance)), 10**9 )
  //FInfo( tokenAddress, provider, '0x995fa5eadCDf96268C7a99357d87fe89dCF7EDd2' )
  balance =  Number(balance) / (10**decimals)
  //balance = balance / (10**decimals)
  //console.log('balance dec:',balance)
  return balance

  }

export {TokenBalance};
