import React, { useState, useEffect } from 'react';
import TokenABI from "../../config/abi/standardTokenABI.json"
import { ethers } from "ethers";
import { getErrorMessage } from '../errorHandler';

//spender = Contract
async function Approve( tokenAddress, spender, amount, provider,  signer ) {
  //const web3 = new Web3( Web3.givenProvider);

  //console.log('aprove:', tokenAddress, spender,  parseInt(amount), provider,  signer )
  var approval= [true, '']

  try {
    let tokenContract = new ethers.Contract(tokenAddress, TokenABI.abi,  provider).connect(signer)
    approval = await tokenContract.approve(spender, amount.toString() )

    const receipt = await approval.wait();
    approval = [true, receipt.blockHash ]


    } catch (e) {
      approval = "N/A"
      var errorMsg = getErrorMessage(e)
      approval = [false, errorMsg]
    }
    console.log('reciept tx:',approval)
    return approval


}
export {Approve};
