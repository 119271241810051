import React from 'react';
import sommiFanBanner from '../../assets/images/sommi fan banner.jpg'
import SommiCryptoRocketMan from '../../assets/images/Sommi-Crypto-Rocket-Man.png'

const Introduction = (props) =>{
    return(
            <section className="hero">
            <div className="container">
                <div className="hero-inner">
                <div className="hero-copy">
                    <h1 className="hero-title mt-0">
                    Welcome friends, to Sommi Fan Token!
                    </h1>
                    <div className="feature-icon">
                    <img src={sommiFanBanner} />
                    </div>
                    <br />
                    <p className="hero-paragraph">
                    Sommi Fan Token is the No.1 Fan Token for crypto influencer{" "}
                    <a
                        href="https://twitter.com/yourfriendSOMMI"
                        className="white-link"
                        target="_blank"
                    >
                        @yourfriendSOMMI
                    </a>
                    </p>
<br/>
 <div className="white-ca">CA: 0x6A46d56F9Dd963cEAc7f60aD148d49219CC3b79f</div>
<br/>
                    <p>

                    <b style={{ color: "#2c374b" }}> ARBITRUM: </b><br /><br />
                    <a style={{ color: "#96bddc" }} target="_blank" href="https://www.sushi.com/swap?chainId=42161&token0=NATIVE&token1=0x6A46d56F9Dd963cEAc7f60aD148d49219CC3b79f&swapAmount=">
                    <button className="button arb-btn">BUY $FRIENDSHIP!</button></a>

                    <a
                        href="https://dexscreener.com/arbitrum/0x80c08ddb2b7c24fc00478c6137bdec66189330ce"
                        style={{ color: "#96bddc" }}
                        className="white-link"
                        target="_blank"
                    >
                    <button  class="button arb-btn">CHART</button>

                    </a>
                    <br />
                    </p>
                    <br/>
                    <p>

                    <b style={{ color: "#7d01ff" }}> PULSECHAIN: </b><br /><br />
                    <a href="https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/?outputCurrency=0x6A46d56F9Dd963cEAc7f60aD148d49219CC3b79f" className="white-link" target="_blank">
                    <button className="button">BUY $FRIENDSHIP!</button></a>
                    <a href="https://dexscreener.com/pulsechain/0xe72951df1f42ab9cd5492d08c01157de52b602bb" className="white-link">
                    <button className="button pls-btn">CHART</button>
                    </a>


                    </p>
                </div>
                <div className="hero-app">
                    <div className="hero-app-illustration"></div>
                    <img
                    className="device-mockup"
                    src={SommiCryptoRocketMan}
                    alt="App preview"
                    />
                    <div className="hero-app-dots hero-app-dots-1">
                    <svg className="RandomBubbles" width={124} height={75} xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fillRule="evenodd">
                        <path
                            fill="#FFF"
                            d="M33.392 0l3.624 1.667.984 3.53-1.158 3.36L33.392 10l-3.249-1.639L28 5.196l1.62-3.674z"
                        />
                        <path
                            fill="#7487A3"
                            d="M74.696 3l1.812.833L77 5.598l-.579 1.68L74.696 8l-1.624-.82L72 5.599l.81-1.837z"
                        />
                        <path
                            fill="#556B8B"
                            d="M40.696 70l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L38 72.599l.81-1.837z"
                        />
                        <path
                            fill="#7487A3"
                            d="M4.314 37l2.899 1.334L8 41.157l-.926 2.688L4.314 45l-2.6-1.31L0 41.156l1.295-2.94zM49.314 32l2.899 1.334.787 2.823-.926 2.688L49.314 40l-2.6-1.31L45 36.156l1.295-2.94z"
                        />
                        <path
                            fill="#556B8B"
                            d="M99.696 56l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L97 58.599l.81-1.837zM112.696 37l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L110 39.599l.81-1.837zM82.696 37l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L80 39.599l.81-1.837zM122.618 57l1.087.5.295 1.059-.347 1.008-1.035.433-.975-.492-.643-.95.486-1.101z"
                        />
                        </g>
                    </svg>
                    </div>
                    <div className="hero-app-dots hero-app-dots-2">
                    <svg width={124} height={75} xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fillRule="evenodd">
                        <path
                            fill="#556B8B"
                            d="M33.392 0l3.624 1.667.984 3.53-1.158 3.36L33.392 10l-3.249-1.639L28 5.196l1.62-3.674zM74.696 3l1.812.833L77 5.598l-.579 1.68L74.696 8l-1.624-.82L72 5.599l.81-1.837zM40.696 70l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L38 72.599l.81-1.837zM4.314 37l2.899 1.334L8 41.157l-.926 2.688L4.314 45l-2.6-1.31L0 41.156l1.295-2.94zM49.314 32l2.899 1.334.787 2.823-.926 2.688L49.314 40l-2.6-1.31L45 36.156l1.295-2.94z"
                        />
                        <path
                            fill="#FFF"
                            d="M99.696 56l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L97 58.599l.81-1.837z"
                        />
                        <path
                            fill="#556B8B"
                            d="M112.696 37l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L110 39.599l.81-1.837z"
                        />
                        <path
                            fill="#FFF"
                            d="M82.696 37l1.812.833.492 1.765-.579 1.68-1.725.722-1.624-.82L80 39.599l.81-1.837z"
                        />
                        <path
                            fill="#556B8B"
                            d="M122.618 57l1.087.5.295 1.059-.347 1.008-1.035.433-.975-.492-.643-.95.486-1.101z"
                        />
                        </g>
                    </svg>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export {Introduction};
