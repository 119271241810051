import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json";


async function FPCollateralInfo(fpmmAddress, provider) {
  let collateral = {token:'NA', isSet:false} ;

  try {
    // Initialize the contract instance
    const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider);
    // Call the contract's function to get the collateral token address
    let collateralTokenAddress = await fpmmContract.collateralToken();
    let isSet = await fpmmContract.isCollateralSet();
    collateral = {token:collateralTokenAddress,isSet:isSet}

  } catch (e) {
    console.error('Error fetching collateral token address:', e);
  }

  console.log('FPMM Collateral Token info:', collateral );
  return collateral;
}

export { FPCollateralInfo };
