export const questionList = [
  {
    questionId: "ff3fd182d8359c49fac7b6a8541a9806ec6cfae70e74056fd86f29eb71c272fb",
    title: "Will Tesla release a flying car by 2025?",
    outcomes: [
      { title: "Option A", short: "Opt A" },
      { title: "Option B", short: "Opt B" },
      { title: "Option C", short: "Opt C" },
      { title: "Option D", short: "Opt D" },
      { title: "Option E", short: "Opt E" },
      { title: "Option F", short: "Opt F" }
    ]
  },
  {
    questionId: "0x5468697320697320736f6d65207175657374696f6e206e756d62657220310000",
    title: "Will Bitcoin hit $100,000 by the end of 2024?",
    outcomes: [
      { title: "Option A", short: "Yes" },
      { title: "Option B", short: "No" }
    ]
  },
  {
    questionId: "4e852da00531b478a5f6756d94dc8efceeb98c3280d68d764f1c6c4571c01033",
    title: "Will Apple announce a new product line by 2023?",
    outcomes: [
      { title: "Option A", short: "Opt A" },
      { title: "Option B", short: "Opt B" },
      { title: "Option C", short: "Opt C" },
      { title: "Option D", short: "Opt D" }
    ]
  },
  {
    questionId: "newQuestion002",
    title: "Will the FIFA World Cup in 2026 introduce new rule changes?",
    outcomes: [
      { title: "Option A", short: "Yes" },
      { title: "Option B", short: "No" }
    ]
  },
  {
    questionId: "c42d66afc417f962764dfa427b40dc21c66e9d6eaa879f30eae88f956b448438",
    title: "Will there be human settlements on Mars by 2050?",
    outcomes: [
      { title: "Option A", short: "Opt A" },
      { title: "Option B", short: "Opt B" },
      { title: "Option C", short: "Opt C" }
    ]
  },
  {
    questionId: "newQuestion003",
    title: "Will electric vehicles make up 50% of all global car sales by 2030?",
    outcomes: [
      { title: "Option A", short: "Yes" },
      { title: "Option B", short: "No" }
    ]
  },
  {
    questionId: "1051a90dade9f4567871debfeb2495721964e587404931028f2c52fc0a15c040",
    title: "Will renewable energy become the dominant power source by 2030?",
    outcomes: [
      { title: "Option A", short: "Opt A" },
      { title: "Option B", short: "Opt B" },
      { title: "Option C", short: "Opt C" },
      { title: "Option D", short: "Opt D" },
      { title: "Option E", short: "Opt E" }
    ]
  },
  {
    questionId: "4722079102bf17998c06c2a10fef723ac6a1a52b5db8390bf51e1733a77732ab",
    title: "Will climate change targets be met by 2040?",
    outcomes: [
      { title: "Option A", short: "Opt A" },
      { title: "Option B", short: "Opt B" }
    ]
  },
  {
    questionId: "newQuestion004",
    title: "Will AI surpass human intelligence by 2050?",
    outcomes: [
      { title: "Option A", short: "Yes" },
      { title: "Option B", short: "No" }
    ]
  },
  {
    questionId: "6e9c54bafa87937d3f287e0036de389aeab0a0ac10ab4f40214cce4c9f572364",
    title: "Will personal flying vehicles be commonplace by 2050?",
    outcomes: [
      { title: "Option A", short: "Opt A" },
      { title: "Option B", short: "Opt B" },
      { title: "Option C", short: "Opt C" },
      { title: "Option D", short: "Opt D" },
      { title: "Option E", short: "Opt E" },
      { title: "Option F", short: "Opt F" }
    ]
  },
  {
    questionId: "newQuestion005",
    title: "Will the global population exceed 10 billion by 2050?",
    outcomes: [
      { title: "Option A", short: "Yes" },
      { title: "Option B", short: "No" }
    ]
  },
  {
    questionId: "68eb892e4804daf0be4a7b4aeaa7eb2b2d4bb49dc248aa1bf9b67b3f95a781c1",
    title: "Will humans successfully communicate with an alien civilization by 2100?",
    outcomes: [
      { title: "Option A", short: "Opt A" },
      { title: "Option B", short: "Opt B" }
    ]
  },
  {
    questionId: "f2f41f1dc2df7b13de9cd07172cd2fef5e6847f97d63e077e60e25a7a6f791b2",
    title: "Will 90% of the world's data be stored on blockchain by 2040?",
    outcomes: [
      { title: "Option A", short: "Opt A" },
      { title: "Option B", short: "Opt B" },
      { title: "Option C", short: "Opt C" },
      { title: "Option D", short: "Opt D" },
      { title: "Option E", short: "Opt E" }
    ]
  },
  {
    questionId: "ecb499026b44d69d980d29dbecd7fe1a11886c528745304bea76593f0ae7e681",
    title: "Will climate engineering technologies be globally accepted by 2040?",
    outcomes: [
      { title: "Option A", short: "Opt A" },
      { title: "Option B", short: "Opt B" },
      { title: "Option C", short: "Opt C" }
    ]
  }
];



export const dataList = [
  {
    questionId: "ff3fd182d8359c49fac7b6a8541a9806ec6cfae70e74056fd86f29eb71c272fb",
    totalBet: 926,
    outcomes: [
      { title: "Option A", perCent: 71 },
      { title: "Option B", perCent: 47 },
      { title: "Option C", perCent: 35 },
      { title: "Option D", perCent: 62 },
      { title: "Option E", perCent: 65 },
      { title: "Option F", perCent: 83 }
    ]
  },
  {
    questionId: "4e852da00531b478a5f6756d94dc8efceeb98c3280d68d764f1c6c4571c01033",
    totalBet: 2854,
    outcomes: [
      { title: "Option A", perCent: 46 },
      { title: "Option B", perCent: 91 },
      { title: "Option C", perCent: 20 },
      { title: "Option D", perCent: 84 }
    ]
  },
  {
    questionId: "c42d66afc417f962764dfa427b40dc21c66e9d6eaa879f30eae88f956b448438",
    totalBet: 338,
    outcomes: [
      { title: "Option A", perCent: 15 },
      { title: "Option B", perCent: 86 },
      { title: "Option C", perCent: 100 }
    ]
  },
  {
    questionId: "1051a90dade9f4567871debfeb2495721964e587404931028f2c52fc0a15c040",
    totalBet: 6349,
    outcomes: [
      { title: "Option A", perCent: 99 },
      { title: "Option B", perCent: 14 },
      { title: "Option C", perCent: 40 },
      { title: "Option D", perCent: 24 },
      { title: "Option E", perCent: 98 }
    ]
  },
  {
    questionId: "0x5468697320697320736f6d65207175657374696f6e206e756d62657220310000",
    totalBet: 482,
    outcomes: [
      { title: "Option A", perCent: 83 },
      { title: "Option B", perCent: 36 },
    ]
  },
  {
    questionId: "6e9c54bafa87937d3f287e0036de389aeab0a0ac10ab4f40214cce4c9f572364",
    totalBet: 8026,
    outcomes: [
      { title: "Option A", perCent: 29 },
      { title: "Option B", perCent: 14 },
      { title: "Option C", perCent: 84 },
      { title: "Option D", perCent: 56 },
      { title: "Option E", perCent: 92 },
      { title: "Option F", perCent: 19 }
    ]
  },
  {
    questionId: "68eb892e4804daf0be4a7b4aeaa7eb2b2d4bb49dc248aa1bf9b67b3f95a781c1",
    totalBet: 946,
    outcomes: [
      { title: "Option A", perCent: 57 },
      { title: "Option B", perCent: 28 }
    ]
  },
  {
    questionId: "f2f41f1dc2df7b13de9cd07172cd2fef5e6847f97d63e077e60e25a7a6f791b2",
    totalBet: 7616,
    outcomes: [
      { title: "Option A", perCent: 82 },
      { title: "Option B", perCent: 48 },
      { title: "Option C", perCent: 77 },
      { title: "Option D", perCent: 5 },
      { title: "Option E", perCent: 13 }
    ]
  },
  {
    questionId: "ecb499026b44d69d980d29dbecd7fe1a11886c528745304bea76593f0ae7e681",
    totalBet: 5199,
    outcomes: [
      { title: "Option A", perCent: 22 },
      { title: "Option B", perCent: 38 },
      { title: "Option C", perCent: 9 }
    ]
  }
];
