import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json"; // Fixed Product Market Maker ABI
import ERC20ABI from "../../../../config/abi/ERC20ABI.json"; // Assuming ERC20 ABI is available
import { getErrorMessage } from '../../../errorHandler';


async function Buy( fpmmAddress, collateralTokenAddress,  investmentAmount, outcomeIndex, minOutcomeTokensToBuy ,provider ,signer , allowance){
        // Address of the Fixed Product Market Maker contract
     // Address of the collateral token (ERC20)
          // Amount of collateral to invest
          // Index of the outcome being bought
         // Minimum outcome tokens to buy (for slippage protection)


    let buyResult = [true, ''];

    try {
        console.log('Executing buy with investment amount:', collateralTokenAddress,  investmentAmount, outcomeIndex, minOutcomeTokensToBuy,
      allowance);
        // Create a contract instance for the Fixed Product Market Maker
        const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider).connect(signer);

        if (Number(allowance) < Number(investmentAmount)  ){
          const collateralTokenContract = new ethers.Contract(collateralTokenAddress, ERC20ABI.abi, provider).connect(signer);
          const approveTx = await collateralTokenContract.approve(fpmmAddress, investmentAmount);
          console.log('Approval transaction sent:', approveTx.hash);
          const receipt = await provider.waitForTransaction(approveTx.hash)
        }

        // Call the buy function on the FPMM contract
        const tx = await fpmmContract.buy(investmentAmount,  outcomeIndex, 0 );
        console.log('Buy transaction sent:', tx.hash);
        // Wait for the transaction confirmation
        buyResult = [true, tx.hash];

    } catch (error) {
        console.error('Error executing buy:', error);
        const errorMsg = getErrorMessage(error);
        buyResult = [false, errorMsg];
    }

    return buyResult;
}

export { Buy };
