import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import VestingABI from "../../config/abi/vestingOne.json"

async function VDeposit( vestingAddress, tokenAddress, amount, signer , provider ) {

  var deposit = ''
  try {
    let vestingContract = new ethers.Contract( vestingAddress, VestingABI.abi, provider).connect(signer)
    deposit = await vestingContract.deposit(tokenAddress, amount.toString())
  }
  catch (e) {
    //console.log('my deposit error:',e)
    //console.log('my deposit error:', e.message )
    deposit = 'Deposit are lockeded'
  }
  return deposit
}
export {VDeposit};
