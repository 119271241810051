import tokens from '../config/contracts/tokens.json'

export function getPredictionAddresses(chainId) {
    // Get the chainId using the Ethereum provider
    //if (!window.ethereum) {
    //  throw new Error("Ethereum provider not found.");
    //}

    //const chainIdHex = await window.ethereum.request({ method: "eth_chainId" });
    //const chainId = parseInt(chainIdHex, 16); // Convert hex to decimal

    const matchingKeys = [];

    // Loop through each key/token in the token data
    for (const [key, value] of Object.entries(tokens)) {
      // Check if predictions exist for the current key
      if (value.predictions && Array.isArray(value.predictions)) {
        // Check if the current chainId exists in the predictions array
        if (value.predictions.includes(chainId)) {
          const address = value[chainId];
          if(address){
              matchingKeys.push( {name:key ,value:address} );
          }

        }
      }
    }
    //console.log('matchingKeys',matchingKeys)
    return matchingKeys;
  }
