import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import VestingABI from "../../config/abi/vestingOne.json"

async function VTimes( vestingAddress, provider, signer) {
  
  let vestingInfo = []
  try {
    let vestingContract = new ethers.Contract(vestingAddress, VestingABI.abi, provider)
    //console.log("VestingContract:", vestingContract.methods)
    var startTime = await vestingContract.startTime()
    var endTime = await vestingContract.endTime()
    var lockPeriod = await vestingContract.lockPeriod()
    vestingInfo.push(
      [startTime,endTime,lockPeriod]
    );
  }
  catch (e) {
    //console.log('error:',e)
    vestingInfo = [0,0,0];
  }
  console.log('vestingInfo:',vestingInfo)
  return vestingInfo
}
export {VTimes};
