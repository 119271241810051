import React, { useState, useEffect } from "react";
import RPCs from "../../config/RPCs.json";

const cache = {};

function importAll(r) {
  r.keys().forEach((key) => (cache[key] = r(key)));
}
// Note from the docs -> Warning: The arguments passed to require.context must be literals!
importAll(
  require.context(
    "../../assets/images/chainselect",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const images = Object.entries(cache).map((p) => {
  var tmpWord = p[0].split("/")[1];
  var tmpWord2 = tmpWord.split(".")[0];
  var finWord = tmpWord2.charAt(0).toUpperCase() + tmpWord2.slice(1);
  //console.log(finWord, RPCs[finWord].chain, RPCs[finWord].rpc)

  return [finWord, p[1], RPCs[finWord].chain];
});

const Logos = (props) => {
  const {
    handleClick,
    setIcon,
    icon,
    chainData,
    logoImages,
    setLogoImages,
    showString,
    setShowString,
  } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    //populate state from folder of images
    setLogoImages(images);
  }, []);

  useEffect(() => {
    const fetchChainId = async () => {
      try {
        const hexChainId = await window.ethereum.request({ method: 'eth_chainId' });
        const walletChainId = parseInt(hexChainId, 16).toString();
        const matchedIcon = logoImages?.find((logo) => logo[0] === walletChainId);
        setIcon(matchedIcon); // Update the icon state, triggering re-render
      } catch (error) {
        console.error("Failed to fetch chain ID", error);
      }
    };
  
    // Initial fetch
    fetchChainId();
  
    // Listen for chain changes
    const handleChainChanged = () => {
      fetchChainId();
    };
  
    window.ethereum?.on('chainChanged', handleChainChanged);
  
    // Cleanup on unmount
    return () => {
      window.ethereum?.removeListener('chainChanged', handleChainChanged);
    };
  }, [chainData?.chainId, icon]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (screenWidth < 1300 && showString) {
      setShowString(false);
    }
    if (screenWidth >= 1300 && !showString) {
      setShowString(true);
    }
  }, [screenWidth]);

  const handleResize = () => {
    requestAnimationFrame(() => {
      setScreenWidth(window.innerWidth);
    });
  };

  return (
    <>
      {logoImages
        ? logoImages.map((logo) => {
            if (icon) {
              if (icon[0] != logo[0]) {
                return (
                  <div
                    className="chainSelect-item"
                    onClick={() => handleClick(logo[0])}
                  >
                    <img src={logo[1] ? logo[1] : null} key={logo[0]} />
                    {showString ? <p>{logo[2]}</p> : null}
                  </div>
                );
              }
            }
          })
        : null}
    </>
  );
};

export { Logos };
