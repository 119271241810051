import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";

// Function to get Payout Numerator for a specific outcome slot
async function PayoutNumerator(conditionalAddress, conditionId, index, provider) {

    let payoutNumerator = 'NA';

    try {
        console.log('Fetching Payout Numerator for Condition ID:', conditionId, 'at index:', index);

        // Instantiate the contract
        let conditionalTokenContract = new ethers.Contract(conditionalAddress, ConditionalTokenABI.abi, provider);

        // Fetch the payout numerator for the specified condition and index
        payoutNumerator = await conditionalTokenContract.payoutNumerators(conditionId, index);

        console.log('Payout Numerator:', payoutNumerator.toString());

    } catch (error) {
        console.error('Error fetching Payout Numerator:', error);
        //throw error;
    }

    return Number(payoutNumerator);
}

export { PayoutNumerator };
