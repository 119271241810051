import React from 'react';
import { useErrorContext } from '../context/ErrorProvider'; // Import the ErrorProvider and useError
import { PopUpMsg, } from './PopUpMsg';

// A functional component to handle error pop-ups
const ErrorPopupHandler = () => {
  const { error, errorType, closePopup } = useErrorContext(); 
      return (
      <>
        {error !== "" && <PopUpMsg msg={error} errorType={errorType} onClose={closePopup} />}
      </>
    );
  };
  
export {ErrorPopupHandler}