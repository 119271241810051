import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ progress }) => {
    const containerStyles = {
        height: '30px',
        width: '100%',
        backgroundColor: '#e0e0df',
        borderRadius: '5px',
        boxShadow: '1px 2px 2px black'
    };

    const fillerStyles = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: '#007bff',
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 0.2s ease-in'
    };

    const labelStyles = {
        padding: '5px',
        color: 'white',
        fontWeight: 'bold'
    };

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${progress}%`}</span>
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    progress: PropTypes.number.isRequired
};

export {ProgressBar};
