import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";


// Function to get Condition ID
async function ConditionId(conditionalAddress, oracle, questionId, outcomeSlotCount, provider) {

    var conditionId = 'NA'

    try {
        console.log('Fetching Condition ID for:', oracle, questionId, outcomeSlotCount);

        // Instantiate the contract
        let conditionalTokenContract = new ethers.Contract(conditionalAddress, ConditionalTokenABI.abi, provider);
        conditionId = await conditionalTokenContract.getConditionId(oracle, questionId, outcomeSlotCount);

        console.log('Condition ID:', conditionId);

        //return conditionId;
    } catch (error) {
        console.error('Error fetching Condition ID:', error);
        //throw error;
    }
    return conditionId;
}

export { ConditionId };
