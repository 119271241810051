import { ethers } from "ethers";
import WhitelistABI from "../../../../config/abi/prediction/WhitelistABI.json";

async function CollateralWhitelist(contractAddress, provider) {
    let result = null;

    try {
        // Initialize the contract instance
        const whitelistContract = new ethers.Contract(contractAddress, WhitelistABI.abi, provider);

        // Call the contract's function to get the collateral whitelist
        const collateralAddresses = await whitelistContract.getCollateralWhitelist();
        console.log('Retrieved collateral whitelist:', collateralAddresses);

        // Store the result
        result = collateralAddresses;

    } catch (error) {
        console.error('Error retrieving collateral whitelist:', error);
    }

    return result;
}

export { CollateralWhitelist };
