import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json";

/**
 * Calculate the sell amount of outcome tokens for a given return amount and outcome index.
 *
 * @param {string} fpmmAddress - The address of the Fixed Product Market Maker contract.
 * @param {number} returnAmount - The amount of collateral to return (in wei).
 * @param {number} outcomeIndex - The index of the outcome for which to sell tokens.
 * @param {ethers.providers.Provider} provider - The ethers.js provider instance.
 * @returns {Promise<number>} - A promise that resolves to the amount of outcome tokens to sell.
 */
async function FPCalcSellAmount(fpmmAddress, returnAmount, outcomeIndex, provider) {
  let sellAmount = 0;
  // console.log("debug", fpmmAddress)
  try {
    // Initialize the contract instance
    const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider);
    // Call the contract's function to calculate the sell amount
    //ethers.utils.parseUnits(returnAmount.toString(), 'wei')
    sellAmount = await fpmmContract.calcSellAmount(returnAmount, outcomeIndex);

  } catch (e) {
    console.error('Error calculating sell amount:', e);
  }

  // Convert to a regular number if needed and log
  console.log('Sell Amount:', sellAmount)
  return Number(sellAmount)

}

export { FPCalcSellAmount };
