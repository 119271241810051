import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";
import { getErrorMessage } from '../../../errorHandler';

// Function to redeem positions
async function RedeemPosition(conditionalAddress, collateralTokenAddress, parentCollectionId, conditionId, indexSets, provider, signer) {
    let redemptionResult = [true, ''];

    try {
        console.log('Redeeming positions for condition:', conditionalAddress, collateralTokenAddress,conditionId, indexSets);

        // Create a contract instance using the provider and signer
        let conditionalTokenContract = new ethers.Contract(conditionalAddress, ConditionalTokenABI.abi, provider).connect(signer);

        // Call the redeemPositions function
        let tx = await conditionalTokenContract.redeemPositions(
            collateralTokenAddress,   // Address of the collateral token
            parentCollectionId,       // ID of the parent collection
            conditionId,              // ID of the condition
            indexSets                 // Array of index sets to redeem
        );

        console.log('Redemption Transaction sent:', tx.hash);

        // Wait for transaction confirmation
        // const receipt = await tx.wait();
        redemptionResult = [true, tx.hash];

    } catch (error) {
        console.error('Error redeeming positions:', error);
        const errorMsg = getErrorMessage(error);
        redemptionResult = [false, errorMsg];
    }

    return redemptionResult;
}

export { RedeemPosition };
