import { ethers } from "ethers";
import FriendshipABI from "../config/abi/FriendshipABI.json"

async function FInfo( tokenAddress, provider, userAddress ) {

  var infoDict = {balance: 0, sellRatio:0, userShare:0, userStatus:0, userTracker:0 }
  var balance = 0
  //console.log('finfos:', tokenAddress, provider, userAddress)
  try {
    //console.log('token info is running')
    let tokenContract = new ethers.Contract( tokenAddress, FriendshipABI,  provider)

    const balance = await tokenContract.balanceOf( userAddress )
    const sellRatio = await tokenContract.sellRatio( userAddress )
    const userShare = await tokenContract.userShare( userAddress )
    const userStatus =  await tokenContract.userStatus( userAddress )
    const userTracker = await tokenContract.userTracker(userAddress )

    infoDict = {balance: balance, sellRatio:sellRatio, userShare:userShare, userStatus:userStatus, userTracker:userTracker }
      //"symbol":symbol},{"decimals":decimals},{"name":name}
      //[balance,sellRatio,userShare,userStatus,userTracker]//,userStatus,userTracker]
    //);
  }
  catch (e) {
    console.log('error:',e)
  }
  console.log('ftoken info:',infoDict)
  return infoDict
}
export {FInfo};
