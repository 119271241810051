import React from 'react';
import { cleanNumbers, numToPercent } from '../modules/cleanNumbers';

const FriendshipPanel = ({ chain, balance, sellRatio, userShare, userStatus, userTracker, nativeTokens, friendshipTokens }) => {
  //cleanNumbers rounds to x digits and makes view value easier
  //numToPercent converts any number to % using first 3 spaces

  function currency(chn) {
    var cur = 'ETH'
    if (chn === 'PULSECHAIN') {
      cur = 'PLS'
    }
    return cur
  }
  function rnder(num, x) {
    var newNum = Math.round(parseInt(num) * 1000 / (10 ** x)) / 1000
    return newNum
  }
  //console.log('allnew:',chain,  sellRatio, userShare)
  return (
    <div className="panel friendship-panel">
      <h4>{chain && chain.toUpperCase()}</h4>
      <div className="friendship-panel-inner">
        <b>Balance</b>
        <p className="depressionStyle" style={{ gridRow: 2 }}>{rnder(balance, 9)} <br/> ($FRIENDSHIP)</p>
        <b>Balance</b>
        <p className="depressionStyle" style={{ gridRow: 2 }}>{rnder(nativeTokens, 18)} <br/> (${currency(chain)})</p>
        <b>Total Spend</b>
        <p className="depressionStyle" style={{ gridRow: 2 }}>{rnder(userTracker[1], 18)} <br/> (${currency(chain)})</p>
        <b>Sell Ratio</b>
        <p className="depressionStyle" style={{ gridRow: 2 }}>{sellRatio ? numToPercent(parseInt(sellRatio)) + "%" : "N/A"}</p>
        <b>Last Spend</b>
        <p className="depressionStyle" style={{ gridRow: 2 }}>{rnder(userTracker[0], 18)} <br/> (${currency(chain)})</p>
      </div>

    </div>
  )
}

export { FriendshipPanel };
