import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";

async function BalanceOfBatch(conditionalAddress, ownerAddresses, ids, provider) {
  let balances = [];

  try {
    console.log('Fetching batch balances for:', conditionalAddress, ownerAddresses, ids);

    // Initialize the contract instance
    let conditionalTokenContract = new ethers.Contract(conditionalAddress, ConditionalTokenABI.abi, provider);

    // Call the balanceOfBatch function
    balances = await conditionalTokenContract.balanceOfBatch(ownerAddresses, ids);

    // Convert BigNumber balances to regular numbers
    balances = balances.map(balance => parseInt(balance.toString()));
  } catch (error) {
    console.error('Error fetching batch balances:', error);

    // Set default balances in case of an error (for debugging)
    balances = ownerAddresses.map(() => 1000);
  }

  console.log('Batch balances:', balances);
  return balances;
}

export { BalanceOfBatch };
