import React, { useState, useEffect, useMemo, useRef } from 'react';
import greenBlack from '../../assets/images/background/green-black.jpg'
import { FriendshipPanelController } from './FriendshipPanelController';
import { NftGalleryPhotos } from '../../components/NftGalleryPhotos'
import { Panel } from '../../components/Panel'
import { UserInfo } from './UserInfo';
import { MainPanel } from './MainPanel';
import { TokenInfo } from '../../modules/tokenInfo';
import tokensAddrs from '../../config/contracts/tokens.json';
import RPCs from '../../config/RPCs.json';
import { TokenBalance } from '../../modules/tokenBalance';
import { useGlobalState } from '../../components/context/GlobalStateProvider';
import { JsonRpcProvider } from 'ethers';
import { LogoDict } from '../../assets/images/chainselect/logos';
import { MyBigInt } from '../../modules/MyBigInt';
import {FInfo} from '../../modules/friendshipInfo';
import {userNftCollection} from '../../modules/nfts/userNftCollection';
import { PLS_NFT_HOLDERS } from '../../config/configurations';

const ProfilePage = () => {
  const [selected, setSelected] = useState(0)
  const [user, setUser] = useState(); //{ walletId: null, nativeTokens: {}, friendshipTokens: { Arbitrum: 0, PULSECHAIN: 0 }, NFTS: [], data: null }
  const supported_chains = ["Arbitrum", "PULSECHAIN"]
  const { storedTokenInfo, chainData, walletInfo, isConnected } = useGlobalState();
  const intervalIdRef = useRef(null);

  useEffect(()=>{
    getBalance()  
    if(intervalIdRef?.current){
      intervalIdRef.current = setInterval(getBalance,12000);
    }
      return () => clearInterval(intervalIdRef.current);
  },[])
 
  const friendShipData = useMemo(() => {
    const tmpObj = {};
    Object.entries(RPCs).forEach(([key, value]) => {
        const chainName = value.chain;
        if(supported_chains.includes(chainName)){
        const tokenContractAddress = tokensAddrs.Friendship[key];
        tmpObj[chainName] = { address: tokenContractAddress, defaultProvider: new JsonRpcProvider(value.rpc), logo: LogoDict[key] };
  }});
    return tmpObj;
  }, []);

  const getBalance = async () => {
    if (!isConnected || !friendShipData || !walletInfo?.signer) return;
    var tmpFriendshipObj = {};
    var tmpNativeObj = {};
    var tmpDataObj = {}
    let nftsDict = await userNftCollection(walletInfo?.signer?.address, PLS_NFT_HOLDERS)
    //let nftsDict = await userNftCollection("0xA1b2C3d4E5F67890AbCdEf0123456789AbCdEf01", PLS_NFT_HOLDERS) //No NFT USEr
    //let nftsDict = await userNftCollection("0xb8bF8D8cE14F092A21dA559ae2a4eD84EFF64b09",PLS_NFT_HOLDERS ) //LOTS NFT USEr

    for (const [key, value] of Object.entries(friendShipData)) {
        let friendshipBalance = await TokenBalance(value?.address, walletInfo?.signer.address, value.defaultProvider, storedTokenInfo);
        let nativeBalance = await value.defaultProvider.getBalance(walletInfo?.signer.address);
        let userData = await FInfo(value.address, value.defaultProvider, walletInfo?.signer.address)
        tmpFriendshipObj[key] = friendshipBalance;
        tmpNativeObj[key] = nativeBalance;
        tmpDataObj[key] = userData;
    }
    setUser({ walletId: walletInfo.signer, nativeTokens: tmpNativeObj, friendshipTokens: tmpFriendshipObj, NFTS: nftsDict, data: tmpDataObj });
};
  return (
    <section className='media section'>
      <div className='demoDapp-container'>
        <div className="profilePage-container">
        <div className="panel profile-title-panel"><h1>Profile</h1></div>
        {user ?<> 
          <div className='ProfilePage-topRow'>

          <MainPanel className="reference" user={user} selected={selected} setSelected={setSelected} ownedNfts={user?.NFTS} />
          {Object.keys(user?.NFTS).length > 1 &&
          <NftGalleryPhotos className="nftGallery" selected={selected} setSelected={setSelected} ownedNfts={user?.NFTS} showBtns={false} state={"user"} /> 
        }
          </div>

          <FriendshipPanelController user={user} /> 

          </>: <div className='panel loading-panel'> Loading....</div> }
          </div>

        <img src={greenBlack} />
      </div>
    </section>

  );
};

export { ProfilePage };
