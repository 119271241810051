import React, { useState, useEffect } from 'react';
import { questionList } from '../../config/markets/marketsConfig';
import { Controller } from './Controller';
import { getQuestionById } from './getQuestionById';
import { FAKEWEB3CALL } from './FAKEWEB3CALL';
import source_list from '../../config/source_list.json'

const Grid = ({ outcomeSelect, handleSubmit, questionList , qBaseUrl, gridView, vInputChange, vCalcBuyShares, vBatchPositionInfo,
  collateralInfo, userWTokenBalance,chainId , userFPMMAllowance, vInValue}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState(questionList); // Initialize with full question list
  const [questionsWithData, setQuestionsWithData] = useState([]); // For storing questions with relevant data
  const [questionsWithDetails, setQuestionsWithDetails] = useState([]); // For storing questions with FAKEWEB3CALL data
  const [activeController, setActiveController] = useState(null);

  //const baseString="https://arweave.net/myFkl2qOjO-5_NJibqIdeoN5JkTBefBWr9l2Dhy1SwA/"
  const endString=".json"

  useEffect(()=>{
    fetchQuestionsData(questionList)
  },[questionList])


  useEffect(() => {
    const regex = new RegExp(searchQuery, 'i'); // 'i' flag for case-insensitive matching
    const newFilteredQuestions = questionList?.filter((question) => regex.test(question?.title)); // Filter questions by search query
    setFilteredQuestions(newFilteredQuestions); // Set filtered questions
    fetchQuestionsData(newFilteredQuestions); // Fetch relevant data for filtered questions
  }, [searchQuery]);

  // Function to handle search input changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to fetch relevant data for filtered questions
  const fetchQuestionsData = async (questions) => {
    if(!questions){return}
    const updatedQuestions = await Promise.all(
      questions?.map(async (question) => {
        const relevantQstnData = await getQuestionById(qBaseUrl, question?.questionString, endString);

        let thisState = { predictionState: 'buy', viewType: null };
        //if(question?.question_id === activeController){ console.log("debug relevant", relevantQstnData)}
        if (relevantQstnData) { //set multi or single
          //vInputChange( [  [relevantQstnData.question_id, relevantQstnData.outcomes] , "testOutcomes"]);
          relevantQstnData?.outcomes?.length > 2 /*||
          (//logic to justify 2 cases of multi
            relevantQstnData?.outcomes?.length === 2 &&
            !relevantQstnData?.outcomes?.some(outcome => outcome?.title === 'Yes') &&
            !relevantQstnData?.outcomes?.some(outcome => outcome?.title === 'No')
          )*/
            ? (thisState.viewType = 'multi')
            : (thisState.viewType = 'single');

          }
          switch (gridView) {
            case 10:
              // Case 10: No operation
              break;
            case 100:
              // Ensure immutability if thisState is React state
              if (thisState) {
                thisState = { ...thisState, predictionState: "liquidity" };
              }
              break;
            default:
              break;
          }
        return {
          ...question,
          relevantQstnData,
          thisState,
        };
      })
    );


    setQuestionsWithData(updatedQuestions); // Update the state with questions and relevant data
    //console.log('activeController',updatedQuestions)
  };

  //OutcomesCount


  return (
    <div className="market_grid_container">
      {/* <input
        className="market_search_bar"
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search by question title..."
      /> */}
      {/* Bet Card List */}
      <div className="bet-list">
        {questionsWithData.length > 0 ? (
          questionsWithData.map((question) => {
            if(!question){return null}
            const { relevantQstnData, thisState, questionDetails, loading } = question;
            //console.log("debug", question) //TODO Logic for not returning live/dead state

            const date = new Date(relevantQstnData?.resolution_date_time);
            let thisDate = null
            if (!isNaN(date.getTime())){
              thisDate = date.toISOString().split('T')[0];
            }
            //vInputChange( [  [question?.questionString, question.eventAddress, relevantQstnData?.outcomes] , "testOutcomes"]);
            //console.log('question eventAddress:',question?.questionString, question.eventAddress)
            return (
              <Controller
                key={question?.questionString}
                qId={question.questionString}
                question={relevantQstnData?.title}
                betPercent={relevantQstnData && relevantQstnData?.outcomes && relevantQstnData?.outcomes?.map((outcome) => outcome?.perCent)}
                options={relevantQstnData?.outcomes?.map((outcome) => outcome?.short)}
                sourceId={question?.sourceId && source_list[Number(question.sourceId)]?.name}
                source_img={question?.sourceId && source_list.find(item => item.id === Number(question.sourceId))?.img}
                source_view={relevantQstnData?.source_view}
                source_name={relevantQstnData?.source_name}
                resolution={relevantQstnData?.resolution}
                resolution_date_time={ thisDate }
                state={thisState}
                source_url={relevantQstnData?.url}
                source_embed={''}
                collateralToken={question?.collateralToken}
                activeController={activeController}
                setActiveController={setActiveController}
                outcomeSelect={outcomeSelect}
                handleSubmit={handleSubmit}
                questionList={questionList}
                questionDetails={questionDetails}
                loading={loading}
                vInputChange = {vInputChange}
                vEventAddress = {question.eventAddress}
                collateralInfo={collateralInfo}
                vCalcBuyShares={vCalcBuyShares}
                vBatchPositionInfo={vBatchPositionInfo}
                userWTokenBalance={userWTokenBalance}
                chainId={chainId}
                userFPMMAllowance={userFPMMAllowance}
                vInValue={vInValue}
              />
            );
          })
        ) : (
          <p>No results found for "{searchQuery}".</p>
        )}
      </div>
    </div>
  );
};

export { Grid };
