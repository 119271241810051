import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json";


async function FeesSplit(fpmmAddress, feeType, provider) {
  try {
    // Initialize the contract instance
    const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider);

    // Fetch the fees based on feeType
    const feesData = feeType === 1
      ? await fpmmContract.allFees()
      : await fpmmContract.friendshipFees();

    // Structure the fees in a consistent format
    const fees = { dev: Number(feesData[0]), donate: Number(feesData[1]), user: Number(feesData[2]), community: Number(feesData[3]), outreach: Number(feesData[4]) };

    //console.log('Fees:', fees);
    return fees;

  } catch (e) {
    console.error('Error fetching fees:', e);
    // Return an object with zero values if an error occurs
    return { dev: 0, donate: 0, user: 0, community: 0, outreach: 0 };
  }
}

export { FeesSplit };
