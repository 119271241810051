import React, { useState, useEffect } from "react";
import {PercentageInput} from '../../inputs/PercentageInput';
import { getPredictionAddresses } from '../../../modules/getPredictionAddresses';
import { HorizontalBanner } from "../../HorizontalBanner";
import { useLocation } from "react-router-dom";
import { DebugComponent } from "../../DebugComponent";

let userLPFees = 100;
let dHintSelection = [ "neutral", "extremely high", "high", "marginaly high", "marginaly low", "low", "extremely low", ];
let dHintSplit = [50, 90, 75, 60, 40, 25, 10];
let ilPercent = 30;


const Liquidity = (props) => {
  const { qId, activeController, handleSubmit, viewData, updateViewData, question, InputHandle, setActiveController, betPercent, options,
     questionSelect,   vInputChange, collateralAddy, userFPMMAllowance, vInValue, userCTBalance, fpFees, feesSplit, tvlBalance,
   chainId, vSelectedLP, collateralInfo, userWTokenBalance, outcomesCount} = props;
  //onst [tokenlist , setTokenlist] = useState([])
  const [loading, setLoading] = useState(false);
  const location = useLocation()
  const isPredictionPage = location?.pathname === "/dapp/prediction"

  useEffect(()=>{
    if(viewData?.userLPFees !== userLPFees && isPredictionPage){updateViewData("userLPFees", userLPFees)}
  },[viewData])

const tokenlist = getPredictionAddresses(chainId);

let buttonText = 'Approve + Add Liquidity'
function checkApprove(){
  console.log('userFPMMAllowance, vInValue', userFPMMAllowance, vInValue*10**9 )
  if ( userFPMMAllowance >= vInValue*10**9 ){
    console.log('inside userFPMMAllowance, vInValue', userFPMMAllowance, vInValue*10**9 )
    buttonText = 'Add Liquidity'
  }
}
checkApprove()

/*
  function changeDHint() {
    let outcomeIndex = options?.indexOf(viewData.selectedOutcome); //Error 01/11/2024 not defined
    let dhTypeIndex = dHintSelection?.indexOf(viewData.selectedHint);
    let newDHIndex = dhTypeIndex * 2;

    let yesMain = dHintSplit[dhTypeIndex];
    let noMain = 100 - dHintSplit[dhTypeIndex];
    let yesOther = noMain;
    let noOther = yesMain;



    let arr = Array.from({ length: 2 * options?.length }, (_, index) =>
      index % 2 === 0 ? yesOther : noOther
    );

    if (outcomeIndex >= 0 && newDHIndex + 1 < arr.length) {
      arr[outcomeIndex] = yesMain;
      arr[outcomeIndex + 1] = noMain;
    }
    console.log('dhint arr:',arr)
    if (tvlBalance >0 ) {
      arr = []
      console.log('arr2:',arr)
      //vInputChange
    }

    return arr;
  }
*/

function changeDHint() {
    // Calculate the length of the array, which is 2^x
    let x = outcomesCount;
    let value = 1000;

    let length = Math.pow(2, x);
    if (outcomesCount === 2 ){
      length = 2
    }

    // Initialize the array with zeros
    let array = new Array(length).fill(1000);

    // Loop through the powers of 2 indices and set them to the specified value/
/*
    for (let i = 0; i < length; i++) {
        // Check if the index is a power of 2 (1, 2, 4, 8, 16, ...)
        if ((i + 1) & i) {
            continue; // Skip non-power-of-2 indices
        }
        array[i] = value; // Set the value at the power of 2 index
        array[array.length - 1] = 1
    }
*/
    if (tvlBalance >0 ) {
      array = []
      console.log('arr2:',array)
      //vInputChange
    }

    return array;
}

// Example usage





  //press button
  const callHandleSubmit = (currentViewData, qId, question, type) => {
    //distribution //updateViewData
    let tmpDistribution = changeDHint();
    const newUpdateViewData = {
      ...currentViewData,
      distributionInfo: tmpDistribution,
      userBinaryPartSelection: viewData.selectedHint,
      userMultiPartSelection: viewData.selectedOutcome,
    };
    //from prediction page
    handleSubmit(newUpdateViewData, qId, question, type);
  };

const setWithdrawValue = (val) =>{
  console.log("val:", val)
  updateViewData("input", val)
}

  return (
    <div className={isPredictionPage ? "prediction-container" : "market-container"}>
    {isPredictionPage &&
      <div className="prediction-toggle-buttons" style={{ display: "flex", gap: "10px", justifyContent: "center" }} >
        <button className="prediction-toggle-button" onClick={() => updateViewData("LiquidityView", "addLiquidity")}>
          addLiquidity
        </button>
        <button className="prediction-toggle-button" onClick={() => updateViewData("LiquidityView", "removeLiquidity")} >
          removeLiquidity
        </button>
      </div>
      }

      {!loading && (viewData?.LiquidityView === "addLiquidity" || !viewData?.LiquidityView) && (

        <div className="prediction-add-section">
        {isPredictionPage && <>
        <div className="prediction-info">
        <span className="prediction-info-item-flex">Fees TVL Locked: {tvlBalance}</span>
        <span className="prediction-info-item-flex">
          <b>There is a total Fee of {fpFees}%  for all trades on this event </b>{/*min {"0"} ${collateralInfo?.name}*/}
        </span>
        <i className="prediction-info-item-flex">
          Liquidity providers will be eligible for {100*(feesSplit?.user/1000)}% of all fees <br/>
          Fees donated to Sommi's address: {100*(feesSplit?.donate/1000)}%
        </i>
      </div>
      </>
    }

      {(!tvlBalance || tvlBalance === 0) && (
        <>
          <label htmlFor="distribution-hint" className="betcard-label">
            I want to provide liquidity based on a
            <select className="betcard-select" value={viewData.selectedHint} name="selectedHint"
            onChange={(e) => {updateViewData("selectedHint", e.target.value);   vInputChange( [[e.target.value, changeDHint() ], e.target.name] ); }} >
              {dHintSelection.map((hint,ind) => (
                <option key={ind} value={hint}>
                  {hint}
                </option>
              ))}
            </select>
            chance that the answer will be
            <select className="betcard-select" value={viewData.selectedOutcome} name="selectedOutcomeIndex"
            onChange={(e) => {updateViewData("selectedOutcome", e.target.value);  vInputChange( [e.target.value, e.target.name] ); }} >
              {options?.map((option, ind) => (
                <option key={ind} value={ind}>
                  {option}
                </option>
              ))}
            </select>
          </label>

          {/* <div className="betcard-multi-rows"> //TODO Logic for returning options/outcomes
            {options?.map((option, row) => {
              if (
                activeController === id &&
                row !== viewData?.userMultiPartSelection
              ) {
                return null;
              }
              return (
                <div className="betcard-multi-row" key={"card" + row}>
                  <b className="bet_option_text">{option}</b>
                  <i>
                    <p className="bet_option_text">
                      {betPercent?.[row] && betPercent?.[row]}%
                    </p>
                  </i>
                </div>
              );
            })}
          </div> */}
          {isPredictionPage &&
          <>
          <div className="prediction-info">
            <span className="prediction-info-item">
              By selecting <b>{viewData?.selectedHint}</b> you could receive{" "}
            You are taking positions {"evenly"} accross all pools. This value
              does not take into account the tokens you will receive from
              trading fees on this event.
            </span>
          </div>
        </>}
          <div className="betcard-select-container">
            <label htmlFor="token-select" className="betcard-label">
              Select a Token
            </label>
            <select id="token-select" className="betcard-select"  name="selectCollateral" defaultValue={vSelectedLP}
            onChange={(e) => {vInputChange( [e.target.value, e.target.name] ); }} >
              {tokenlist.map((token, index) => (
                <option key={token.value} value={token.value}>
                  {token.name}
                </option>
              ))}
            </select>
          </div>
          </>
)}
{tvlBalance > 0 && (
  <HorizontalBanner data = {{"Wallet": userWTokenBalance, Token: collateralInfo?.name}} small={!isPredictionPage} />
)}

          <div className="prediction-submit-section" style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", }} >

            <InputHandle />
            <button
              onClick={() =>{
               changeDHint();  callHandleSubmit(viewData, qId, question, "addLiquidity"); 
              }}
              className="button bet_btn_sub bet_btn"
            >
              {buttonText}
            </button>
          </div>
        </div>
      )}

      {!loading && viewData?.LiquidityView === "removeLiquidity" && (
  <div  style={{display: 'flex', flexDirection: 'column'}} className="prediction-info">
    {/* <span className="prediction-info-item-flex">My Total Positions: {userCTBalance} {viewData.selectedLPToken}</span>
    <span className="prediction-info-item-flex">My LP share: {userLPShare}</span>
    <span className="prediction-info-item-flex">Liquidity Fees: <b className="glow-text">{userLPFees} {viewData.selectedLPToken}</b></span> */}
    <HorizontalBanner  data={{Total: 1, 'LP-Share': 2, Fees: 100}} customStyling={{"Fees": "glow-text"}} small={!isPredictionPage}/>
    <div className="prediction-submit-section">
      <button className="button bet_btn bet_btn_sub" onClick={() => callHandleSubmit(viewData, qId, question, "claimLiquidity")}>
        Claim Fees
      </button>
        <PercentageInput min={1} max={userCTBalance} value={viewData?.input} setValue={setWithdrawValue} />
      <button className="button bet_btn bet_btn_sub" onClick={() => callHandleSubmit(viewData, qId, question, "removeLiquidity")}>
        Remove Liquidity
      </button>
    </div>
  </div>
)}
    </div>
  );
};

export { Liquidity };
