import React from 'react';
import { useGlobalState } from './context/GlobalStateProvider';

const Loading = (props) =>{
    const { loading } = useGlobalState();
    const {msg} = props
    if(loading){
    return(
        <div clas="container">
            <h1>{msg}</h1>
            <p>loading widget here!</p>
        </div>
    )}
}

export {Loading}