import { ethers } from "ethers";
import PaginatedQuestions from "../../../../config/abi/prediction/PaginateQuestionsABI.json";
import {PageHelper}  from "./pageHelper";

async function GetQsByTradingState(tradingState, offset, limit, contractAddress, provider) {
    let result = [null, null ];

    try {
        // Initialize the contract instance
        const paginatedQuestionsContract = new ethers.Contract(contractAddress, PaginatedQuestions.abi, provider);
        // Call the contract's function to get questions by trading state
        const [page1, page2] = await paginatedQuestionsContract.getQuestionsByTradingState(tradingState, offset, limit);
        console.log('Retrieved questions by trading state:', page1, page2);
        // Store the results
        result = PageHelper(page1,page2)
        //console.log('Retrieved questions by trading state:', result);

    } catch (error) {
        console.error('Error retrieving questions by trading state:', error);
    }

    return result;
}

export { GetQsByTradingState };
