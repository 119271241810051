import { ethers } from "ethers";
import LotteryABI from "../../config/abi/NFTLotteryABI.json"; // Adjust the path if necessary

async function CurrentGame(lotteryAddress, provider, signer) {
    let currentGameId = 0; // Default value in case of an error

    try {
        // Initialize the Lottery Contract with the provided address, ABI, provider, and signer
        let LotteryContract = new ethers.Contract(lotteryAddress, LotteryABI.abi, provider).connect(signer);
        currentGameId = await LotteryContract.currentGame();
        currentGameId = Number(currentGameId)
        //console.log("response Game ID:", currentGameId);

    } catch (error) {
        console.error("Error fetching current game ID:", error);

    }

    // Log the current game ID for debugging purposes
    //console.log("Current Game ID:", currentGameId);

    // Return the current game ID (it could be used in other components)
    return currentGameId;
}

export { CurrentGame };
