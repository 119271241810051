import { ethers } from "ethers";
import PaginatedQuestions from "../../../../config/abi/prediction/PaginateQuestionsABI.json";
import { getErrorMessage } from '../../../errorHandler';

async function UpdateQuestion(contractAddress, id, resolutionDate, tradingState, sourceId, categoryId, eventAddress,  provider, signer) {
    // Initialize result array with success status and message
    let updateResult = [true, ''];
    console.log('update data test',resolutionDate, tradingState, sourceId, categoryId, eventAddress)

    try {
        console.log('Updating question ID:', id);
        // Initialize contract instance
        const paginatedQuestionsContract = new ethers.Contract(contractAddress, PaginatedQuestions.abi, provider).connect(signer);
        const tx = await paginatedQuestionsContract.updateQuestion(id, resolutionDate, tradingState, sourceId, categoryId, eventAddress);
        console.log('Question update transaction sent:', tx.hash);
        // Wait for transaction confirmation
        await tx.wait();
        updateResult = [true, tx.hash]; // Set result to success with transaction hash

    } catch (error) {
        console.error('Error updating question:', error);
        // Get a readable error message and set result to failure
        const errorMsg = getErrorMessage(error);
        updateResult = [false, errorMsg];
    }

    return updateResult;
}

export { UpdateQuestion };
