import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";

// Function to get the number of outcome slots for a given condition ID
async function SlotCount(conditionalAddress, conditionId, provider) {
    var slotCount = 0
    try {

        console.log('Fetching outcome slot count for condition ID:', conditionId);

        // Instantiate the contract
        const conditionalTokenContract = new ethers.Contract(conditionalAddress, ConditionalTokenABI.abi, provider);
        slotCount = await conditionalTokenContract.getOutcomeSlotCount(conditionId);

        console.log('Outcome Slot Count:', slotCount.toString());


    } catch (error) {
        console.error('Error fetching outcome slot count:', error);
        //throw error;
    }
    return slotCount;
}

export { SlotCount };
