import { ethers } from "ethers";
//import
import FPMMFactoryABI from "../../config/abi/prediction/FPMMFactoryABI.json";
import { getErrorMessage } from '../errorHandler';

// Function to create a Fixed Product Market Maker
async function CreateFPMM( factoryAddress, conditionalTokens, collateralToken, conditionIds ,fee,  provider, signer ) {

  var creation = [true, ''];

  try {
    console.log('Creating Fixed Product Market Maker with parameters:',
    'Conditional Tokens:', conditionalTokens,
    'Collateral Token:', collateralToken,'Condition IDs:', conditionIds,
    'Fee:', fee)

    // Connect to the Fixed Product Market Maker Factory contract
    let factoryContract = new ethers.Contract(factoryAddress, FPMMFactoryABI.abi, provider).connect(signer);

    // Send the transaction to create the Fixed Product Market Maker
    let tx = await factoryContract.createFixedProductMarketMaker(conditionalTokens, collateralToken, conditionIds, fee.toString() );

    // Log the transaction hash and wait for it to be confirmed
    console.log('Transaction sent:', tx.hash);
    creation = [true, tx.hash];
    let result = await tx.wait()
    console.log('transaction events', result?.logs)
    console.log('transaction events trial', result?.logs?.[0]?.args?.[1]) // we need to keep this. is the new contract address
    creation = [true, result?.logs?.[0]?.args?.[1] ];

  } catch (error) {
    console.error('Error creating Fixed Product Market Maker:', error);
    var errorMsg = getErrorMessage(error);
    creation = [false, errorMsg];
  }

  return creation;
}

export { CreateFPMM };
