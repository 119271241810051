import React, { Component } from 'react';
import Spinner from './Spinner';
import './Slotmachine.css'
import { ethers } from "ethers";
import { Allowance } from '../../../modules/allowance'
import { useGlobalState } from '../../../components/context/GlobalStateProvider';
import MetamaskButton from './MetamaskButton/MetamaskButton'
import {Approv} from '../../../modules/actions/approve';
/* abi */
import  SlotMachine from "../../../config/abi/Slotmachine3.json";
import  BUSDabi from "../../../config/abi/BUSDABI.json";

import { getSlotMachineEggpAddress, getSlotMachineCakeAddress, getSlotTokenEprtAddress, getSlotTokenEggpAddress,getSlotTokenCakeAddress} from "./addressHelpers";


import tokensAddrs from '../../../config/contracts/tokens.json'
import contractAddrs from '../../../config/contracts/contracts.json'




/* connection variables */
var provider = undefined;
var signer = undefined;
var connected = false;
var slotMachineContract = undefined;
var BUSDcontract = undefined;

/* contract variables */
let slotMachineAddress = '0x2b4aF6328410e22204c38916719A82a459b251C8' //contractAddrs.slots[56] //getSlotMachineEggpAddress();
let tokenAddress =  tokensAddrs.Friendship[56] // getSlotTokenEggpAddress();

try {
  let mysigner = this.props.walletInfo.signer
  let wallet =  mysigner.getAddress()
} catch (e) {
  console.log('walet err',e);
  let wallet = 'blanks'
}

function RepeatButton(props) {
    return (
      <button
        aria-label='Play again.'
        id='repeatButton'
        onClick={props.onClick}>
            Spin
      </button>
    );
}

function WinningSound() {
    return (
    <audio autoplay="autoplay" className="player" preload="false">
      <source src="https://andyhoffman.codes/random-assets/img/slots/winning_slot.wav" />
    </audio>
    );
}

class Slotmachine extends Component {
    static loser = [
        'Not quite',
        'Stop gambling',
        'Hey, you lost!',
        'Ouch! I felt that',
        'Don\'t beat yourself up',
        'There goes the college fund',
        'I have a cat. You have a loss',
        'You\'re awesome at losing',
        'Coding is hard',
        'Don\'t hate the coder'
    ];



    static matches = [];

    constructor(props) {

        super(props);
        this.state = {
            winner: null,
            currentBet: 0.2,
            increment: 1,
            buttonText: "Connect wallet.",
            currentSlot:'spinner-div-1',
            gameToken:'Friendship',

        }

        this.finishHandler = this.finishHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.addBet = this.addBet.bind(this);
        this.reduceBet = this.reduceBet.bind(this);
        this.handleBetCLaim = this.handleBetCLaim.bind(this);
    }



    async handleClick() {
        let bigNumberBet = await (ethers.formatEther(this.state.currentBet.toString()));

        let getfee =  await slotMachineContract.getfee();
        getfee = ethers.formatEther(getfee).toString();
        let overrides = {value: ethers.formatEther(getfee) };
        let tx = await slotMachineContract.playSlot(bigNumberBet,overrides);
        let spinning = false;

        //let Wallet = Getwallet()

        slotMachineContract.on("RNG", (value) => {
            console.log(value.toNumber());
            if(!spinning &&  value.toNumber().toString().length == 3){
                spinning = true;
                let retrievedNumber = value.toNumber().toString();
                console.log(retrievedNumber);

                let n1 = parseInt(retrievedNumber.substring(0,1));
                let n2 = parseInt(retrievedNumber.substring(1,2));
                let n3 = parseInt(retrievedNumber.substring(2,3));

                console.log(n1)
                console.log(n2)
                console.log(n3)

                this.setState({... {winner: null} });
                this.emptyArray();
                if(this.state.connected){
                    this._child1.forceUpdateHandler(n1);
                    this._child2.forceUpdateHandler(n2);
                    this._child3.forceUpdateHandler(n3);
                }

                this.getBUSDBalance();
                this.getSlotViews();
                let wallet = signer.getAddress()
                this.getWinner(wallet)
            }

        });



        /*
        this.setState({... {winner: null} });
        this.emptyArray();
        if(this.state.connected){
            this._child1.forceUpdateHandler((Math.floor((Math.random()*9))));
            this._child2.forceUpdateHandler((Math.floor((Math.random()*9))));
            this._child3.forceUpdateHandler((Math.floor((Math.random()*9))));
        }
        */
    }
    finishHandler(value) {
        Slotmachine.matches.push(value);

        if (Slotmachine.matches.length === 3) {
            const { winner } = this.state;
            const first = Slotmachine.matches[0];
            let results = Slotmachine.matches.every(match => match === first)
            this.setState({ winner: results });
        }
    }

    emptyArray() {
        Slotmachine.matches = [];
    }

    //go up and down in the bet dom
    addBet(){
        let newBet = parseFloat(this.state.currentBet) + parseFloat(this.state.increment)
        newBet = Math.round(newBet * 10) /10
        this.setState({currentBet: newBet})
    }

    reduceBet(){
        if(this.state.currentBet > 0){
            this.setState({currentBet: Math.round((this.state.currentBet - parseFloat(this.state.increment)) * 10) /10})
        }
    }

    handleBetChange(e){
        this.setState({currentBet: e.target.value})
        this.handleClick()
    }


    handleContractChange(c){
      this.setState({gameToken: c.target.value})

      if (c.target.value == 'EGGP'){
        this.setState({currentToken:'spinner-div-1'})
        slotMachineAddress = getSlotMachineEggpAddress();
        tokenAddress = getSlotTokenEggpAddress();
        this.setState({increment : 1.0})
      }
      if (c.target.value == 'CAKE'){
        this.setState({currentToken:'spinner-div-2'})
        slotMachineAddress = getSlotMachineCakeAddress();
        tokenAddress = getSlotTokenCakeAddress();
        this.setState({increment : 0.1})
      }
        //slotMachineContract = new ethers.Contract(slotMachineAddress, SlotMachine.abi);
        //BUSDcontract = (new ethers.Contract(tokenAddress, BUSDabi.abi));
        slotMachineContract = new ethers.Contract(slotMachineAddress, SlotMachine.abi, provider).connect(signer);
        BUSDcontract = new ethers.Contract(tokenAddress, BUSDabi.abi, provider).connect(signer);
        this.getBUSDBalance();
        this.getSlotViews();
        let wallet = signer.getAddress()
        this.getWinner(wallet)
    }

    async handleBetCLaim(){
        let wallet =  this.props.walletInfo.signer.getAddress() //await signer.getAddress()
        let claim = await slotMachineContract.claimPrizes(wallet);
        this.getWinner(wallet)
    }

    //remove
    async connectMetamask(){
        if (!this.connected){///if(!this.connected){
            await window.ethereum.enable()
            //provider = new ethers.providers.Web3Provider(window.ethereum)
            //signer = provider.getSigner();
            console.log('this.props.walletInfo.signer',this.props.walletInfo.signer)
            var provider =  this.props.walletInfo.provider //await new ethers.BrowserProvider(window.ethereum);
            var signer =  this.props.walletInfo.signer //await provider.getSigner();

            let wallet = await signer.getAddress()


            this.setState({connected: true});
            this.setState({buttonText :"Approve"});
            try {
              slotMachineContract = new ethers.Contract(slotMachineAddress, SlotMachine.abi, provider).connect(signer);
              BUSDcontract = new ethers.Contract(tokenAddress, BUSDabi.abi, provider).connect(signer);
              this.getBUSDBalance();
              this.getSlotViews();
              //this.getWinner(wallet);
            }
            catch (e){
              console.log("meta var:",tokenAddress, slotMachineAddress )
              console.log("meta error:",e)

            }

            //slotMachineContract = new ethers.Contract(slotMachineAddress, SlotMachine.abi);
            //BUSDcontract = (new ethers.Contract(tokenAddress, BUSDabi.abi));


        }
    }

//remove
    async approve() {
        if(!this.state.approved){
            //var inp = 100000000;
            //var aproval = Approve(BUSDcontract, slotMachineAddress, inp, this.props.walletInfo.provider, this.props.walletInfo.signer);
            await BUSDcontract.approve(slotMachineAddress, 100000000);
            this.setState({buttonText : "Approved", approved: true});
        }
    }
/*
        if (!walletInfo) {
          setInputErr(errorString)
       } else {
          try {
            var inp = input * 10 ** 9
            console.log('input balance;', inp)
            var aproval = Approve( BUSDcontract , slotMachineAddress, inp , walletInfo.provider, walletInfo.signer)
          } catch (e) {
            console.log("aprove function failed:", e)
          }
        }
      }*/


// remove
    async getBUSDBalance( ){
          let mysigner = this.props.walletInfo.signer
          let wallet =  mysigner.getAddress()

          let balance  = await BUSDcontract.balanceOf(wallet);
          balance = Math.round(ethers.formatEther(balance)* 100) / 100;
          console.log("balance", balance)
          this.setState({balance: balance});
    }


    async getWinner(wallet){
      let checkWinner =  await slotMachineContract.checkWinner(0, wallet);
      let winCounter = await slotMachineContract.winCounter();
      winCounter = ethers.formatEther(winCounter)*10**18;
      checkWinner = ethers.formatEther(checkWinner);
      this.setState({checkWinner: checkWinner});
      this.setState({winCounter: winCounter});
    }

    //write
    async getSlotViews(){

//console.log('getSlotViews',slotMachineContract)

var provider =  this.props.walletInfo.provider //await new ethers.BrowserProvider(window.ethereum);
var signer =  this.props.walletInfo.signer
      var slotsId = null
      console.log('slotviews','here', slotMachineContract)
        slotMachineContract = new ethers.Contract(slotMachineAddress, SlotMachine.abi, provider);
        slotsId = 1 //await slotMachineContract.slotsId();
        console.log('slotviews',slotsId)
        let minBet = await slotMachineContract.minBet();

        let maxBet = await slotMachineContract.maxBet();
        console.log('minBet',minBet)
        console.log('maxBet',maxBet)
        let slotBalance = 0//await slotMachineContract.slotBalance();
console.log('slotBalance',slotBalance)

        let mainPrizePot = await slotMachineContract.mainPrizePot();
console.log('mainPrizePot',mainPrizePot)


        slotsId = Math.round ((ethers.formatEther(slotsId))* 10**18);
        minBet = parseInt(minBet) //ethers.formatEther(minBet);
        maxBet = parseInt(maxBet) //ethers.formatEther(maxBet);
        slotBalance = Math.round(ethers.formatEther(slotBalance)*100)/100;
        mainPrizePot = parseInt(maxBet) //ethers.formatEther(mainPrizePot);

        console.log('maxBet2',maxBet)
        this.setState({slotsId: slotsId});
        this.setState({minBet: minBet});
        this.setState({maxBet: maxBet});
        this.setState({slotBalance: slotBalance});
        this.setState({mainPrizePot: mainPrizePot});
        this.setState({currentBet:this.state.minBet})
        //this.setState({mainPrizeToken: mainPrizeToken});
    }
    render() {
        const  winner  = this.state.winner;

        let repeatButton = null;
        let winningSound = null;


        if(this.state.connected){
            if (winner !== null) {
                repeatButton = <RepeatButton onClick={this.handleClick} />
            }else{
                repeatButton = <RepeatButton disabled />
            }
        }

        if (winner) {
            winningSound = <WinningSound />
        }

        return (
            <div className="slotsContainer">
                <div className="wrapper">
                    <div className="slotsheader"></div>
                    <div className="slotSpan">
                        <div className="currencyButton">
                                <div className="eggp"><button onClick={(c) => {this.handleContractChange(c)}} value={"EGGP"}>BET EGGP</button></div>
                                <div className="cake"><button onClick={(c) => {this.handleContractChange(c)}} value={"CAKE"}>BET CAKE</button></div>
                        </div>
                    </div>
                    <div className={"gameDiv "+this.state.gameToken}>
                        <div className={"top-ui " +this.state.gameToken}>
                            <div className="wallet-data">
                                <span>Wallet ({this.state.gameToken})  </span>
                                <br></br>
                                <span>{this.state.balance}</span>

                                <h3 className={this.state.gameToken}>Slot Balance: <br></br>{this.state.slotBalance}</h3>
                            </div>
                            <div className="buttons">
                                <button id="metamaskButton" onClick={() => this.state.connected ? this.approve() :  this.connectMetamask()}>
                                    <img src="{metamaskLogo}" width="20px"  /> {this.state.buttonText}
                                </button>
                                {repeatButton}
                            </div>

                        </div>

                        <div className="slotMachineDiv">
                            <div className={"spinner-div-1 " + this.state.gameToken}>
                                <Spinner className={`spinner`} onFinish={this.finishHandler} ref={(child) => { this._child1 = child; }} timer="2400" />
                                <Spinner className={`spinner`} onFinish={this.finishHandler} ref={(child) => { this._child2 = child; }} timer="3400" />
                                <Spinner className={`spinner`} onFinish={this.finishHandler} ref={(child) => { this._child3 = child; }} timer="4200" />
                            </div>
                        </div>
                        <div className={"fields " + this.state.gameToken}>
                            <div>
                                <h3 className="winner">Max Win: {this.state.mainPrizePot}</h3>
                                <h3>MaxBet: {this.state.maxBet}</h3>
                                <h3>MinBet: {this.state.minBet}</h3>
                                <br/>
                            </div>
                            <div>
                                <h3 className="winner">Win Count: {this.state.winCounter}</h3>
                                <h3>Game Round: {this.state.slotsId}</h3>
                                <h3>My Winnings: {this.state.checkWinner}</h3>
                                <br/>
                            </div>
                        </div>
                        <div className={"bottom-ui " + this.state.gameToken}>
                            <button onClick={(e) => {this.handleBetChange(e)}} value={this.state.minBet}>Min</button>
                            <div class="inputRow">
                                <button onClick={this.reduceBet} >-</button><input type="number" onChange={(e) => {this.handleBetChange(e)}} placeholder="Your bet here" step="0.1" value={this.state.currentBet} ></input><button onClick={this.addBet}>+</button>
                            </div>

                            <button onClick={(e) => {this.handleBetChange(e)}} value={this.state.maxBet}>Max</button>
                            <span><button onClick={this.handleBetCLaim}>
                                Claim
                            </button></span>
                        </div>

                    </div>
                    {winningSound}

                </div>
            </div>

        );
    }
}
// */
export { Slotmachine }
