import React, {useState} from 'react'

const FAQ = () =>{
  const [show, setShow] = useState([false,false,false,false,false,false,false])

  const handleClick = (i) =>{
    setShow((prev=> {
      const updatedShow = [...prev];
      updatedShow[i] = !updatedShow[i];
      return updatedShow;}))
  };

    return (
      <section class="newsletter section">
        <div class="container-sm" id="FAQs">
          <script>
            </script>
          <div class="newsletter-inner section-inner">
            <div class="newsletter-header text-center">
              <h2 class="section-title mt-0">Frequently Asked Questions</h2>
                <div class="faq-one">
                  <h3 onClick={()=>handleClick(0)} class="faq-page">Is this token made by YourFriendSommi?</h3>
                    {show[0] ? 
                    <div class="faq-body">
                      <p>No this token is a Fan made purely for fun and speculation. Sommi 100% does not endorse this token at the time of its inception.
					              It's better to think of Token as a social experiment. Since it's unlikely Sommmi will make his own token,
					              we created something to pay homage his comunity and his hard work.
                      </p>
                    </div> : null }
                      </div>
                        <hr class="hr-line"/>
                          <div class="faq-two" >
                            <h3 onClick={()=>handleClick(1)} class="faq-page" >Does Sommi Fan Token have hidden Fees?</h3>
                            {show[1] ? 
                              <div class="faq-body">
                                <p>Sommi Fan token will have an buy/sell transaction tax to help with sustaining development of the project.<br/><br/>
                                <b>26% goes to Development, Dev Fees: 0xb8bF8D8cE14F092A21dA559ae2a4eD84EFF64b09 <br/><br/>
                                   26% is added to the LP <br/><br/>
                                   10% goes to Community Activities 0xfbD806e9Fb2b7bB353EdDE8725257f603A4bBD5C:: <br/><br/>
                                   18% goes to Community Outreach/Marketing: 0xCcd1e792752FbFfdE0E8bF1dE1e7f473c9e33f8E <br/><br/></b>
                                   A % of the Community Outreach <b>we will auto donate to yourFriendSommi.eth</b> because he's awesome.
                                   We donnate to Sommi in eth so that he isn't forced to dump Token on the community.
                                </p>
                                </div> : null }
                            </div>
                            <hr class="hr-line"/>
                            <div class="faq-three">
                                
                            <h3 onClick={()=>handleClick(2)} class="faq-page">Who runs Sommi Fan Token?</h3>
                              {show[2] ?   
                                <div class="faq-body">
                                    <p>Sommi Fan Token is a comunity lead project that aims opperate as a DAO structure. As time goes the team will decentralize as much as is possible with the access we have to
                                      modern technologies. The door is always open to join Sommi Fan Token and put your skills to use. If you have skills you think are useful, please contact us.

                                    </p>
                                </div> : null }
                            </div>
                            <div class="faq-four">
                                
                            <h3 onClick={()=>handleClick(3)} class="faq-page">What are the token launch details?</h3>
                              {show[3] ?     
                                <div class="faq-body">
                                    <p>Sommi Fan Token will do a stealth launch on Ethereum Mainnet. There wont be an ICO, IDO or initial offering for the token from private investors.
                                      The team will set liquidity with Ethereum and allow for an organic value of the TOKEN after announcement.
                                    </p>
                                </div> : null }
                            </div>
                            <div class="faq-five">
                                
                            <h3 onClick={()=>handleClick(4)} class="faq-page">Am I allowed to Buy Sommi Fan Token?</h3>
                            {show[4] ?                                    
                                <div class="faq-body">
                                    <p>Sommi Fan Token will be deployed on a decentralised blockchain and although it will be free to access via smart contracts, your country may prohbit access.
                                      Please check your local laws and financial regulators to see if you are prohibited from engaging with this project, its token or its features.
                                    </p>
                                </div> : null }
                            </div>
                            <div class="faq-six">
                                
                            <h3 onClick={()=>handleClick(5)} class="faq-page"> Will I make lots of money from Sommi Fan Token?</h3>
                              {show[5] ?       
                                <div class="faq-body">
                                    <p>Crypto assets are EXTREMELY VOLATILE, trading and investing into them may result in a loss of all your money.
                                      This web application has been made as a fun, zero-utility experiment, should be consider a speculative game at most.
                                      Any smart contracts or webapps associated with the project will be announced through our official channels.  Be aware of scammers, and aovid them!
                                    </p>
                                </div> : null }
                            </div>
                            <div class="faq-seven">
                                
                            <h3 onClick={()=>handleClick(6)} class="faq-page">Am I allowed to take part in the referral bot processs?</h3>
                              {show[6] ?       
                                <div class="faq-body">
                                    <p>Our referral program is NOT OPEN to the UK or similar countries that have strict rules on crypto-currency referral marketing. In Other words you are expressly prohibited in joining our referral.
                                      If you are unsure, before engaging check your local financial regulators rules.You must do this before you or take part in the community activities, or speak to a professional financial advisor.
                                    </p>
                                </div> : null }
                            </div>
                        </div>
                      </div>
                    </div>
            </section>    
    )
}
export {FAQ};