import React, { useState, useCallback  } from "react";
import { useLocation } from "react-router-dom";
import { HorizontalBanner } from "../../HorizontalBanner";


//import { GetEmbed } from '../../../modules/getEmbed';

const Multi = ({children, ...props}) => {
  const { qId, activeController, handleSubmit, viewData, source_name, resolution_date_time, source_view, resolution,source_embed,source_url, updateViewData,
    question, InputHandle, InputTest, setActiveController, betPercent, options, outcomeSelect,view, vInputChange, vCalcBuyShares, vBuySellInfo, handleInputChange,
  vInValue,vCalcSellShares, userFPMMAllowance, fpmmApproved, userWTokenBalance, fpFees, collateralInfo, vEventAddress, vBatchPositionInfo} = props;

  const location = useLocation()
  const isPredictionPage = location?.pathname === "/dapp/prediction"
  let inType = 'Tokens'
  let localBuySell = vCalcSellShares
  let buttonText = 'Approve + SELL'

  if (fpmmApproved){
    buttonText = 'SELL'
  }



  if (view?.toLowerCase() === "buy"){
    inType = 'Shares'
    localBuySell = vCalcBuyShares
    buttonText = 'Approve + BUY'
    console.log('userFPMMAllowance, vInValue', userFPMMAllowance, vInValue*10**9 )
    if (userFPMMAllowance > vInValue*10**9 && vBatchPositionInfo?.aFPMM === vEventAddress ){ //change to decimals
      buttonText = 'BUY'
    }
  }
  let bet = ['-','-','-','-','-','-']//vBatchPositionInfo?.betPercents
  if(vBatchPositionInfo?.betPercents && vBatchPositionInfo?.aFPMM === vEventAddress){
    bet = vBatchPositionInfo?.betPercents
    console.log('vBatchPositionInfo?.betPercents',vBatchPositionInfo?.betPercents)
  }
//vBatchPositionInfo?.betPercents

 function changeBet( betArray ) {

   let localBet = bet[betArray[1]]
    if (betArray[0]===1 && viewData?.userMultiPartSelection==[betArray[1]] ){
      localBet = 100-bet[betArray[1]]
    }

    //console.log('Bet Array update 2:',betArray )

    return localBet

 }



  return (
    <div className={isPredictionPage ? "prediction-container" : "market-container"}>
     {isPredictionPage && <>
     <div className="prediction-header">
        <h3> {view?.toLowerCase() === "buy" ? "Buy" : "Sell"}</h3>
      </div>
        <div>
        </div>


      </>}


    <div className={isPredictionPage ? "betcard-inner-prediction-page prediction-add-section" : "betcard-inner"}>
      { options?.map((option, row) => {
        if(!isPredictionPage && activeController === qId && row!==viewData?.userMultiPartSelection){return null}
        return(
        <div className="betcard-multi-row" key={"card" + row}>
          <b className={isPredictionPage ? "bet_option_text" : "bet_option_text_small"}>{option}</b>
          <i>
            <p className={isPredictionPage ? "bet_option_text" : "bet_option_text_small"}>
              {changeBet([viewData?.userBinaryPartSelection,row])/*betPercent?.[row] && betPercent?.[row]*/}%
            </p>
          </i>
          <div className="bet-options">
            <button
              onClick={(e) => {outcomeSelect(0, row, activeController, setActiveController, viewData, updateViewData, qId);
              vInputChange( [[0,row], "oSelector"] ); changeBet([0,row]); vInputChange( [ [vEventAddress] , "vEventAddress"]); }}
              className={options?.[row] && viewData?.userMultiPartSelection===row && viewData?.userBinaryPartSelection===0 ? "button bet-option green" : "button bet-option" }
            >
              Yes
            </button>
            <button
              onClick={(e) => {outcomeSelect(1, row, activeController, setActiveController, viewData, updateViewData, qId);
              vInputChange( [[1,row], "oSelector"] ); changeBet([1,row]); vInputChange( [ [vEventAddress] , "vEventAddress"]); }}
              className={options?.[row] && viewData?.userMultiPartSelection===row  && viewData?.userBinaryPartSelection===1? "button bet-option red" : "button bet-option" }
            >
              No
            </button>
          </div>
        </div>
      )}
    )}
    </div>

{/* Buy: To calculate how many outcome tokens the user can buy for a given investment amount in the specified outcome pool.
Sell: To calculate how many outcome tokens the user needs to sell to receive a specific amount of collateral.<br/><br/>


      {inType}: {localBuySell} ---------
      Current Value: {vInValue} ---------
      Wallet:{userWTokenBalance} $DynamicTokename */}

      {activeController === qId && <>
      <HorizontalBanner data={{[inType]: localBuySell/(10**9),  Wallet: userWTokenBalance, Token: collateralInfo?.name}} small={!isPredictionPage}/>
      <InputHandle /></>
      }
      <div id={isPredictionPage ?  "prediction_submit_button_wrapper" : "market_submit_button_wrapper"} >
        <button onClick={() => handleSubmit( viewData, qId, question, view ) } className="button bet_btn bet_btn_sub" >
        {buttonText}
        </button>
      </div>
      {isPredictionPage && (
      <div className="prediction-info">
          <span className="prediction-info-item-flex" ><b>Source:</b></span> <span><i>{source_name}</i></span>
          <span className="prediction-info-item-flex" ><b>Resolving:</b></span> <span><i>{resolution_date_time}</i></span>
          <span className="prediction-info-item-flex" ><b>Details:</b></span> <span><i>{source_view}</i></span>
          <span className="prediction-info-item-flex" ><b>Res-Method:</b></span> <span><i>{resolution}</i></span>
          {children}
        </div>
      )}
    </div>

  )
};

export {Multi};
