import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json"; // Fixed Product Market Maker ABI
import { getErrorMessage } from '../../../errorHandler';

async function RemoveFunding(fpmmAddress, amountsToRemove,  provider, signer) {
    let removeFundingResult = [true, ''];

    try {
        console.log('Initiating funding removal with amounts:', amountsToRemove);
        const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider).connect(signer);

        // Call removeFunding method from the contract
        const tx = await fpmmContract.removeFunding(amountsToRemove);

        console.log('Funding removal transaction sent:', tx.hash);
        await tx.wait();
        removeFundingResult = [true, tx.hash];

    } catch (error) {
        console.error('Error removing funding:', error);
        const errorMsg = getErrorMessage(error);
        removeFundingResult = [false, errorMsg];
    }

    return removeFundingResult;
}

export { RemoveFunding };
