export default {
  machineEggp: {
    56: '0x6b313cE968b502d34696eb15a8d8d5F85816DB87', //'0xbe4c35C1E37cd1cca9D9EFb5684c2E585696AFB5',
    97: '0xeBA712B607357F0FCC0cE90AEF1a13EFfc00c3D4'//'0xeBA712B607357F0FCC0cE90AEF1a13EFfc00c3D4'
  },
  machineCake: {
    56: '0xF89894aC3376518161B67F545cc7a60aa1dbb659',
    97: '0x528D966413cC8f1AEcE61302CC04c99B507990fC'//'0x528D966413cC8f1AEcE61302CC04c99B507990fC'
  },
  tokenEprt: {
    56: '0x21adB1c644663069e83059AC3f9d9Ca1133D29e4',
    97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee'
  },
  tokenEggp: {
    56: '0x21adB1c644663069e83059AC3f9d9Ca1133D29e4', //'0x21adB1c644663069e83059AC3f9d9Ca1133D29e4',
    97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee' //'0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee'
  },
  tokenCake: {
    56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee'//'0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee'
  }
}
