import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";
import { getErrorMessage } from '../../../errorHandler';

// Function to report payouts for a condition
async function ReportPayouts(conditionalAddress, questionId, payouts, provider, signer) {
    let reportResult = [true, ''];

    try {
        //payouts = [10,50,0,0,2,30]
        console.log('Reporting payouts for questionId:', questionId, 'with payouts:', payouts);


        let conditionalTokenContract = new ethers.Contract(conditionalAddress, ConditionalTokenABI.abi, provider).connect(signer);
        let tx = await conditionalTokenContract.reportPayouts(
            questionId,   // Question ID associated with the condition
            payouts       // Array of payouts for each outcome slot
        );

        console.log('Payouts Report Transaction sent:', tx.hash);

        // Wait for transaction confirmation
        // const receipt = await tx.wait();
        reportResult = [true, tx.hash];

    } catch (error) {
        console.error('Error reporting payouts:', error);
        const errorMsg = getErrorMessage(error);
        reportResult = [false, errorMsg];
    }

    return reportResult;
}

export { ReportPayouts };
