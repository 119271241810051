import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";

// Function to check if an operator is approved for all assets of an owner
async function IsApproved(conditionalAddress, ownerAddress, operatorAddress, provider) {

    var isApproved = 'NA'
    try {
        console.log('Checking approval status for:', ownerAddress, operatorAddress);

        // Instantiate the contract
        const conditionalTokenContract = new ethers.Contract(conditionalAddress, ConditionalTokenABI.abi, provider);
        // Call the isApprovedForAll function
        isApproved = await conditionalTokenContract.isApprovedForAll(ownerAddress, operatorAddress);

        console.log('Is Approved:', isApproved);


    } catch (error) {
        console.error('Error checking approval status:', error);
        //throw error;
    }
    return isApproved;
}

export { IsApproved };
