import React, { memo, useEffect, useRef } from 'react';

const YouTubeEmbed = memo(({ url }) => {
  const iframeRef = useRef(null);

  // Extract video ID from URL
  const getVideoId = (url) => {
    if (!url || typeof url !== 'string') {
      return null;
    }
    const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|shorts)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  // Update iframe src only when the URL changes
  useEffect(() => {
    const videoId = getVideoId(url);
    if (iframeRef.current && videoId) {
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      // Only update the iframe src if it differs
      if (iframeRef.current.src !== embedUrl) {
        iframeRef.current.src = embedUrl;
      }
    }
  }, [url]);

  return (
    <iframe
      ref={iframeRef}
      className="you-frame"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  );
}, (prevProps, nextProps) => prevProps.url === nextProps.url);

export { YouTubeEmbed };
