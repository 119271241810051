import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";

// Function to get Payout Denominator
async function PayoutDenominator(conditionalAddress, conditionId, provider) {

    let payoutDenominator = '0';

    try {
        console.log('Fetching Payout Denominator for Condition ID:', conditionId);

        // Instantiate the contract
        let conditionalTokenContract = new ethers.Contract(conditionalAddress, ConditionalTokenABI.abi, provider);
        // Fetch the payout denominator for the specified condition
        payoutDenominator = await conditionalTokenContract.payoutDenominator(conditionId);

        console.log('Payout Denominator:', payoutDenominator.toString());

    } catch (error) {
        console.error('Error fetching Payout Denominator:', error);
        throw error;
    }

    return Number(payoutDenominator);
}

export { PayoutDenominator };
