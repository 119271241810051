import React, { useState } from "react";
import { PlusMinusInput } from "./PlusMinusInput";
const PercentageInput = (props) => {
  const {min, max, value, setValue, step=1} = props;

  // Handle setting the percentage of the maximum value (e.g., 100)
  const setPercentage = (percent, setValue) => {
    let val = Math.ceil((max * percent) / 100)
    setValue(val);
  };

  return (
    <div className="percentage-input-container">
      <PlusMinusInput
        type="number"
        className="percentage-input"
        value={value}
        step={step}
        onChange={(e) => setValue(Number(e.target.value))}
        setValue={setValue}
        min={min}
        max={max}
      />
      <div className="percentage-buttons">
        <button onClick={() => setPercentage(25,setValue)}>25%</button>
        <button onClick={() => setPercentage(50,setValue)}>50%</button>
        <button onClick={() => setPercentage(75,setValue)}>75%</button>
        <button onClick={() => setPercentage(100,setValue)}>100%</button>
      </div>
    </div>
  );
};

export {PercentageInput};
