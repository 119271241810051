import React, { useEffect, useState } from 'react';
import { Logos } from './'
import { useGlobalState } from '../../components/context/GlobalStateProvider';

const ChainSelect = () => {
    const { chainData, setChainData } = useGlobalState();
    const [open, setOpen] = useState(false);
    const [showString, setShowString] = useState()
    const [icon, setIcon] = useState();
    const [logoImages, setLogoImages] = useState()

    const handleClick = (i) => {
        var chainId = "0x" + (parseInt(i)).toString(16);
        setChainData({ ...chainData, chainId: chainId })
        setOpen(false)
    }
    return (
        <div className='chainSelect-anchor'>
                <div className='button chainSelect-button' onClick={() => { setOpen(prev => !prev) }}>
                    {icon ? <img className='chainSelect-img' src={icon && icon[1]} /> : null}
                    {showString ? <p>{icon && icon[2]}</p> : null}
                </div>
            <div className={open ? "container chainSelect chainSelect-menu" : "container chainSelect chainSelect-menu hidden"}>
                <Logos chainData={chainData} logoImages={logoImages} setLogoImages={setLogoImages} handleClick={handleClick} icon={icon} setIcon={setIcon} showString={showString} setShowString={setShowString} />
            </div>
        </div>
    )
}

export { ChainSelect };