import React, {useState, useEffect} from 'react'
import blueBlack from '../../assets/images/background/blue-black.jpg'
import { FPBalanceOf } from '../../modules/prediction/FPMarketMaker/views/balanceOf';
import { FPCollateralInfo } from '../../modules/prediction/FPMarketMaker/views/collateralInfo';
import { FPConditionIds } from '../../modules/prediction/FPMarketMaker/views/conditionIds';
import { FPCalcBuyAmount } from '../../modules/prediction/FPMarketMaker/views/calcBuyAmount';
import { FPCalcSellAmount } from '../../modules/prediction/FPMarketMaker/views/calcSellAmount';
import { GetQsByfilter } from '../../components/prediction/getQsByfilter';
import { CollectionId } from '../../modules/prediction/Conditional/views/collectionId';
import { PositionId } from '../../modules/prediction/Conditional/views/positionId';
import { SlotCount } from '../../modules/prediction/Conditional/views/slotCount';
import { ReportPayouts } from '../../modules/prediction/Conditional/actions/report';
import { AddFunding } from '../../modules/prediction/FPMarketMaker/actions/addFunding';
import { Buy } from '../../modules/prediction/FPMarketMaker/actions/buyShares';
import { IsApproved } from '../../modules/prediction/Conditional/views/isApproved';
import { Allowance } from '../../modules/allowance';
import { GetUrlString } from '../../modules/prediction/questionDB/views/getUrl';
import { getPredictionAddresses } from '../../modules/getPredictionAddresses';

import {TokenBalance} from '../../modules/tokens/views/tokenBalance';
import { CollateralWhitelist } from '../../modules/prediction/Whitelist/views/getCollateral';
import { GetPositionIds } from '../../modules/prediction/FPMarketMaker/views/getPositionIds';
import tokensAddrs from '../../config/contracts/tokens.json'
import { Prepare } from '../../modules/prediction/Conditional/actions/prepare';
import { useError } from '../../components/context/ErrorProvider';
import { useGlobalState } from '../../components/context/GlobalStateProvider';
import predictionAddrs from '../../config/contracts/prediction.json'
import { Grid } from '../../components/prediction/Grid';
import { WrongChain } from '../../components/WrongChain';
import { Pagination } from '../../components/Pagination';
import { BalanceOfBatch } from '../../modules/prediction/Conditional/views/balanceOfBatch';
import { TotalSupply } from '../../modules/prediction/FPMarketMaker/views/totalSupply';

const MarketsPage = () => {
  const { chainData , setChainData, walletInfo, isConnected } = useGlobalState();
  const [ tokenAddy , setTokenAddy ] = useState()
  const [ balanceOf, setBalanceOf ] = useState()
  const [ conditionId, setConditionId ] = useState()
  const [ collectionId, setCollectionId ] = useState()
  const [ collateralAddy, setCollateralAddy] = useState()
  const [ calcBuy, setCalcBuy] = useState()
  const [ calcSell, setCalcSell] = useState()
  const [ positionId, setPositionId ] = useState()
  const [ isApproved, setIsApproved ] = useState()
  const [ slotCount, setSlotCount ] = useState()
  const [ questId, setQuestId ] = useState()
  const [ oracleAddy, setOracleAddy ] = useState()
  const [ condId, setCondId ] = useState()
  const [ collectId, setCollectId ] = useState()
  const showPopUp = useError();
  const [ dHint, setDHint] = useState()
  const [ funding, setFunding] = useState()
  const [ tokenAmount, setTokenAmount] = useState()
  const [ qBaseUrl, setQBaseUrl] = useState()
  const [ indexNum, setIndexNum ] = useState(0)
  const [ decimals, setDecimals ] = useState(9)
  const [ apiTrigger, setApiTrigger] = useState(0)
  const [questionList, setQuestionList] = useState([])
  const [pagination, setPagination] = useState({ page: 1, resultsPerPage: 12 });
  const [gridView, setGridView] = useState(200)
  const [testValue, setTestValue] = useState()
  const [ inValue, setInValue ] = useState()
  const [ selectedIndex, setSelectedIndex] = useState(0)
  const [ activeFPMM, setActiveFPMM] = useState()
  const [ priceInfo, setPriceInfo] = useState()
  const [ localBetFee, setLocalBetFee] = useState(0)
  const [ selectedCollateral , setSelectedCollateral] = useState()
  const [ collateralInfo, setCollateralInfo] = useState()

  const [ userFPMMAllowance,setUserFPMMAllowance] = useState()
  const [ userWTokenBalance ,setUserWTokenBalance] = useState()
  const [ fpmmApproved, setFPMMApproved] = useState()
  const [ batchPositionInfo, setBatchPositionInfo ] = useState()
  const [ tvlBalance, setTVLBalance ] = useState()
  const [outcomesCount, setOutcomesCount] = useState()
  //set in grid const [qPageData, setQPageData] = useState( { pId:null, catId:null,  sourceId:null, tradingStateId:null, eventAddress:null, resolutionDate:null } )

  /*chain change flow, onChainChange triggered by wrongchain, this updates apiTrigger (count)
   which triggers use effect to fetch url (changed based on chain), after the url is set this triggers W3
   after w3 this updates questionlist and passes into paginate and grid with a slice
  */
  let nineList = ['0x900a033403556DB01b830DdDE80EbF69403f49dC','0x6A46d56F9Dd963cEAc7f60aD148d49219CC3b79f','0xc674B7Bed1D086584c8776C1B83013009cC294e7']

  function tokenNameByAddress(tokenlist, address) {
    const token = tokenlist.find(token => token.value.toLowerCase() === address.toLowerCase());
    return token ? token.name : null; // Return the name if found, otherwise null
  }

  let vInputChange = ( test ) => {

    //console.log('market vinput', test)
    let viewInput = test[1]
    switch(viewInput) {

      case "buyTokensIn":
        console.log("buyTokensIn:",test[0])
        setInValue(test[0]);

        setTokenAmount( test[0]*10**9 )
        getBuyInfo(  test[0]*10**9, selectedIndex )
        userInfo( )

        break;

      case "vEventAddress":
        console.log("vEventAddress",test[0])
        setActiveFPMM(test[0])
        break;


      case "liquidityIn":
        console.log("liquidityIn:",test[0])
        setInValue(test[0]);
        setFunding( test[0]*10**9 )
        //getCollateralInfo( )
        userInfo()
        break;

      case "oSelector":
        let optionIndex = getQOptionIndex( test[0][1],  test[0][0] )
        console.log("optionIndex tester:",optionIndex)
        setSelectedIndex(optionIndex)

        break;

      case "selectCollateral":
        console.log("sel col:",test[0])
        //setCollateralAddy(test[0])
        setSelectedCollateral(test[0])
        break

      case "testOutcomes":
        //if (test[0][1] == activeFPMM ){
          console.log("testOutcomes:",activeFPMM, test[0])
        //}
        break

      case "activeCard":
        //if (test[0][1] == activeFPMM ){
        console.log("activeCard:", test[0])
        if (activeFPMM !== test[0][2]){
          setActiveFPMM(test[0][2])
          setOutcomesCount(test[0][0].length)
          //setQuestId(questionId)
          getCollateralInfo( test[0][2] )
          getPositionInfo( test[0][2], test[0][0].length )
        }

        //}
        break


    default:
    //console.log("No case match")
    }
  }

  async function getBuyInfo( lTokAmount, here ) {
    var cBuy = await FPCalcBuyAmount( activeFPMM, lTokAmount, selectedIndex, walletInfo.provider)
    setCalcBuy( Number(cBuy) )
    console.log('cBuy:', cBuy , lTokAmount, selectedIndex, here )
    return cBuy
  }

  useEffect(()=>{
    fetURL()
  },[apiTrigger])

  useEffect(() => {
    const fetchData = async () => {
      if (isConnected) {
        try {
          let thisChain = parseInt(chainData?.chainId, 16)
          let tokenAddress = tokensAddrs.Friendship[thisChain] || "0xc674B7Bed1D086584c8776C1B83013009cC294e7";
          setTokenAddy(tokenAddress);

          const completeQuestions = await GetQsByfilter(walletInfo?.provider, predictionAddrs.PaginateQuestions[thisChain], "trade", gridView);
          completeQuestions ? setQuestionList(completeQuestions) : setQuestionList([])
          // // Ensure this function does not require async/await unless modified to handle it.
          //getCollateralInfo()
          getConditionalInfo();
          getOnceOnly()
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [isConnected, qBaseUrl, gridView]);

  async function fetURL(){
     let thisChain = parseInt(window.ethereum.chainId, 16)
     let url = await GetUrlString( 2,  predictionAddrs.PaginateQuestions[thisChain] , walletInfo?.provider )

     if (thisChain !==369 ){
       url = 'https://arweave.net/myFkl2qOjO-5_NJibqIdeoN5JkTBefBWr9l2Dhy1SwA/'
     }
     setQBaseUrl(url)
  }

//INPUT HANDLING
const outcomeSelect = (select, row, activeController, setActiveController, viewData, updateViewData, qId) => {
  const {userBinaryPartSelection, userMultiPartSelection, input} = viewData;
  if(activeController && userBinaryPartSelection === select){

    getBuyInfo()
    //select = yes/no (0/1),  row=rowIndex     - userMultiPartSelection=(PreviousRender Row index)
    if((!row && row !==0) || userMultiPartSelection === row){
      setActiveController(null) //Condition if someone unselects the option (clicks on already accepted)

    }
  } else {
    setActiveController(qId); //activates which controller is currently vbeing viewed (can see input)
    //console.log( 'ActiveController',activeController)
    console.log('viewData',viewData)


  }
  if (row || row === 0) {
    updateViewData("userMultiPartSelection", row); //if userMultiPartSelection is defined set it to option index
  }
  updateViewData("userBinaryPartSelection", select); //set yes or no
  let optionIndex = getQOptionIndex( userMultiPartSelection,  userBinaryPartSelection )


};




const handleSubmit = ( viewData, qId, qTitle, view, ) => {
  //checkDecimals()
  switch(view) {
    case "buy":
      const {userBinaryPartSelection, userMultiPartSelection, input } = viewData //Move into switchstatement
      let optionIndex = getQOptionIndex( userMultiPartSelection,  userBinaryPartSelection )
      console.log('decimals:',decimals)
      let tAmount = input*10**9
      let fpmmAddress = activeFPMM

      BuyShares( optionIndex, tAmount, fpmmAddress )

      if (userBinaryPartSelection === "") {
        showPopUp("You must select an option to enter market", "error");
        return;
      }
      break;

    case "addLiquidity":
      console.log("add Liquidty funding", viewData.input)
      let funds = Number(viewData.input) * Number(10**9)

      if (!nineList.includes(collateralAddy)) {
          funds = Number(viewData.input) * Number(10**18)
      }

      setDHint(viewData.distributionInfo)
      console.log('funding:', funding, funds)
      AddFunds()
      break;
    default:
      // code block
  }
};



  function getQOptionIndex( row , col  ){
    if ( row == null ){
      row = 0
    }
    var qOptnIndex = col*row -1
    qOptnIndex = (row*2) + (col)
    return qOptnIndex

  }
  async function checkDecimals( ){
    let tDecimals = 9
    var collateralToken = await FPCollateralInfo(activeFPMM,  walletInfo.provider)

    if (!nineList.includes(collateralToken.token)) {
      tDecimals = 18
    }
    setDecimals(tDecimals)
    return tDecimals
  }

  function resultsHandle( myResult ){
    if(myResult[0]){
      showPopUp("Transaction Success: "+ myResult[1], "success")
    } else {
      showPopUp("Error: "+ myResult[1], "error")
    }

  }

  async function userInfo(){
    //userBalance
    var userAllowance = await Allowance( collateralInfo?.token, activeFPMM, walletInfo.provider, walletInfo.signer.address )
    var fpmmUserApproved = await IsApproved(predictionAddrs.ConditionalToken[chainData?.id], walletInfo.signer.address, activeFPMM, walletInfo.provider)

    var userTokenBalance = await TokenBalance( collateralInfo?.token, walletInfo.signer.address,  walletInfo.provider)
    setUserWTokenBalance( userTokenBalance )
    console.log('userAllowance check markets Al,FP,TB:',userAllowance, fpmmUserApproved, userTokenBalance)
    console.log('userFPMMAllowance vs input:',userFPMMAllowance, tokenAmount )
    setUserFPMMAllowance (userAllowance)
    setFPMMApproved( fpmmUserApproved )

  }

  async function getOnceOnly(){
    let collateralWhitelist = await CollateralWhitelist( predictionAddrs.WhiteList[chainData?.id] , walletInfo.provider );
    setCollateralInfo((prevData) => ({
      ...prevData, // Spread the previous state
      whiteList:collateralWhitelist // Update the specified key with the new value
    }));

  }

  async function getCollateralInfo( activeFPMM ){
    let collateralToken = await FPCollateralInfo( activeFPMM,  walletInfo.provider)
    //will fail if fpmm  is null
    let tDecimals = 9


    if ( !nineList.includes(collateralToken.token) ){
      tDecimals = 18
    }
    const tokenlist = getPredictionAddresses(chainData?.id)

    let tokenName = 'NA'
    try{
      tokenName = tokenNameByAddress(tokenlist, collateralToken.token)
    }catch{

    }

    console.log( "collateral info",tokenName, collateralToken, tDecimals) // collateralInfo.whiteList,s
    setCollateralInfo((prevData)=> ({
      ...prevData,
      token: collateralToken?.token,
      name:tokenName,
      decimals: tDecimals
    }));

    console.log('collateral info name:',collateralInfo?.name)

    setDecimals(tDecimals)

  }

  async function getConditionalInfo(){
    //conditionalAddress, ownerAddress, id,
    var id = 0
    var ownerAddress = '0x995fa5eadCDf96268C7a99357d87fe89dCF7EDd2' //walletInfo.signer.address
    var oracleAddress = '0xb8bF8D8cE14F092A21dA559ae2a4eD84EFF64b09'
    var questionId = '0x4b22fe478b95fdaa835ddddf631ab29f12900b62061e0c5fd8564ddb7b684333'
    var parentCollectionId = '0x0000000000000000000000000000000000000000000000000000000000000000'
    var indexSet = 0
    var outcomeSlotCount = 2
    var operatorAddress = predictionAddrs.FPMarketMaker[chainData?.id]
    var conditionIds = 0
    var amount = 1000
    var index = 0

    console.log('FPmm',activeFPMM)

  }


  async function AddFunds(){
    console.log('Market AddFunds:',activeFPMM)
    var result = await AddFunding(  activeFPMM, tokenAddy,  funding,  dHint, walletInfo.provider,  walletInfo.signer)
    resultsHandle(result)
  }


  async function BuyShares( indexNum, tokenAmount, fpmmAddress ){
    if (userFPMMAllowance < tokenAmount){
      console.log('BuyShares fpmm: allowance its less for some reason')
    }
    if (userFPMMAllowance > tokenAmount){
      console.log('BuyShares fpmm: tokenAmount its less for some reason')
    }
    console.log('BuyShares fpmm: amnt, allw', fpmmAddress, tokenAmount,  userFPMMAllowance )
    var minBShares = calcBuy
    var result = await Buy(  fpmmAddress, tokenAddy,  tokenAmount, indexNum, calcBuy, walletInfo.provider,  walletInfo.signer, userFPMMAllowance)
    resultsHandle(result)
  }

  function processPositions(positionIds, indexes, balances) {
  // Step 1: Calculate powerLength and complength
  const x = indexes.length;
  const powerLength = Math.pow(2, x) - 1;
  const complength = powerLength;

  // Initialize lists to store the results
  let list1 = [];
  let list2 = [];
  let list3 = [];
  let list = [];

  // Step 2: Iterate over the indexes and perform operations
  indexes.forEach(val => {
    // Create the binary dictionary with 'yes' and 'no' as per your specification
    const binaryDict = {
       "yes":val,
       "yPostition":positionIds[val],
       "no":complength - val,
       "nPostition":positionIds[complength - val],
       "balance":balances[val]
    };

    list.push(binaryDict);

    list1.push(val)
    list1.push(complength - val)

    // Add to list2 (binary array)
    list2.push(positionIds[val]);
    list2.push(positionIds[complength - val]);

    list3.push(balances[val]);
    list3.push(balances[complength - val]);


  });

  return [list, list1, list2, list3];
  }

  function processPercent(inputArray) {
      // Extract numbers at even indices (0, 2, 4, 6, ...)
      const evenIndexValues = inputArray.filter((_, index) => index % 2 === 0);
      // Calculate the sum of the even-index numbers
      const evenIndexSum = evenIndexValues.reduce((sum, num) => sum + num, 0);
      // Create the percentage array based on the even-index values
      const percentageArray = evenIndexValues.map(num => {
          return evenIndexSum === 0 ? 0 : ((num / evenIndexSum) * 100).toFixed(1);
      });

      return {
          evenIndexSum,
          percentageArray
      };
  }

  function createPowerOfTwoArray(x, value) {
      // Calculate the length of the array, which is 2^x
      let length = Math.pow(2, x);
      // Initialize the array with zeros for the "yes" condition
      let array = new Array(length).fill(0);
      // List to store the indices set to the value (yes condition)
      let yesIndices = [];
      // Loop through and find the powers of 2 up to the length of the array
      let powerOfTwo = 1;
      while (powerOfTwo < length) {
          yesIndices.push(powerOfTwo); // Add the power of 2 index to the list
          powerOfTwo *= 2; // Move to the next power of 2
      }
      // Fill the array at the chosen indices with the specified value (yes condition)
      for (let i = 0; i < yesIndices.length; i++) {
          array[yesIndices[i]] = value; // Set the value at the specified index
      }
      // Generate binary representations for the "yes" condition (indices set to value)
      let yesBinary = yesIndices.map(index => index.toString(2).padStart(x, '0'));
      // Now, generate the "no condition" indices where exactly one bit is 0 and others are 1
      let noIndices = [];
      let noBinary = [];
      // Loop through and generate the binary numbers where only one bit is 0
      for (let i = 0; i < x; i++) {
          let binaryStr = '1'.repeat(x); // Start with all ones
          binaryStr = binaryStr.slice(0, i) + '0' + binaryStr.slice(i + 1); // Replace the i-th bit with 0
          noIndices.push(parseInt(binaryStr, 2)); // Convert binary string to integer for "no" index
          noBinary.push(binaryStr); // Store the binary representation
      }
      // Create a new array for the "no condition" with the value set at the "no" indices
      let noArray = new Array(length).fill(0); // Initialize with zeros
      for (let i = 0; i < noIndices.length; i++) {
          noArray[noIndices[i]] = value; // Set value at the "no" condition indices
      }
      // Create the final array that includes both the "yes" and "no" conditions
      let finalArray = new Array(length).fill(0);
      // Apply the "yes" condition (set values at powers of 2 indices)
      for (let i = 0; i < yesIndices.length; i++) {
          finalArray[yesIndices[i]] = value;
      }
      // Apply the "no" condition (set values where exactly one 0 bit exists)
      for (let i = 0; i < noIndices.length; i++) {
          finalArray[noIndices[i]] = value;
      }

      // Print all the required information
      console.log("Yes condition (Indices set to value):", yesIndices);
      console.log("Yes condition (Binary representations):", yesBinary);
      console.log("No condition (Indices with one 0):", noIndices);
      console.log("No condition (Binary representations with one 0):", noBinary);
      console.log("Resulting Array (Yes condition applied):", array);
      console.log("Resulting Array (No condition applied):", noArray);
      console.log("Final Array (Yes and No conditions applied):", finalArray);

      return yesIndices;
  }

  async function getPositionInfo( activeFPMM, outcomesCount ){
    var position1  = await GetPositionIds( activeFPMM, walletInfo.provider)
    let positions = position1.concat(position1)
    let positionStringsExtra = Object.values(positions).map(value => value.toString());
    let positionStrings = Object.values(position1).map(value => value.toString());

    var marketTVL = await TokenBalance( collateralInfo?.token, activeFPMM,  walletInfo.provider)
    var totalSupply = await TotalSupply( activeFPMM,  walletInfo.provider)
    let feeRate = 0.05

    console.log('real balance:', marketTVL , collateralInfo?.token ,'positionIds',positionStrings)
    console.log('real totalSupply',totalSupply)
    setTVLBalance( marketTVL )

    let repeatUserWallets = Array(positionStrings.length).fill(walletInfo.signer.address)
    let repeatFPMMWallets = Array(positionStrings.length).fill(activeFPMM)
    let repeatWallets = repeatUserWallets.concat(repeatFPMMWallets)
    let outcomers = Array(positionStrings.length).fill('id or something');
    let bal = Array(positionStrings.length).fill(100);

    //postitList
    var cBalanceOfBatch = await BalanceOfBatch( predictionAddrs.ConditionalToken[chainData?.id], repeatWallets,  positionStringsExtra, walletInfo.provider)
    console.log('cBalanceOfBatch:',cBalanceOfBatch)
    var userCBalanceOfBatch = cBalanceOfBatch.slice(0,cBalanceOfBatch.length/2)
    var fpmmCBalanceOfBatch = cBalanceOfBatch.slice(cBalanceOfBatch.length/2)
    console.log('real userCBalanceOfBatch:',userCBalanceOfBatch)
    console.log('real fpmmCBalanceOfBatch:',fpmmCBalanceOfBatch)

    console.log('(outcomesCommes)',outcomesCount)

    let sum = 0;
    try{
      for (let i = 0; i < cBalanceOfBatch.length; i++) {
        sum += Number(cBalanceOfBatch[i]);
      }
    }catch{

    }
    let  value = 10
    let yesIndices = createPowerOfTwoArray(outcomesCount, value)

    const [ulist0, ulist1, ulist2, ulist3] = processPositions(positionStrings.slice(0), yesIndices,  userCBalanceOfBatch )
    const [list0, list1, list2, list3] = processPositions(positionStrings.slice(0), yesIndices, fpmmCBalanceOfBatch)

    console.log("List 2 (binary dict):", list0);
    console.log("List 1 (Flat indixces):", list1);
    console.log("List 2 (Flat Positions):", list2);
    console.log("List 3 (Flat balance):", list3);

    let betPercents = processPercent(list3)
    console.log("real Even Index Sum:", betPercents?.evenIndexSum);
    console.log("real Percentage Array:", betPercents?.percentageArray);


    setBatchPositionInfo( {
      positionIds: list2,
      userCondToknBalance:ulist3,
      fpmmCondToknBalance:list3,
      outcomes:outcomers.slice(0,outcomesCount*2),
      outcomeIndex:list1,
      colToknBalance:bal.slice(0,outcomesCount*2),
      betPercents:betPercents?.percentageArray,
      aFPMM:activeFPMM}

    )

    console.log('BatchPositionInfo:',batchPositionInfo)
    //console.log('Batch sum: ',sum, 'balance:',userCTBalance)

    //console.log('estimated token buys:',amountWithoutFee, 'estimated token sells:', collateralWithFee, 'from:',1000, 'realbuy:',cBuy, 'realsell',cSell)
  }


  const onChainChange = () =>{
    setApiTrigger((prevCount) => prevCount + 1); // Increment count instead of toggling
  }

  return (
      <section className="newsletter section">
        <div className="demoDapp-container">
        <div>
        <div className="panel-with-banner panel">
              <div className="markets-banner-img general-banner-img"/>
              </div>
          <div className="prediction-toggle-buttons" style={{ display: "flex", gap: "10px", justifyContent: "center" }} >
            <button className="prediction-toggle-button" onClick={() => setGridView(200)}>
              Live Markets
            </button>
            <button className="prediction-toggle-button" onClick={() => setGridView(100)} >
              Pre-liquidity Markets
          </button>
      </div>
        <WrongChain onChainChange={onChainChange} wrongChainError={"The Markets Page is reserved for Arbitrum/Pulse"} expectedChainIdList={[42161, 369]} showPopUp={showPopUp}>
        <Pagination
              currentPage={pagination?.page}
              totalPages={Math.ceil(
                questionList?.length / pagination?.resultsPerPage //This sets question list via index [0 hasLiquid 1 no Liquid]
              )}
              isCapped={true}
              onPageChange={(newPage) =>{
                setPagination((prevState) => ({
                  ...prevState,
                    page: newPage,
                }))
              }}
            >

            <Grid gridView={gridView}
             outcomeSelect={outcomeSelect}
             qBaseUrl={qBaseUrl}
             handleSubmit={handleSubmit}
             questionList={questionList && questionList?.slice((pagination.page - 1) * pagination.resultsPerPage, pagination.page * pagination.resultsPerPage)}
             vInputChange={vInputChange}
             collateralInfo={collateralInfo}
             vCalcBuyShares={calcBuy}
             userWTokenBalance={userWTokenBalance}
             vBatchPositionInfo={batchPositionInfo}
             chainId={chainData?.id}
             userFPMMAllowance={userFPMMAllowance}
             vInValue={tokenAmount/(10**9)}
             />
            <br/></Pagination>
       </WrongChain>
      </div>
          <img src={blueBlack} />
        </div>
      </section>
  );
};

export { MarketsPage };
