import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { ConnectWithSelect } from "../modules/wallet";
import { useLocation, useNavigate } from 'react-router-dom';
import banner from '../assets/images/sommi-fan-token-logo-blue-thin.png'
import { HashLink as Link } from 'react-router-hash-link';
import { ChainSelect } from "../modules/wallet";

const NavBar = () => {
  const location = useLocation();
  const isPageHome = location.pathname === '/';
  const isPageMeme = location.pathname.startsWith('/memes');
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className="navigation">
        <nav class="navbar">
          <a href='/'><div className="navbar-logo" /></a>
          <ul class="nav-links nav-left">
            <input type="checkbox" id="checkbox_toggle"  checked={isChecked} onChange={()=>setIsChecked(!isChecked)}/>
            <label for="checkbox_toggle" class="hamburger"><b>&#9776;</b></label>
            <div class="menu">
              <a href="/" className={isChecked ? "nav-item" : "nav-item nav-link"}><li>Overview</li></a>
              <a href="/memes" className={isChecked ? "nav-item" : "nav-item nav-link"}><li>Memes</li></a>
              <a href="/dapp/nfts" target="_blank" rel="noreferrer" className={isChecked ? "nav-item" : "nav-item nav-link"}><li>NFTs</li></a>
              <a href="https://twitter.com/SommiFanToken" target="_blank" rel="noreferrer" className={isChecked ? "nav-item" : "nav-item nav-link"}><li>Follow</li></a>
              <a href="https://t.me/SommiFanToken" target="_blank" rel="noreferrer" className={isChecked ? "nav-item" : "nav-item nav-link"}><li>Telegram</li></a>
              {isPageHome || isPageMeme ? <a href="/dapp" className={isChecked ? "nav-item" : "nav-item nav-link"}><li>Dapp</li></a> : null}
            </div>
          </ul>
          <ul class="nav-links nav-right">
          {isPageHome || isPageMeme  ?  null : <li><ChainSelect /></li> }
          {isPageHome || isPageMeme ? null : <li><ConnectWithSelect /></li> }
        </ul>
        </nav>
      </div>
      );
}

      export {NavBar};
