import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";

// Function to get Position ID
async function PositionId(conditionalAddress, collateralTokenAddress, collectionId, provider) {
    var positionId = '0'

    try {
        console.log('Fetching Position ID for:', collateralTokenAddress, collectionId);

        let conditionalTokenContract = new ethers.Contract(conditionalAddress, ConditionalTokenABI.abi, provider);
        positionId = await conditionalTokenContract.getPositionId(collateralTokenAddress, collectionId);
        positionId = Number(positionId)
        console.log('Position ID:', positionId);


    } catch (error) {
        console.error('Error fetching Position ID:', error);
        //throw error;
    }

    return positionId;
}

export { PositionId };
