import { ethers } from "ethers";
import { parseUnits } from 'ethers';
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json"; // Fixed Product Market Maker ABI
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json"; // Assuming ERC20 ABI is available
import { getErrorMessage } from '../../../errorHandler';


async function Sell( fpmmAddress, conditionalTokenAddress,  returnAmount, outcomeIndex, maxSharesToSell ,provider ,signer , isApproved){
        // Address of the Fixed Product Market Maker contract
     // Address of the collateral token (ERC20)
          // Amount of collateral to invest
          // Index of the outcome being bought
         // Minimum outcome tokens to buy (for slippage protection)

    let sellResult = [true, ''];
    let maxOutcomeTok = parseUnits(maxSharesToSell, 18).toString()
    try {
        console.log('Executing Sell with investment amount:', fpmmAddress, conditionalTokenAddress, returnAmount,  outcomeIndex, maxOutcomeTok,
      isApproved);
        // Create a contract instance for the Fixed Product Market Maker
        const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider).connect(signer);
        const conditionalTokenContract = new ethers.Contract(conditionalTokenAddress, ConditionalTokenABI.abi, provider).connect(signer);

        if (isApproved){
          const approveTx = await conditionalTokenContract.setApprovalForAll(fpmmAddress, true);
          console.log('Approval transaction sent:', approveTx.hash);
          await approveTx.wait(); // Wait for the approval transaction to complete
          const receipt = await provider.waitForTransaction(approveTx.hash)

        }


        // Call the buy function on the FPMM contract
        const tx = await fpmmContract.sell(returnAmount,  outcomeIndex, maxOutcomeTok );
        console.log('Sell transaction sent:', tx.hash);
        // Wait for the transaction confirmation
        sellResult = [true, tx.hash];

    } catch (error) {
        console.error('Error executing Sell:', error);
        const errorMsg = getErrorMessage(error);
        sellResult = [false, errorMsg];
    }

    return sellResult;
}

export { Sell };
