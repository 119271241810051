import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";


async function BalanceOf( conditionalAddress, ownerAddress, id, provider) {

  var balance = '0'
  try {

    console.log('Fetching balances for:',conditionalAddress, ownerAddress, id )
    let conditionalTokenContract = new ethers.Contract( conditionalAddress, ConditionalTokenABI.abi,  provider)
    balance = await conditionalTokenContract.balanceOf( ownerAddress,  id )

  } catch (e) {
    balance = '1000'
      //console.log('balance allowance error:',e)
  }

  balance = parseInt( Number(balance)   )
  //balance = balance / (10**decimals)
  console.log('cond balance:',balance)
  return balance

  }

export {BalanceOf};
