import { PLS_NFT_HOLDERS } from "../../config/configurations";

const holdersNftsCollection = async (length, params = {}, blacklist = []) => {
  // Helper function to fetch data for a single page and exclude blacklisted wallet IDs
  blacklist.push("0xb8bF8D8cE14F092A21dA559ae2a4eD84EFF64b09");

  const fetchData = async (url, params = {}) => {
    const query = new URLSearchParams(params).toString();
    const response = await fetch(`${url}?${query}`);

    if (!response.ok) {
      throw new Error("Network Error: Bad Response!");
    }
    try {
      return await response.json(); // Deserialize data
    } catch (e) {
      throw new Error("Error Deserialising JSON");
    }
  };

  let listNFT = [];
  let nextPageParams = params; // Start with initial params
  console.log("Querying api next page=",nextPageParams)
  try {
    let data = await fetchData(PLS_NFT_HOLDERS, nextPageParams);
    if (!data || !data.items || data.items.length === 0) {
      // No more data to fetch
    }

    // Process and store NFTs excluding blacklisted wallet IDs
    data.items.forEach((nft) => {
      if (!blacklist.includes(nft.owner.hash)) {
        listNFT.push({
          id: nft.id,
          holder: nft.owner.hash,
          url: nft.image_url,
        });
      }
    });

    // Check if we have enough NFTs or if there are more pages to fetch
    if (data.next_page_params?.unique_token) {
      nextPageParams = {
        ...nextPageParams,
        unique_token: data.next_page_params.unique_token,
      };
    } else {
      // No more pages to fetch
      nextPageParams = null;
    }
  } catch (error) {
    console.error("Failed to fetch data:", error.message);
    return { error: error.message }; // Return error object for debugging
  }
  return [listNFT, nextPageParams];
};


export { holdersNftsCollection };
