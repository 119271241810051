import React from "react";
import { useLocation } from "react-router-dom";
import { HorizontalBanner } from "../../HorizontalBanner";

const Single = ({children, ...props})=> {
//const { handleSubmit, InputHandle, qId, source_name, resolution, resolution_date_time, source_view, activeController, question, setActiveController,
//  vInValue, options, outcomeSelect, setShowPercent, vCalcSellShares, userWTokenBalance, view, viewData, updateViewData} = props;

  const { qId, activeController, handleSubmit, viewData, source_name, resolution_date_time, source_view, resolution,source_embed,source_url, updateViewData,
    question, InputHandle, InputTest, setActiveController, betPercent, options, outcomeSelect, showPercent, setShowPercent,view, vInputChange, vCalcBuyShares, vBuySellInfo, handleInputChange,
  vInValue,vCalcSellShares, userFPMMAllowance, fpmmApproved, userWTokenBalance, fpFees, collateralInfo, vEventAddress, vBatchPositionInfo} = props;

const location = useLocation()
const isPredictionPage = location?.pathname === "/dapp/prediction"
let inType = 'Tokens'
let localBuySell = vCalcSellShares

console.log('showPercent',showPercent, betPercent)

function changeBet( betArray ) {}

return(
  <div className={isPredictionPage ? "prediction-container" : ""}>
  {isPredictionPage && <>
  <div className="prediction-header">
     <h3> {view?.toLowerCase() === "buy" ? "Buy" : "Sell"}</h3>
   </div>
   </>
       }

  <div className="betcard-inner-single">
       { /*<div style={{display: 'flex', justifyContent: 'space-between'}}>
         <div className="bet-chart">
            <div className="bet-chart-bar">
              <div
                className="bet-chart-fill"
                style={
                  betPercent?.[showPercent] && {
                    '--percentage': betPercent[showPercent],            }
                }
              />
            </div>
              {betPercent?.[showPercent] && (
                <>
                  <p className="bet_option_text">
                    {options?.[showPercent] && options?.[showPercent]}
                  </p>
                  <p className="bet_option_text">{betPercent[showPercent]}%</p>
                </>
              )}
          </div>
      </div> */}
      <div id="bet-options-single" className="bet-options">
        <button
          onClick={()=> {outcomeSelect(0, null, activeController, setActiveController, viewData, updateViewData, qId);
            vInputChange( [[0,0], "oSelector"] ); changeBet([0,0]); vInputChange( [ [vEventAddress] , "vEventAddress"]); }
          }

          onMouseOver={() => setShowPercent(0)}
          className={options?.[0] && viewData?.userBinaryPartSelection===0 ? "button bet_btn green" : "button bet_btn" }
        >
          {options?.[0] && options[0]}
        </button>
        <button
          onClick={()=> {outcomeSelect(1, null, activeController, setActiveController, viewData, updateViewData, qId);
            vInputChange( [[1,0], "oSelector"] ); changeBet([1,0]); vInputChange( [ [vEventAddress] , "vEventAddress"]); }
          }
          onMouseOver={() => setShowPercent(1)}
          className={options?.[1] && viewData?.userBinaryPartSelection===1 ? "button bet_btn red" : "button bet_btn"}
        >
          {options?.[1] && options[1]}
        </button>
      </div>

    </div>
    {activeController === qId &&
    <HorizontalBanner data={{[inType]: localBuySell/(10**9),  Wallet: userWTokenBalance, Token: collateralInfo?.name}} small={!isPredictionPage}/>
    }
    <InputHandle />
    <div id={isPredictionPage ?  "prediction_submit_button_wrapper" : "market_submit_button_wrapper"} className="market_submit_button_wrapper">
      <button onClick={() => handleSubmit( viewData, qId, question, view ) } className="button bet_btn bet_btn_sub" >
      {view?.toLowerCase() === "buy" ? "BUY" : "SELL"}
      </button>
    </div>
    {isPredictionPage && (
    <div className="prediction-info" style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: '10px' }}>
       <span className="prediction-info-item-flex" ><b>Source:</b></span> <span><i>{source_name}</i></span>
       <span className="prediction-info-item-flex" ><b>Resolving:</b></span> <span><i>{resolution_date_time}</i></span>
       <span className="prediction-info-item-flex" ><b>Details:</b></span> <span><i>{source_view}</i></span>
       <span className="prediction-info-item-flex" ><b>Res-Method:</b></span> <span><i>{resolution}</i></span>
       {children}
     </div>
    )}
    </div>
  );
};

export {Single};
