import { ethers } from "ethers";
import LotteryABI from "../../config/abi/NFTLotteryABI.json";
import TokenABI from "../../config/abi/standardTokenABI"


async function WinAmount( lotteryAddress, provider, signer) {


  let winInfo = []
  let decimals = 18
  let erc20Address = '0x00'
  let uERC20 = true
  let amount = 0


  try {

    let LotteryContract = new ethers.Contract( lotteryAddress, LotteryABI.abi,  provider)

    amount = await LotteryContract.getWinAmount()
    //console.log('winamount',amount[1])
    //uERC20 = await LotteryContract.useERC20()


    if(uERC20) {
      erc20Address = await LotteryContract.erc20Token()
      decimals = 9
      if ( erc20Address != "0x6A46d56F9Dd963cEAc7f60aD148d49219CC3b79f"){
        let TokenContract = new ethers.Contract( erc20Address,TokenABI, provider).connnect(signer)
        decimals = await TokenContract.decimals()
      }

    }


  }
  catch (e) {
    console.log('lottery fees error:',e)

  }

  winInfo.push(
    Number(amount[1]), uERC20, erc20Address, decimals, Number(amount[0]),Number(amount[2])
  )

  console.log('LottoINFO:', winInfo)
  return winInfo
}
export { WinAmount };
