import React from "react";

const Redeem = ({handleSubmit, qId, question, vBatchPositionInfo, options, collateralInfo}) => {
  //const positions = [{ outcome: "yes", position: 1, valuePerShare: 1, Total: 3 }, { outcome: "no", position: 1, valuePerShare: 1, Total: 3 }, { outcome: "demo1", position: 1, valuePerShare: 2, Total: 3 }, { outcome: "demo2", position: 1, valuePerShare: 2, Total: 3 }];
  //console.log( 'vBatchPositionInfo',vBatchPositionInfo)

  const outcomeBuilder = () => {
    console.log("vBatchPositionInfo debug", vBatchPositionInfo)
    if (!vBatchPositionInfo ) {
      return (<div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", borderBottom: "1px solid blue", }} >
        <div className="prediction-info">
          <h3>No position found...</h3>
          <p>Please Refresh to try again</p>
        </div>
      </div>)
    }
    else{
      console.log('vBatchPositionInfo bein read:', vBatchPositionInfo)
    const positions = vBatchPositionInfo?.outcomes?.map((outcome, index) => ({
      outcomes:outcome,
      positionIds: vBatchPositionInfo?.positionIds?.[index],
      userCondToknBalance: vBatchPositionInfo?.userCondToknBalance?.[index],
      fpmmCondToknBalance: vBatchPositionInfo?.fpmmCondToknBalance?.[index],
      colToknBalance: vBatchPositionInfo?.colToknBalance?.[index],
      position: index + 1, // assuming position is index-based
    }));

        return positions.map((outcomeData, index) => {
          const optionGroup = Math.floor(index / 2); // 0 for 0-1, 1 for 2-3, 2 for 4-5
          const currentOption = options?.[optionGroup];
          const yesOrNo = index % 2 === 0 ? "Yes" : "No"; //Hardcoded yes/no still

          return (
          <div key={index} style={{ width: "100%",  display: "flex", flexDirection: "column", alignItems: "center", borderBottom: "1px solid blue", }} >
            <h3>{currentOption}: {yesOrNo}</h3>
            <div className="prediction-info ">
              <p className="word-wrapper">Position Id {outcomeData?.positionIds}</p><br/>
              <p>Position Shares: { outcomeData?.userCondToknBalance/(10**9) }</p><br/>
              <p>Total Pool Shares: { outcomeData?.fpmmCondToknBalance/(10**9) }</p><br/>
              <p>Total Value:{outcomeData?.colToknBalance} ${collateralInfo.name}</p>
            </div>
            <div className="prediction-submit-section">
              <button onClick={()=>handleSubmit(positions?.[index], qId, question, "redeem")} className="button bet_btn_sub bet_btn">Redeem</button>
            </div>
            <hr />
          </div>
        )});
      };
  }

  /*
  const outcomeBuilder = () => {
    return positions.map((outcomeData, index) => (
      <div key={index} style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", borderBottom: "1px solid blue", }} >
        <h3>{outcomeData.outcome}</h3>
        <div className="prediction-info">
          <p>Position {outcomeData.position}</p>
          <p>Value per share {outcomeData.valuePerShare}</p>
          <p>Total {outcomeData.Total}</p>
        </div>
        <div className="prediction-submit-section">
          <button onClick={()=>handleSubmit(positions?.[index], qId, question, "redeem")} className="button bet_btn_sub bet_btn">Redeem</button>
        </div>
        <hr />
      </div>
    ));
  };
*/
  return (
    <div className="prediction-container">
      <div className="prediction-header">
        <h3>Redeem</h3>
      </div>
      <div style={{ borderTop: "1px solid blue", height: "100%", width: "100%", display: "flex", flexDirection: "column", gap: "30px", }} >
        {outcomeBuilder()}
      </div>
    </div>
  );
};

export { Redeem };
