// src/components/VideoPlayer.js
import React, { useRef, useEffect } from 'react';
import './videoPlayer.css'; // Import the CSS file for styling

const VideoPlayer = ({ src, played, setPlayed, vidData, toPlay }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && toPlay) {
      videoRef.current.play();
      setPlayed(true);
    }},[]);

  return (
    <div className="video-container">
      <video ref={videoRef}>
        <source src={vidData && toPlay && vidData[toPlay][0]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export {VideoPlayer};
