import React, { useEffect, useState } from 'react';

const doFormatMoney = (num) => {
  num = num/(10**9)
  if (num >= 1000000) {
    return (num / 1000000) + 'm'; // Format for millions
  } else if (num >= 100000) {
    return (num / 1000) + 'k'; // Format for 100k+
  } else if (num >= 1000) {
    return (num / 1000) + 'k'; // Format for thousands
  } else {
    return num.toLocaleString(); // Regular format with commas
  }
};

function truncateTxt( wStr  ){
  return wStr.length > 10 ? wStr.substring(0, 20) + "..." : wStr;
}

const TicketCardController = ({ ticket, isWinner, blacklist=[] }) => {
  const [rollOver, setRollOver] = useState(false)

  const [formatedMoney, setFormatedMoney] = useState()
  blacklist.push("0xb8bF8D8cE14F092A21dA559ae2a4eD84EFF64b09");

useEffect(()=>{
  setFormatedMoney(doFormatMoney(Number(ticket?.amount)))
  sortBlackListRollOver()
},[ticket])

const sortBlackListRollOver = () =>{
  if(isWinner){
    var hasMatch = blacklist.some(item => ticket?.wallet === item )
    if (hasMatch){
      setRollOver(true)
    }
  }
}
//if(rollOver){ //BLACKLISTED WALLET
let ignoreWallet = "0xb8bF8D8cE14F092A21dA559ae2a4eD84EFF64b09"
if(ticket?.wallet===ignoreWallet){

//  {console.log('address, ind',address, ind)}

  return (
    <>
    <div className="ticket edging">
      <img className="holders-logo" src={ticket?.url + ticket?.winnerToken?.toString() + ".jpg"}  />

        <div className="rollover-panel">
        <span><b>ID#</b><i>{Number(ticket?.winnerToken)}</i></span>
        <span className="rollover-text"><b>RollOver!</b> </span>
        <span className="glow-text">{formatedMoney} {ticket?.prizeAddress}</span>
        <span><b>Date</b><i>{ticket?.drawTime.split(',')[0]}</i></span>
      </div>
        {/* <span><b>Holder:</b><br/></span>
        <span><i>{ticket?.wallet}</i></span>
        <span><b>Prize Address:</b><br/><i>{ticket?.prizeAddress}</i></span> */}
      </div>

  </>
  )}
  return (
    <div className="ticket edging">
      {isWinner ? (
        <>
          <img className="holders-logo" src={ticket?.url + ticket?.winnerToken?.toString() + ".jpg"} />
          <div className='ticket-inner-winners'>
            <div className='winner-id-row'>
              <span><b>ID# </b><i>{Number(ticket?.winnerToken)}</i></span>

            </div>
            <span className="winner-text"><b>Winner!</b><br/></span>
            <span ><i>{truncateTxt(ticket?.wallet)}</i></span>
            <span className="winner-text-amount"><i> {formatedMoney} {ticket?.prizeAddress}</i></span>
            <span><b>Date</b><i>{ticket?.drawTime.split(',')[0]}</i></span>
          </div>
        </>
      ) : (
        <>
          <img className="holders-logo" src={ticket?.url}  />
          <div className='ticket-inner ticket-inner-holders'>
            <span className="holder-id-row">
              <b>ID#</b>
              <i title={ticket?.id}>{ticket?.id}</i> {/* Simple tooltip with title */}
            </span>
            <span><b>Holder:</b></span>
            <span>
              <i title={ticket?.holder}>{ticket?.holder}</i> {/* Simple tooltip with title */}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export {TicketCardController};
