import React from 'react';

const Card = ({ disabled, header, body, imgSrc, altText, children }) => {
  return (
    <div className="feature is-revealing">
    <div className="feature-inner">
      {imgSrc ? <img className="feature-icon" src={imgSrc} alt={altText} /> : null}
      {children && children }
      <h2 className="feature-title-2 mt-24">{header}</h2>
      <p className="text-sm mb-0">{body}</p>
    </div>
    </div>
  );
};

export { Card };