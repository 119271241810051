import React from 'react';

const AttributePanel = ({name, currentAttributes}) =>{
        return (
            <div className="attribute-panel">
              <div className="panel-content">
                {currentAttributes && currentAttributes.map(key => (
                  <div key={key} className="panel-row">
                    <span className="panel-key">{key?.trait_type}:</span>
                    <span className="panel-value">{key?.value}</span>
                  </div>
                ))}
              </div>
            </div>
          );
        };

export {AttributePanel};