function PageHelper( page1, page2){

  const { ids, questionStrings, resolutionDates, timestamps } = page1;
  const { tradingStates, sourceIds, categoryIds, eventAddresses } = page2;


  let npage1 = ids.map((id, index) => ({
      id,
      questionString: questionStrings[index],
      resolutionDate: resolutionDates[index],
      timestamp: timestamps[index],
  }));

  let npage2 = tradingStates.map((tradingState, index) => ({
      tradingState,
      sourceId: sourceIds[index],
      categoryId: categoryIds[index],
      eventAddress: eventAddresses[index],
  }));


  let result = [ npage1, npage2 ];
console.log('trade ids', result);

  return result;

}
export { PageHelper };
