import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json";


async function GetWFees(fpmmAddress, provider) {
  let withdrawableFees = 0;

  try {
    // Initialize the contract instance
    const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider);
    // Call the contract's function to get fees withdrawable by the account
    withdrawableFees = await fpmmContract.fee();
    console.log('Withdrawable Fees:', withdrawableFees);

  } catch (e) {
    console.error('Error fetching fees:', e);
  }

  // Convert to a regular number if needed and log

  return Number(withdrawableFees);
}

export { GetWFees };
