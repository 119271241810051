import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange, isCapped=true,children }) => {
    return (
        <div className='pagination-container'>
            {children && <div className='pagination-children'>{children}</div>}

        <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className="custom-pagination">
                    <button
                        className="pagination-button button"
                        disabled={currentPage === 1}
                        onClick={() => onPageChange(currentPage - 1)}
                    >
                        <span className='arrow-head'>⮜</span>
                    </button>

                    <div className="current-page-box">
                        <span className='current-page'>{currentPage}</span>
                    </div>

                    <button
                        className="pagination-button button"
                        disabled={isCapped ? currentPage === totalPages : false}
                        onClick={() => onPageChange(currentPage + 1)}
                    >
                        <span className='arrow-head'>⮞</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export { Pagination };
