import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json";

/**
 * Calculate the buy amount of outcome tokens for a given investment amount and outcome index.
 *
 * @param {string} fpmmAddress - The address of the Fixed Product Market Maker contract.
 * @param {number} investmentAmount - The amount of collateral to invest (in wei).
 * @param {number} outcomeIndex - The index of the outcome for which to buy tokens.
 * @param {ethers.providers.Provider} provider - The ethers.js provider instance.
 * @returns {Promise<number>} - A promise that resolves to the amount of outcome tokens to buy.
 */
async function FPCalcBuyAmount(fpmmAddress, investmentAmount, outcomeIndex, provider) {
  let buyAmount = 0;

  try {
    // Initialize the contract instance
    const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider);

    // Call the contract's function to calculate the buy amount
    buyAmount = await fpmmContract.calcBuyAmount(investmentAmount, outcomeIndex);
  } catch (e) {
    console.error('Error calculating buy amount:', e);
  }

  // Convert to a regular number if needed and log
  console.log('Buy Amount:', buyAmount);
  return Number(buyAmount)
  //return parseFloat(ethers.utils.formatUnits(buyAmount, 'wei'));
}

export { FPCalcBuyAmount };
