import { ethers } from "ethers";
import FPMarketMakerABI from "../../../../config/abi/prediction/FixedProductMarketMakerABI.json";

async function TotalSupply(fpmmAddress, provider) {

  let totalSupply = 0;

  try {
    // Initialize the contract instance
    const fpmmContract = new ethers.Contract(fpmmAddress, FPMarketMakerABI.abi, provider);

    // Call the contract's function to get the total supply
    totalSupply = await fpmmContract.totalSupply();
    //console.log('Total Supply:', totalSupply);

  } catch (e) {
    console.error('Error fetching total supply:', e);
  }

  // Convert to a regular number if needed and return
  return Number(totalSupply);
}

export { TotalSupply };
