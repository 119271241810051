import { initializeConnector } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnectConnector } from '@web3-react/walletconnect';
import { Web3ReactHooks } from '@web3-react/core';

const [metaMask, useMetaMask] = initializeConnector((actions) => new MetaMask({ actions }));

const connectors = [[metaMask, useMetaMask]];

export { connectors };
