import React from 'react';

const Tabs = (props) =>{
//console.log("debug", props.view , props?.tabs)
    return(
        <div className="betcard-tabs">
        {props.tabs?.public?.map((tab, index) => ( //Public Tabs
           <div
             key={index}
             className={`betcard-tab ${props.view === tab ? 'active' : ''}`}
             onClick={() => props.setView(tab)} // Set the active tab by index
           >
             {tab.charAt(0).toUpperCase() + tab.slice(1)} {/* Capitalize first letter */}
           </div>
        ))}

        {props?.conditional && props.tabs?.private?.map((tab, index) => ( //Private Tabs
           <div
             key={index}
             className={`betcard-tab ${props.view === tab ? 'active' : ''}`}
             onClick={() => props.setView(tab)} // Set the active tab by index
           >
             {tab.charAt(0).toUpperCase() + tab.slice(1)} {/* Capitalize first letter */}
           </div>
        ))}
        </div>
    )
}

export {Tabs};
