import React, { useState, useEffect, useRef } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Gallery from 'react-photo-gallery';
import { Panel } from './Panel';
import { NFT_URL_SRC } from '../config/configurations';
import '../assets/scss/components/NftGalleryPhotos.css';
import noNft from '../assets/images/icons/noNfts.jpg';

const NftGalleryPhotos = ({ numberOfPhotos, ownedNfts, showBtns = true, setSelected, state }) => {
  const [altView, setAltView] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loadedIndexes, setLoadedIndexes] = useState(new Set());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOver, setIsOver] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const galleryRef = useRef(null);
  const scrollSpeed = useRef(1);

  const srcUrl = NFT_URL_SRC;
  const maxPhoto = 1859;

  useEffect(() => {
    selectPhotos();
  }, [numberOfPhotos, ownedNfts]);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (galleryRef?.current) {
        if (ownedNfts) {
          if (galleryRef?.current.scrollTop + galleryRef?.current.clientHeight >= galleryRef?.current.scrollHeight && !isOver) {
            galleryRef.current.scrollTop = 0;
          } else {
            galleryRef.current.scrollTop += scrollSpeed.current;
          }
        } else {
          if (galleryRef?.current.scrollLeft + galleryRef?.current.clientWidth >= galleryRef?.current.scrollWidth && !isOver) {
            galleryRef.current.scrollLeft = 0;
          } else {
            galleryRef.current.scrollLeft += scrollSpeed.current;
          }
        }
      }
    }, 30);

    return () => clearInterval(scrollInterval);
  }, [isOver, ownedNfts]);

  const selectPhotos = () => {
    let tmpPhotos = [];
    if (state === "Display") {
      for (let i = 0; i < numberOfPhotos; i++) {
        const randomNumber = Math.floor(Math.random() * maxPhoto) + 1;
        tmpPhotos.push({
          src: `${srcUrl}${randomNumber}.jpg`,
          width: 4,
          height: 3,
          loaded: false
        });
      }
      setPhotos(tmpPhotos);
      setInitialized(true);
    } else {
      if (state === "user" && ownedNfts) {
        if(ownedNfts.length < 1){setPhotos([{src: noNft, width: 4, height: 2, loaded: true}]); setInitialized(true);} else {
        tmpPhotos = Object.keys(ownedNfts).map((p) => ({
          src: ownedNfts[p].url,
          width: 4,
          height: 3,
          loaded: false
        }));
        setPhotos(tmpPhotos);
        setInitialized(true);
      }}
    }

  };

  useEffect(() => {
    const loadImagesInView = () => {
      if (!galleryRef?.current) return;

      const galleryRect = galleryRef?.current.getBoundingClientRect();
      const loadImageIndexes = new Set(loadedIndexes);

      photos.forEach((photo, index) => {
        const element = document.getElementById(`photo-${index}`);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (
            rect.top < galleryRect.bottom &&
            rect.bottom > galleryRect.top &&
            !loadImageIndexes.has(index)
          ) {
            loadImageIndexes.add(index);
            photo.loaded = true;
          }
        }
      });

      setLoadedIndexes(loadImageIndexes);
    };

    galleryRef?.current?.addEventListener('scroll', loadImagesInView);
    window.addEventListener('resize', loadImagesInView);

    loadImagesInView();

    return () => {
      galleryRef?.current?.removeEventListener('scroll', loadImagesInView);
      window.removeEventListener('resize', loadImagesInView);
    };
  }, [photos]);

  const handleMouseOver = (event) => {
    setIsOver(true);
    const { clientX, currentTarget } = event;
    const { offsetWidth, offsetLeft } = currentTarget;
    const mouseX = clientX - offsetLeft;

    if (mouseX < offsetWidth / 3) {
      scrollSpeed.current = -6;
    } else if (mouseX > (2 * offsetWidth) / 3) {
      scrollSpeed.current = 10;
    } else {
      scrollSpeed.current = 3;
    }
  };

  const handleMouseOut = () => {
    setIsOver(false);
    scrollSpeed.current = 1;
  };

  const openModal = index => {
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const calcHeight = () => {
    let style = { overflowX: 'none' };

    if (ownedNfts.length <= 1 && window.innerWidth >= 768) {
      style.height = '16em';
      style['alignSelf'] = 'center';
    }
    return style;
  };

  if (altView) {
    return (
      <>
        <button onClick={() => { setAltView(p => !p); }}>Scroller View</button>
        <button onClick={() => selectPhotos()}>Reload</button>
        <Panel className="NFT-panel">
          <Gallery photos={photos} />
        </Panel>
      </>
    );
  }

  return (
    <>
      {showBtns && <>
        <button onClick={() => { setAltView(p => !p); }}>Gallery View</button>
        <button onClick={() => selectPhotos()}>Reload</button>
      </>}
      <Panel extraStyles={ownedNfts && calcHeight()} className={ownedNfts ? "NFT-panel2 friendship-panel" : "NFT-panel friendship-panel"}>
        <div
          className={ownedNfts ? "scrollable-gallery-vert" : "scrollable-gallery"}
          ref={galleryRef}
          onMouseMove={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {!initialized ? "Loading..." : photos.map((photo, index) => (
            <img
              class = {`mNFT` }
              key={index}
              id={`photo-${index}`}
              src={photo.loaded ? photo.src : null} // Placeholder can be used here
              width={photo.width * 100}
              height={photo.height * 100}
              onClick={ownedNfts ? () => setSelected(index) : () => openModal(index)}
              style={{ margin: '5px', cursor: 'pointer', display: 'block' }}
              alt={`NFT ${index}`}
            />
          ))}
        </div>
      </Panel>
      {!ownedNfts &&
        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={closeModal}>
              <Carousel
                views={photos.map(photo => ({
                  source: photo.src
                }))}
                currentIndex={currentIndex}
              />
            </Modal>
          ) : null}
        </ModalGateway>}
    </>
  );
};

export { NftGalleryPhotos };
