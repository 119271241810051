// ErrorProvider.js
import React, { createContext, useState, useContext } from 'react';


const ErrorContext = createContext(); // Ensure this is exported

let showPopUp = () => {
  console.warn("showPopUp called before ErrorProvider initialization.");
};


export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState("");
  const [errorType, setErrorType] = useState("");

  const tmpShowPopUp  = (errMsg, errorType) => {
    setError(errMsg);
    setErrorType(errorType);

    setTimeout(() => {
      closePopup();
    }, 5000);
  };

  const closePopup = () => {
    setError("");
    setErrorType("");
  };

  showPopUp = tmpShowPopUp;
  
  const contextValue = { error, errorType, closePopup, showPopUp };

  return (
    <ErrorContext.Provider value={contextValue}>
      {children}
    </ErrorContext.Provider>
  );
};
export const useError = () => showPopUp;

export const useErrorContext = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useErrorContext must be used within an ErrorProvider");
  }
  return context;
}