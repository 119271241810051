import React, { useState, useEffect } from 'react';
import { NFT_URL_SRC } from '../../config/configurations';
import Carousel, { Modal, ModalGateway } from 'react-images';
import closedChest from '../../assets/images/NFT-Promo/Sommi-NFTs-poster-2.png'; //Image before Purchase
import { NFTWithMetaModel } from '../NFTWithMetaModel'
import metaTags from '../../pages/nfts/demoMetaTags.json'; //Todo get nft tags in function?
import { VideoPlayer } from './videoPlayer';
import {vidData} from '../../assets/vid/vidData.js'

const LootBoxController = ({input, toPlay, setToPlay}) => {
  const [isOpening, setIsOpening] = useState(false)
  const [played, setPlayed] = useState(false)
  const [skipDelay, setSkipDelay] = useState(false);

  useEffect(() => {
    if (!isOpening || !toPlay) { return }
    const timer = setTimeout(() => {
      handleClose()
    }, vidData[toPlay][1] + 300);

    return () => clearTimeout(timer); // Clear timeout if component unmounts or dependencies change
  }, [isOpening, skipDelay])

  const handleSkip = () => {
    setSkipDelay(true); // Update state to indicate that delay should be skipped
  };

  useEffect(() => {
    if (toPlay) {
      setIsOpening(true);
    }
  }, [toPlay]);

  const handleClose = () => {
    setToPlay()
    setIsOpening(false)
    setSkipDelay(false)
  }

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        { isOpening && !skipDelay ?
        <>
          <h4 style={{position: 'relative', top: '2.5em'}}>Welcome to Friendship!</h4>
          <VideoPlayer played={played} setPlayed={setPlayed} vidData={vidData} toPlay={toPlay}/>
          <a href="/dapp"><button style={{position: 'relative', top: '-6.5em', left: '10.5em'}} className='button'>Skip</button></a>
         </>
        : <><img className="closedBox-img" src={closedChest} /></>
         }
      </div>
    </>
  );
};

export { LootBoxController };
