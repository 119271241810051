import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import NftABI from "../../config/abi/NftABI.json";

async function NftETHFees( nftAddress, provider, signer) {


  let feesInfo = []


  try {

    let nftContract = new ethers.Contract( nftAddress, NftABI.abi,  provider).connect(signer)
    var ethFee =  await nftContract.ethFees(  signer )
    var refFee = 1000/10000 //10% from the contract (if changed in the contract, must change manually)
    var refRatio = 9000/10000 // 90% from the contract (ditto)


    feesInfo.push(
      ethFee[0],ethFee[1],ethFee[2], ethFee[3], refFee, refRatio
    )
    //currentPrice_, etherPrice , incrementFee, increment, referrer fee, refRatio

  }
  catch (e) {
    //console.log('eth fees error:',e)

  }
  console.log('feesINFO:',feesInfo)
  return feesInfo
}
export { NftETHFees };
