import { ethers } from "ethers";
import ConditionalTokenABI from "../../../../config/abi/prediction/ConditionalTokenABI.json";
import { getErrorMessage } from '../../../errorHandler';

// Function to prepare a condition
async function Prepare(conditionalAddress, oracleAddress, questionId, outcomeSlotCount, provider, signer) {

    var prepare = [true, '']

    try {
        console.log('Preparing condition with oracle:', oracleAddress, 'questionId:', questionId, 'outcomeSlotCount:', outcomeSlotCount);

        let conditionalTokenContract = new ethers.Contract(conditionalAddress, ConditionalTokenABI.abi, provider).connect(signer);
        let tx = await conditionalTokenContract.prepareCondition( oracleAddress , questionId , outcomeSlotCount)

        console.log('Prepare Transaction sent:', tx.hash)
        //console.log('Prep Transaction confirmed:', tx.hash, receipt.blockHash);
        prepare = [true, tx.hash ]



    } catch (error) {
        console.error('Error preparing condition:', error);
        var errorMsg = getErrorMessage(error)
        prepare = [false, errorMsg]
    }

    return prepare;
}

export { Prepare };
