import React, { Component } from 'react';

class Spinner extends Component {
    constructor(props){
      super(props);
      this.numbers = undefined;
      this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      //console.log('this:'+this.forceUpdateHandler)

    };

    forceUpdateHandler(number){
      this.reset(number);
      this.numbers = number
    };

    async reset(number) {
      if (this.timer) {
        clearInterval(this.timer);
      }


      this.start = this.setStartPosition(number);


      this.setState({
        position: this.start,
        timeRemaining: this.props.timer
      });

      this.timer = setInterval(() => {
        this.tick(number)
      }, 100);
    }

    state = {
      position: 0,
      lastPosition: null
    }
    static iconHeight = 188;
    multiplier = Math.floor(Math.random()*(4-1)+1);


    start = this.setStartPosition(this.numbers);
    speed = Spinner.iconHeight * this.multiplier;

    setStartPosition(number) {
      if (number==undefined){
        number = Math.floor(Math.random() * 9) + 1
      }
      let rn = (number * Spinner.iconHeight)*-1;
      //console.log('Random Mulitpliers:'+ rn )
      return (number * Spinner.iconHeight)*-1;
    }

    moveBackground() {
      this.setState({
        position: this.state.position - this.speed,
        timeRemaining: this.state.timeRemaining - 100
      })
      let divisor = this.state.position / this.speed
      //console.log('state - speed:'+ this.state.position+' speed:'+ this.speed +' state:'+ this.state.position +' divisor: ' + divisor  )
      //if (this.state.position === this.ChangeNumbers(this.numbers).slotBigpixels){
        //console.log('we stopped time remain:'+this.state.timeRemaining);
        //this.setState({  timeRemaining: 0 })
      //}

    }
    ChangeNumbers( slotIndex) {
      // function to match array of numbers to pixel
      var slotPixels = [0,-188,-376,-564,-752,-940,-1128,-1316,-1504];
      var slotBigpixels = [-4888, -8648, -5264, -7144, -9024, -4136, -6016, -12972, -4700];
      var slotObj = {
        slotPixels: slotPixels[slotIndex-1],
        slotBigpixels: slotBigpixels[slotIndex-1]
      }
      return slotObj;
    }

    getSymbolFromPosition() {
      //let x = Math.floor(Math.random()*9)
      //position = this.ChangeNumbers(x).slotBigpixels
      let {position } = this.state;


      //console.log(position)
      const totalSymbols = 9;
      const maxPosition = (Spinner.iconHeight * (totalSymbols-1)*-1);
      let moved = (this.props.timer/100) * this.multiplier
      //console.log('timer:'+this.props.timer)
      //console.log('this.props:'+this.props)
      //console.log('this.multiplier:'+this.multiplier)
      //console.log('moved:'+moved)
      let startPosition = this.start;
      let currentPosition = startPosition;
      //currentPosition = this.ChangeNumbers(this.numbers).slotPixels;
      //position = this.ChangeNumbers(this.numbers).slotBigpixels
      //console.log('current position:'+  currentPosition)
      //console.log('position:'+  position)


      for (let i = 0; i < moved; i++) {
        currentPosition -= Spinner.iconHeight;
        //console.log(i+ ' state Position:'+this.state.position+' currentLoop:'+  currentPosition)
        if (currentPosition < maxPosition) {
          currentPosition = 0;
        }
      }
      currentPosition = this.ChangeNumbers(this.numbers).slotPixels;
      //this.position = this.ChangeNumbers(this.numbers).slotBigpixels
      //console.log('Forced New tick s.p:'+this.state.position+' CCCurrent:'+  currentPosition)

      //console.log('current position 1:'+  currentPosition)
      //console.log('position 1:'+  position)

      //console.log('new current position:'+  currentPosition)
      //console.log('new position:'+  position)
      //console.log('new s.p position:'+  this.state.position)

      this.props.onFinish(currentPosition);
    }

    tick(number) {
      //console.log('time remain:'+this.state.timeRemaining+' tick s.p:'+this.state.position)

      if (this.state.timeRemaining <= 0) {
        //console.log('-------0 time remain:'+this.state.timeRemaining+' -----0 tick s.p:'+this.state.position)
        this.setState({          position: this.ChangeNumbers(this.numbers).slotBigpixels        })
        //this.state.currentPosition = this.ChangeNumbers(this.numbers).slotPixels
        //current = this.ChangeNumbers(this.numbers).slotPixels
        clearInterval(this.timer);
        //console.log('Foreced New tick s.p:'+this.state.position)
        this.getSymbolFromPosition();


      } else {
        this.moveBackground()
      }
    }

    componentDidMount() {
      clearInterval(this.timer);

      this.setState({
        position: this.start,
        timeRemaining: this.props.timer
      });
      this.timer = setInterval(() => {
        this.tick()
      }, 100);
    }

    render() {


      let { position, current } = this.state;
      if (this.state.timeRemaining == 0){
          //this.setState({position: this.ChangeNumbers(this.numbers).slotBigpixels})


      }



      return (

        <div
          style={{backgroundPosition: '0px ' + position + 'px'}}
          className={`icons`}
        />


      )
    }
  }

  export default Spinner
