import { ethers } from "ethers";
import PaginatedQuestions from "../../../../config/abi/prediction/PaginateQuestionsABI.json";
import {PageHelper}  from "./pageHelper";

async function GetQsByPage(offset, limit, contractAddress, provider) {
    let result = [null,null];

    try {
        // Initialize the contract instance
        const paginatedQuestionsContract = new ethers.Contract(contractAddress, PaginatedQuestions.abi, provider);


        // Call the contract's function to get questions page 1
        const page1 = await paginatedQuestionsContract.getQuestionsPage1(offset, limit);
        const { ids, questionStrings, resolutionDates, timestamps } = page1;

        const page2 = await paginatedQuestionsContract.getQuestionsPage2(offset, limit);
        const { tradingStates, sourceIds, categoryIds, eventAddresses } = page2;

        result = PageHelper(page1,page2)
        console.log('Structured questions from page update 1:', result);


    } catch (error) {
        console.error('Error retrieving questions page 1:', error);
    }

    return result;
}

export { GetQsByPage};
