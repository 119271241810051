import { ethers } from "ethers";
import PaginatedQuestions from "../../../../config/abi/prediction/PaginateQuestionsABI.json";
import {PageHelper}  from "./pageHelper";

async function GetQsByCategory(categoryId, offset, limit, contractAddress, provider) {
    let result = [null,null];

    try {
        // Initialize the contract instance
        const paginatedQuestionsContract = new ethers.Contract(contractAddress, PaginatedQuestions.abi, provider);
        // Call the contract's function to get questions by source
        const [page1, page2] = await paginatedQuestionsContract.getQuestionsByCategory(categoryId, offset, limit);
        // Store the results
        result = PageHelper(page1,page2)

        console.log('Retrieved questions by source:', result);

    } catch (error) {
        console.error('Error retrieving questions by source:', error);
    }

    return result;
}

export {  GetQsByCategory };
