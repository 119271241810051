function getErrorMessage(error) {
    switch (error.code) {
      case 'CALL_EXCEPTION':
        if (error.message.includes('missing revert data')) {
          return 'Transaction failed: insufficient gas provided. Please ensure you have enough gas to cover the transaction.';
        }
        return 'Transaction failed: call exception occurred. Please check the contract method and parameters.';
      case 'INSUFFICIENT_FUNDS':
        return 'Transaction failed: insufficient funds. Please check your balance and try again.';
      case 'NETWORK_ERROR':
        return 'Network error: unable to connect. Please check your internet connection and try again.';
      case 'UNPREDICTABLE_GAS_LIMIT':
        return 'Transaction failed: unpredictable gas limit. Please try again or manually set a higher gas limit.';
      case 'NONCE_EXPIRED':
        return 'Transaction failed: nonce expired. Please refresh your nonce and try again.';
      case 'REPLACEMENT_UNDERPRICED':
        return 'Transaction failed: replacement transaction underpriced. Please increase the gas price and try again.';
      case 'TRANSACTION_REPLACED':
        return 'Transaction failed: transaction has been replaced by another transaction.';
      case 'INVALID_ARGUMENT':
        return 'Transaction failed: invalid argument provided. Please check the parameters and try again.';
      case 'UNSUPPORTED_OPERATION':
        return 'Transaction failed: unsupported operation. Please check the contract method and parameters.';
      default:
        return `An unexpected error occurred: ${error.message}`;
    }
  }
  
export {getErrorMessage}
  